.switch {
	&__toggler {
		width: 38px;
		height: 22px;
		&--message {
			border: none;
			background-color: var(--greyscale-grey-300);
			&::before {
				background-color: var(--additional-white);
			}
		}
	}

	&__label {
		display: flex;
		align-items: center;
		gap: 12px;
	}

	&__text {
		color: var(--greyscale-grey-800);
		font-size: 14px;
		font-style: normal;
		font-weight: 500;
		line-height: 180%; /* 25.2px */
	}

	&--type2 .switch__toggler {
		background: var(--greyscale-grey-300);
		border-color: var(--greyscale-grey-300);
	}

	input:checked ~ .switch__toggler {
		border-color: var(--primary-blue-600);
		background: var(--primary-blue-600);

		&:before {
			left: 18px;
		}
	}

	.disabled {
		.switch__toggler {
			cursor: not-allowed;
		}
	}
}

