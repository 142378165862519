.sidebar1 {
	background: #212c63;
	padding: 40px 26px 30px 26px;
	width: 310px;

	&.active {

	}

	&-nav__logo {
		margin-bottom: 40px;
		display: flex;
		align-items: center;
		width: 100%;

		img {

		}
	}

	&-top {

	}

	&-nav {
		&__list {
			list-style: none;
			padding: 0;
			margin: 0;
			display: flex;
			flex-direction: column;
			gap: 6px;

			.noActive {
				a {
					margin-left: 21px;
					font-family: var(--font-sans);
					font-size: 16px;
					font-weight: 400; /* Normal font weight */
					line-height: 20px;
					letter-spacing: 0em;
					text-align: left;
					color: rgba(228, 228, 228, 1);
				}

				a.active {
					background-color: transparent;
					color: rgba(255, 255, 255, 1);
					font-family: var(--font-sans);
					font-size: 16px;
					font-weight: 600;
					line-height: 20px;
					letter-spacing: 0em;
					text-align: left;


				}
			}
		}

		&__link {
			display: flex;
			align-items: center;
			font-family: var(--font-sans);
			letter-spacing: 0;
			text-align: left;
			width: 100%;
			padding: 15px 20px;
			gap: 10px;
			margin-bottom: 0;
			color: var(--greyscale-grey-100);
			font-size: 16px;
			font-style: normal;
			font-weight: 400;
			line-height: normal;
			border-radius: 8px;
			background: transparent;
			position: relative;
			cursor: pointer;
			transition: var(--transition-base);

			&:hover,
			&.active {
				background: var(--primary-blue-500);
				color: var(--additional-white);
				font-size: 16px;
				font-weight: 600;
			}

			&.active {

				.sidebar1-nav__arrow {
					transform: rotate(180deg);
				}
			}

			&.active + .sidebar1-nav__submenu {
				display: block;
			}
		}

		&__icon {
			width: 20px;
			min-width: 20px;
			height: 20px;
			min-height: 20px;
		}

		&__arrow {
			display: block;
			background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='18' height='18' viewBox='0 0 18 18' fill='none'%3E%3Cpath d='M12.75 7.5L9.35355 10.8964C9.15829 11.0917 8.84171 11.0917 8.64645 10.8964L5.25 7.5' stroke='%23E4E4E4' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
			width: 18px;
			min-width: 18px;
			height: 18px;
			min-height: 18px;
			position: absolute;
			top: calc(50% - 9px);
			right: 20px;
			transition: var(--transition-base);

			svg {
				display: none;
			}
		}

		&__submenu {
			display: none;

			.sidebar1-nav__link {
				display: block;
				padding: 15px 20px;
				color: var(--greyscale-grey-100);
				font-size: 16px;
				font-style: normal;
				font-weight: 400;
				line-height: normal;
				background: none;
				transition: var(--transition-base);

				&:hover,
				&.active {
					color: var(--additional-white);
					font-weight: 600;
				}
			}
		}
	}

	&-nav__arrow.open {
		/* Define the animation for when the sidebar opens */
		animation: spinOpen 0.5s forwards;
	}

	&-nav__arrow.closed {
		/* Define the animation for when the sidebar closes */
		animation: spinClose 0.5s forwards;
	}

	@keyframes spinOpen {
		from {
			transform: rotate(180deg);
		}
		to {
			transform: rotate(0deg);
		}
	}

	@keyframes spinClose {
		from {
			transform: rotate(0deg);
		}
		to {
			transform: rotate(180deg);
		}
	}
}

.navLink {
	&.active {

	}
}

.minimized {
	.sidebar1-nav__list {

	}
}
@media only screen and (min-width: 991px) {
	.header {
		display: none;
	}
}
@media only screen and (max-width: 991px) {
	.main-section .sidebar1.active {
		transform: translate(0);
		transition: all 0.5s ease;
	}
	.sidebar1 {
		display: block;
		width: unset !important;
		min-width: 55px;
		height: calc(100vh - 82px);
		position: fixed;
		left: 0;
		z-index: 99;
		transform: translate(-100%, 0);
		transition: all 0.5s ease;
	}
	.header {
		background: #212c63;
		border-bottom: transparent;
	}
	.header__logo img {
		width: 100%;
		height: 100%;
		object-fit: cover;
		border-radius: 0% !important;
	}
	.sidebar1-nav__logo {
		display: none;
	}
	.sidebar1-nav__list {
		max-height: 70vh;
		overflow-y: auto;
	}
}

