.react-tel-input .form-control.custom-phone-number-input {
	display: block;
	width: 100%;
	height: 48px;
	padding-left: 86px;
	outline: none;
	line-height: normal;
	-webkit-transition: all 0.15s ease;
	-o-transition: all 0.15s ease;
	transition: all 0.15s ease;
	font-weight: 500;
	font-size: 16px;
	line-height: 19px;
	color: #011e26;
}

.phone-number-input {
	grid-template-columns: 100px 237px;
}

.react-tel-input .form-control {
	border: 1px solid var(--border-main-color);
}
.react-tel-input {
	.flag-dropdown {
		height: 48px;
		width: 70px;
		padding: 5px;
		background-color: transparent;
		border: none;
		border-radius: 3px 0 0 3px;
		border-right: 1px solid rgba(230, 232, 236, 0.3);
	}

	.flag-dropdown.open {
		background-color: transparent;
	}

	.selected-flag {
		outline: none;
		position: relative;
		width: 38px;
		height: 100%;
		padding: 15px;
		border-radius: 3px 0 0 3px;

		.arrow {
			left: 26px;
			border-left: 5px solid transparent;
			border-right: 5px solid transparent;
			border-top: 5px solid #777e90;
		}
	}
}
