.settings-2fa {
	&.settings-2fa--reg-setup {
		.settings-2fa__title {
			font-weight: 500;
		}

		.settings-2fa__title-number {
			background: var(--primary-blue-50);
		}

		.settings-2fa__text {
			margin-bottom: 20px;
			color: var(--greyscale-grey-600);
			font-size: 14px;
			font-style: normal;
			font-weight: 400;
			line-height: 160%; /* 22.4px */
		}

		.input__name {
			font-weight: 400;
		}

		.input__notification {
			color: var(--additional-error);
			font-size: 12px;
			font-style: normal;
			font-weight: 400;
			line-height: normal;
			display: block;
			padding: 0;
			background: transparent;
			margin-top: 5px;

			&:before {
				display: none;
			}

			&--type3 {
				margin-top: 15px;
				display: flex;
				align-items: center;
				gap: 2px;
				color: var(--greyscale-grey-800);
				font-size: 12px;
				font-style: normal;
				font-weight: 400;
				line-height: normal;
				border-radius: 4px;
				background: var(--transparent-red);
				padding: 8px 6px;

				&:before {
					display: block;
					content: '';
					background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='21' height='20' viewBox='0 0 21 20' fill='none'%3E%3Cpath d='M10.493 13.773C10.6588 13.773 10.7993 13.7169 10.9144 13.6047C11.0295 13.4925 11.0871 13.3535 11.0871 13.1876C11.0871 13.0218 11.031 12.8813 10.9188 12.7662C10.8066 12.6511 10.6676 12.5935 10.5018 12.5935C10.3359 12.5935 10.1954 12.6496 10.0803 12.7618C9.9652 12.874 9.90765 13.013 9.90765 13.1789C9.90765 13.3447 9.96374 13.4852 10.0759 13.6003C10.1881 13.7154 10.3271 13.773 10.493 13.773ZM9.95573 11.0615H11.039V6.06148H9.95573V11.0615ZM10.5045 17.5807C9.4613 17.5807 8.4788 17.3833 7.55702 16.9886C6.63523 16.5939 5.82841 16.0508 5.13656 15.3592C4.4447 14.6677 3.90132 13.8615 3.50642 12.9408C3.11151 12.02 2.91406 11.0361 2.91406 9.98923C2.91406 8.94231 3.11142 7.96144 3.50615 7.0466C3.90087 6.13176 4.44399 5.32841 5.13552 4.63656C5.82706 3.9447 6.63322 3.40132 7.554 3.00642C8.47478 2.61151 9.45862 2.41406 10.5055 2.41406C11.5524 2.41406 12.5333 2.61142 13.4481 3.00615C14.363 3.40087 15.1663 3.94399 15.8582 4.63552C16.55 5.32706 17.0934 6.1316 17.4883 7.04913C17.8832 7.96665 18.0807 8.94702 18.0807 9.99023C18.0807 11.0335 17.8833 12.016 17.4886 12.9377C17.0939 13.8595 16.5508 14.6663 15.8592 15.3582C15.1677 16.05 14.3632 16.5934 13.4456 16.9883C12.5281 17.3832 11.5477 17.5807 10.5045 17.5807ZM10.4974 16.4974C12.3029 16.4974 13.8377 15.8654 15.1015 14.6015C16.3654 13.3377 16.9974 11.8029 16.9974 9.99738C16.9974 8.19182 16.3654 6.6571 15.1015 5.39321C13.8377 4.12932 12.3029 3.49738 10.4974 3.49738C8.69182 3.49738 7.1571 4.12932 5.89321 5.39321C4.62932 6.6571 3.99738 8.19182 3.99738 9.99738C3.99738 11.8029 4.62932 13.3377 5.89321 14.6015C7.1571 15.8654 8.69182 16.4974 10.4974 16.4974Z' fill='%23EC2E2E'/%3E%3C/svg%3E");
					width: 21px;
					min-width: 21px;
					height: 20px;
					min-height: 20px;
				}
			}
		}
	}

	&__title {
		margin-bottom: 12px;
		display: flex;
		align-items: center;
		gap: 10px;
		color: var(--greyscale-grey-700);
		font-size: 18px;
		font-style: normal;
		font-weight: 700;
		line-height: normal;
	}

	&__title-number {
		min-width: 28px;
		width: 28px;
		height: 28px;
		display: flex;
		align-items: center;
		justify-content: center;
		border-radius: 50%;
		background: var(--additional-white);
		border: 0;
		color: var(--primary-blue-600);
		font-size: 16px;
		font-style: normal;
		font-weight: 700;
		line-height: normal;
	}

	&__text {
		margin-bottom: 20px;
		color: var(--greyscale-grey-600);
		font-size: 14px;
		font-style: normal;
		font-weight: 700;
		line-height: 160%; /* 22.4px */
	}

	&__divider {
		border-top: 1px solid #c7e0ef;
		width: 100%;
		height: 1px;
		margin: 24px 0;
	}

	.input {
		margin-bottom: 0;
	}

	.input__name {
		color: var(--greyscale-grey-600);
		font-size: 14px;
		font-style: normal;
		font-weight: 700;
		line-height: 160%; /* 22.4px */
		margin-bottom: 8px;
	}

	.input-item {
		border-radius: 10px;
		border: 1px solid var(--greyscale-grey-100);
		background: var(--additional-white);
		height: 44px;
		padding: 2px 16px;
	}

	.input__icon {
		position: absolute;
		top: 50%;
		right: 16px;
		width: 20px;
		min-width: 20px;
		height: 20px;
		min-height: 20px;
		transform: translateY(-50%);

		&--eye {
			background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.9862 9.99896C12.9862 11.649 11.6529 12.9823 10.0029 12.9823C8.35286 12.9823 7.01953 11.649 7.01953 9.99896C7.01953 8.34896 8.35286 7.01562 10.0029 7.01562C11.6529 7.01562 12.9862 8.34896 12.9862 9.99896Z' stroke='%23212C63' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M9.99792 16.8932C12.9396 16.8932 15.6813 15.1599 17.5896 12.1599C18.3396 10.9849 18.3396 9.0099 17.5896 7.8349C15.6813 4.8349 12.9396 3.10156 9.99792 3.10156C7.05625 3.10156 4.31458 4.8349 2.40625 7.8349C1.65625 9.0099 1.65625 10.9849 2.40625 12.1599C4.31458 15.1599 7.05625 16.8932 9.99792 16.8932Z' stroke='%23212C63' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
		}
	}

	.input__notification {
		margin-top: 15px;
		display: flex;
		align-items: center;
		gap: 2px;
		color: var(--greyscale-grey-800);
		font-size: 12px;
		font-style: normal;
		font-weight: 700;
		line-height: normal;
		border-radius: 4px;
		background: var(--transparent-red);
		padding: 8px 6px;

		&:before {
			content: '';
			background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='21' height='20' viewBox='0 0 21 20' fill='none'%3E%3Cpath d='M10.493 13.773C10.6588 13.773 10.7993 13.7169 10.9144 13.6047C11.0295 13.4925 11.0871 13.3535 11.0871 13.1876C11.0871 13.0218 11.031 12.8813 10.9188 12.7662C10.8066 12.6511 10.6676 12.5935 10.5018 12.5935C10.3359 12.5935 10.1954 12.6496 10.0803 12.7618C9.9652 12.874 9.90765 13.013 9.90765 13.1789C9.90765 13.3447 9.96374 13.4852 10.0759 13.6003C10.1881 13.7154 10.3271 13.773 10.493 13.773ZM9.95573 11.0615H11.039V6.06148H9.95573V11.0615ZM10.5045 17.5807C9.4613 17.5807 8.4788 17.3833 7.55702 16.9886C6.63523 16.5939 5.82841 16.0508 5.13656 15.3592C4.4447 14.6677 3.90132 13.8615 3.50642 12.9408C3.11151 12.02 2.91406 11.0361 2.91406 9.98923C2.91406 8.94231 3.11142 7.96144 3.50615 7.0466C3.90087 6.13176 4.44399 5.32841 5.13552 4.63656C5.82706 3.9447 6.63322 3.40132 7.554 3.00642C8.47478 2.61151 9.45862 2.41406 10.5055 2.41406C11.5524 2.41406 12.5333 2.61142 13.4481 3.00615C14.363 3.40087 15.1663 3.94399 15.8582 4.63552C16.55 5.32706 17.0934 6.1316 17.4883 7.04913C17.8832 7.96665 18.0807 8.94702 18.0807 9.99023C18.0807 11.0335 17.8833 12.016 17.4886 12.9377C17.0939 13.8595 16.5508 14.6663 15.8592 15.3582C15.1677 16.05 14.3632 16.5934 13.4456 16.9883C12.5281 17.3832 11.5477 17.5807 10.5045 17.5807ZM10.4974 16.4974C12.3029 16.4974 13.8377 15.8654 15.1015 14.6015C16.3654 13.3377 16.9974 11.8029 16.9974 9.99738C16.9974 8.19182 16.3654 6.6571 15.1015 5.39321C13.8377 4.12932 12.3029 3.49738 10.4974 3.49738C8.69182 3.49738 7.1571 4.12932 5.89321 5.39321C4.62932 6.6571 3.99738 8.19182 3.99738 9.99738C3.99738 11.8029 4.62932 13.3377 5.89321 14.6015C7.1571 15.8654 8.69182 16.4974 10.4974 16.4974Z' fill='%23EC2E2E'/%3E%3C/svg%3E");
			width: 21px;
			min-width: 21px;
			height: 20px;
			min-height: 20px;
		}
	}
}
