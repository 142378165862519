.main-content {
	width: calc(100% - 310px);
	padding: 40px 30px;
	transition: width 0.3s ease;
	color: var(--greyscale-grey-800);
	background: var(--greyscale-grey-10);

	@media only screen and (max-width: 575.98px) {
		padding: 20px 15px !important;
	}

	&--flex {
		display: flex;
		flex-direction: column;
	}
	.error-wrapper {
		margin-top: 16px;
	}
}

