.td-name .td-sorting-arrow-button {
	font-size: inherit;
}

.trash {
	svg {
		stroke: #e04141;
	}
}

.unblocked {
	.block {
		svg {
			stroke: #777e90;
		}
	}

	.unblock {
		svg {
			stroke: #30c06e;
		}

		pointer-events: none;
	}
}

.wallet-content-tables .table--fiat-balance--column .tr {
	grid-template-columns: 1fr 1fr 1fr 1.4fr;
}

.coin__text--margin {
	margin-top: 22px;
}

.table--trade-management.table--without-borders {
	.table-header {
		.tr {
			border-bottom: 1px solid #e6e8ec;
		}
	}
}

.wallet-content-tables .block--middle {
	display: flex;
	justify-content: space-between;
	flex-direction: row-reverse;
	align-items: center;

	@media only screen and (max-width: 767.98px) {
		flex-direction: column-reverse;
		gap: 12px;
	}

	.table-title {
		margin-bottom: 0;
	}
}

.table--transfer-history-crypto .table-header .tr {
	align-items: center;
}

.blocked {
	.block {
		svg {
			stroke: #e04141;
		}

		pointer-events: none;
	}

	.unblock {
		svg {
			stroke: #777e90;
		}
	}

	.trash {
		svg {
			stroke: #777e90;
		}
	}
}

.archive {
	.block,
	.unblock,
	.trash {
		pointer-events: none;
		opacity: 0.6;
	}
}

.svg--red svg {
	stroke: #e04141;
}

.pagination > li button,
.pagination > li > a {
	cursor: pointer;
}

.pagination > li.active > a:not(.disabled),
.pagination > li.active > button:not(.disabled) {
	color: #173b58;
	border-color: #3b97d3;
	background: 0 0;
}

.coin__text---upper {
	text-transform: uppercase;
}

.table--crypto-balance .td > p > b,
.table--fiat-balance .td > p > b {
	font-weight: 400;
	display: block;
}

.trade-size__drop {
	z-index: 99;
}

.td {
	&--nowrap {
		white-space: nowrap;
	}

	&--width_90 {
		min-width: 90px;
	}
}

.table--user-management-fee-management-spread .tr {
	grid-template-columns: 1.5fr 1.2fr 0.5fr;
}

.table.table--without-borders .tr {
	border-bottom: none;
}

.table--referral.one--user .tr {
	grid-template-columns: 1fr 1.88fr 1.72fr 1.7fr 1.7fr 1fr 1fr 1fr;
}

// .table.table--transaction-fiat .tr {
// 	grid-template-columns: 1.5fr 1.5fr 1fr 1.5fr 2fr 1fr 1fr 1fr 1fr 1fr 0.6fr;
// }

.table.table--transaction-fiat-transfer .tr {
	grid-template-columns: 1.5fr 1.5fr 1fr 1.5fr 2fr 1fr 1fr 1fr 1fr 1fr 1fr 0.6fr;
}

.table.table--transaction-crypto .tr {
	grid-template-columns: 1.5fr 1.5fr 1.5fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
}

.table.table--transaction-crypto-withdrawal .tr {
	grid-template-columns: 1.5fr 1.5fr 1.5fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1.2fr 0.6fr;
}

.table.table--transaction-crypto-transfer .tr {
	grid-template-columns: 1.5fr 1.5fr 1fr 1.5fr 1fr 1fr 1fr 1fr 1fr 1fr;
}

.table.table--transactions-pooled-deposit .tr {
	grid-template-columns: 1.5fr 1.5fr 1.3fr 1fr 1fr 1fr 1fr 1fr 0.6fr;
}

.table.table--transactions-profit-wallet .tr {
	grid-template-columns: 1.5fr 1.5fr 1.3fr 1fr 1fr 1fr 1fr 1fr 1fr;
}

.table.table--transactions-transfer-limits .tr {
	grid-template-columns: 1fr 1.5fr 1.5fr 1.5fr 1.5fr 1fr;
}

.table.table--transactions-trades .tr {
	grid-template-columns: 1.5fr 1.5fr 1fr 1fr 1fr 1.5fr 1.5fr 1fr 1fr 1fr;
}

.table.table--ledger-aggregated .tr {
	grid-template-columns: 1fr 1fr 1fr 2fr 1fr 1.5fr 1.5fr 1fr 1fr !important;
}

.table.table--transactions-pending-withdrawal .tr {
	grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 0.6fr 0.6fr;

	.td .td--width-130 {
		max-width: 130px;
	}
}

.table.table--referral-trades .tr {
	grid-template-columns: 1fr 1fr 1fr 0.8fr 0.5fr 0.5fr 1fr 1fr 1fr 0.6fr 0.6fr;
}

.table.table--transactions-trading-limits .tr {
	grid-template-columns: 1fr 1fr 1fr 1fr;
}

.table.table--referral-system-referrals .tr {
	grid-template-columns: 1.66fr 2.5fr 2.42fr 2.48fr 1.65fr 1fr;
}

.table.table--referral-system-referrals-by-user .tr {
	grid-template-columns: 1.66fr 1.75fr 2.42fr 2.48fr 1.65fr 1.65fr 1fr;
}

.table.table--bank-accounts-with-select .tr {
	grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 0.7fr;
}
.table.table--associated-accounts .tr {
	grid-template-columns: 1fr 1fr .2fr;
}
.table.table--associated-accounts_main .tr {
	grid-template-columns: 0.7fr 0.8fr 1.2fr 1.9fr 1.5fr  1.4fr;
}
.table.table--associated-accounts_main__newRequests .tr {
	grid-template-columns: 1.2fr 1.2fr 1fr 1.5fr 1.6fr 0.9fr 1.1fr 1.1fr 1fr;
}
.table-pagination.table-pagination--bank-accounts-with-select {
	margin-bottom: 30px;
}

.table.table--transaction-fiat,
.table.table--transaction-fiat-transfer,
.table.table--transaction-crypto,
.table.table--transaction-crypto-withdrawal,
.table.table--transaction-crypto-transfer,
.table.table--transactions-pooled-deposit,
.table.table--transactions-transfer-limits,
.table.table--transactions-trades,
.table.table--referral-trades,
.table.table--transactions-trading-limits,
.table.table--transactions-pending-withdrawal,
.table.table--referral-system-referrals,
.table.table--ledger-aggregated {
	.td-action {
		display: inline;

		&:disabled {
			cursor: default;
			opacity: 0.3;
		}
	}

	.tr {
		align-items: center;
	}

	.td-name--flex {
		gap: 0;
		grid-gap: 0;
	}

	.bank-info-text__title {
		display: block;
	}
}

.table-wrapper.table-wrapper--with-y-scroll {
	min-height: 260px;
	background: #f5f5f5;
	border-radius: 10px;
	border-radius: 24px;

	overflow-y: hidden;
	overflow-x: auto;

	.table {
		// min-width: 1300px;
	}

	.table.table--transaction-fiat-transfer {
		// min-width: 1450px;
	}

	.table.table--bank-accounts-with-select {
		min-width: 1400px;
	}

	.table.table--transactions-pooled-deposit {
		// min-width: 1150px;
	}

	.table-header {
		background: #fff;
	}

	.table-body {
		background: #fff;
	}
}

.td.td--right .select.select--table .select__drop {
	right: 0;
	left: unset;
}

.table-no-data-body {
	border-radius: 0 0 8px 8px;
	padding: 16px 24px;
	height: 253px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.table-no-data-body-plug {
	margin: 16px 0;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 16px;
	font-weight: 400;
	font-size: 14px;
	line-height: 150%;
	color: #bbbec7;
	text-align: center;
}

.input-item.input--table-item {
	height: 30px;
	border-radius: 6px;
	border: 1px solid #e6e8ec;
}

.td-status {
	text-transform: capitalize;
	white-space: nowrap;

	&.completed,
	&.success {
		color: #30c06e;
	}

	&.pending {
		color: #ff9f0a;
	}

	&.failed,
	&.rejected {
		color: #e04141;
	}
}

.table--type2.table--fiat-balance--custom .tr {
	grid-template-columns: 1fr 1fr;
}

.table--payment-method-crypto.deposit-accounts .tr {
	justify-items: start;
	grid-template-columns: 1.5fr 1.5fr 1.5fr 1.5fr 1.5fr 1.5fr 1.5fr 2fr 0.7fr;
}

.button--flex-end {
	justify-content: flex-end;
}

.popup-submit--column {
	flex-direction: column;
	gap: 20px;
}

.user-management-body--width-520 {
	max-width: 720px;
}

.input--row {
	display: flex;
	margin-bottom: 16px !important;
	justify-content: space-between;

	.input__name {
		margin-bottom: 0;
	}
}

.select-block {
	margin-bottom: 0;

	.select__custom {
		font-size: 16px;
		font-weight: 400;
		color: #bbbec7;
		display: inline-flex;
		align-items: center;

		&.select--selected-value {
			color: black;
		}
	}

	.select-input {
		border: 0;
		pointer-events: none;
		background: transparent;
		width: 100%;
		font: inherit;
		color: inherit;
		opacity: 0;
	}

	.select__drop .input-wrapper {
		margin-bottom: 5px;
	}

	.select__drop-item {
		.coin__text {
			font-weight: 400;
			font-size: 12px;
			color: #777e90;
		}
		.coin {
			&--type7 {
				align-items: center;
				gap: 10px;

				.coin__icon {
					min-width: 30px;
					width: 30px;
					height: 30px;
				}

				.coin__text-wrap {
					width: 100%;
					display: flex;
					justify-content: space-between;
					gap: 6px;
				}

				.coin__text {
					color: var(--gray800);
					font-size: 14px;
					font-style: normal;
					font-weight: 500;
					line-height: normal;
				}

				.coin__text-more {
					text-transform: capitalize;
					color: var(--text-secondary);
					font-size: 14px;
					font-style: normal;
					font-weight: 400;
					line-height: normal;
					margin-left: 6px;
				}
			}

			&--type10 {
				align-items: center;
				gap: 10px;

				.coin__icon {
					min-width: 24px;
					width: 24px;
					height: 24px;
				}

				.coin__text-wrap {
					width: 100%;
					display: flex;
					justify-content: space-between;
					gap: 6px;
				}

				.coin__text {
					color: var(--gray800);
					font-size: 14px;
					font-style: normal;
					font-weight: 500;
					line-height: normal;
				}

				.coin__text-more {
					text-transform: capitalize;
					color: var(--text-secondary);
					font-size: 14px;
					font-style: normal;
					font-weight: 400;
					line-height: normal;
					margin-left: 6px;
				}
			}
		}
	}
}

.input-wrapper--width-400 {
	width: 420px;
}

.input--row .select {
	width: 420px;
}

.input-item.input-high {
	padding-top: 10px;
	resize: none;
	height: 92px;
}

.input__name--align-center {
	align-self: center;
	color: var(--text-color2);
}

.select--selected-value {
	color: black;
}

.submit--width {
	max-width: 480px;
	gap: 20px;
}

.setting-content-back {
	padding: 8px;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	width: 40px;
	height: 40px;
	background: #f5f5f5;
	border-radius: 8px;
}

.table--user-management-reset-request--admin .tr {
	grid-template-columns:
		minmax(80px, 0.5fr) 170px minmax(90px, 0.9fr) minmax(70px, 0.9fr) minmax(100px, 0.5fr)
		minmax(120px, 0.8fr) 3fr 70px;
}

.table--user-management-reset-request--user .tr {
	grid-template-columns:
		minmax(80px, 0.5fr) 170px minmax(90px, 0.5fr) minmax(90px, 0.9fr) minmax(100px, 0.5fr)
		minmax(120px, 0.8fr) minmax(130px, 0.8fr) 3.5fr 70px !important;
}

//.table--user-management-fee-management-trade-fiat-fiat {
//	grid-template-columns: 1.5fr 1.5fr 1fr 1fr;
//}
.table--user-management-reset-request--user-password .tr {
	grid-template-columns:
		minmax(80px, 0.5fr) 170px minmax(90px, 0.5fr) minmax(90px, 0.9fr) minmax(100px, 0.5fr)
		minmax(120px, 0.8fr) 3.5fr 70px !important;
}

.table-block.table-block--offset-none {
	margin-top: 0;
}

.table-block.table-block--relative {
	position: relative;
}

.currencyButtonFile.currencyButtonFile--top {
	margin: 30px;
}

.select.select--fit {
	height: 30px;
	max-width: 175px;
}

.switch {
	position: relative;
}

.tooltip .bank-info {
	width: 100%;
}

.table-body {
	.bank-info-list-wrapper {
		width: 100%;
	}

	.tr {
		// .tooltip--bottom {
		// 	top: calc(100% + 3px);
		// }

		// &:last-child,
		// &:nth-last-child(2),
		// &:nth-last-child(3) {
		// 	.tooltip--bottom {
		// 		top: unset;
		// 		bottom: calc(100% + 3px);
		// 	}
		// }
	}

	&.table-body--four-elements {
		.tr {
			&:last-child {
				.tooltip--bottom {
					top: unset;
					bottom: calc(100% + 3px);
				}
			}

			&:nth-last-child(2) {
				.tooltip--bottom {
					top: unset;
					bottom: calc(100% + 3px);
				}

				.bank-info-list-wrapper {
					max-height: 100px;
					overflow-y: auto;
					overflow-x: hidden;
				}
			}

			&:nth-last-child(3) {
				.tooltip--bottom {
					top: calc(100% + 3px);
					bottom: unset;
				}

				.bank-info-list-wrapper {
					max-height: 100px;
					overflow-y: auto;
					overflow-x: hidden;
				}
			}
		}
	}

	&.table-body--tree-elements {
		.tr {
			&:nth-last-child(3) {
				.tooltip--bottom {
					top: calc(100% + 3px);
					bottom: unset;
				}

				.bank-info-list-wrapper {
					max-height: 100px;
					overflow-y: auto;
					overflow-x: hidden;
				}
			}

			&:nth-last-child(2) {
				.tooltip--bottom {
					top: unset;
					bottom: calc(100% + 3px);
				}

				.bank-info-list-wrapper {
					max-height: 50px;
					overflow-y: auto;
					overflow-x: hidden;
				}
			}

			&:last-child {
				.tooltip--bottom {
					top: unset;
					bottom: calc(100% + 3px);
				}

				.bank-info-list-wrapper {
					max-height: 100px;
					overflow-y: auto;
					overflow-x: hidden;
				}
			}
		}
	}

	&.table-body--two-elements {
		.tr {
			&:nth-last-child(2) {
				.tooltip--bottom {
					top: calc(100% + 3px);
					bottom: unset;
				}

				.bank-info-list-wrapper {
					max-height: 100px;
					overflow-y: auto;
					overflow-x: hidden;
				}
			}

			&:last-child {
				.tooltip--bottom {
					top: unset;
					bottom: calc(100% + 3px);
				}

				.bank-info-list-wrapper {
					max-height: 50px;
					overflow-y: auto;
					overflow-x: hidden;
				}
			}
		}
	}

	&.table-body--one-elements {
		.tr {
			&:last-child {
				.tooltip--bottom {
					top: calc(100% + 3px);
					bottom: unset;
				}

				.bank-info-list-wrapper {
					max-height: 100px;
					overflow-y: auto;
					overflow-x: hidden;
				}
			}
		}
	}
}
