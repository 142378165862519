.user-group-input {
	position: relative;

	&__input {
		position: relative;

		&:after {
			content: '';
			display: block;
			background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M7.66683 14.0026C11.1646 14.0026 14.0002 11.1671 14.0002 7.66927C14.0002 4.17147 11.1646 1.33594 7.66683 1.33594C4.16903 1.33594 1.3335 4.17147 1.3335 7.66927C1.3335 11.1671 4.16903 14.0026 7.66683 14.0026Z' stroke='%23BFBFBF' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M14.6668 14.6693L13.3335 13.3359' stroke='%23BFBFBF' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
			width: 16px;
			min-width: 16px;
			height: 16px;
			position: absolute;
			right: 16px;
			top: 50%;
			transform: translateY(-50%);
		}

		.input-item {
			padding-right: 40px;
		}
	}

	&__dropdown {
		position: absolute;
		z-index: 5;
		top: 100%;
		margin-top: 3px;
		left: 0;
		width: 100%;
		border-radius: 8px;
		border: 1px solid var(--primary-blue-200);
		background: var(--additional-white);
		box-shadow: 0 4px 15px 0 rgba(33, 44, 99, 0.09);
		display: flex;
		flex-direction: column;
		max-height: 180px;
		overflow: auto;
		scrollbar-color: #b6b6b6 transparent;
		scrollbar-width: thin;

		&::-webkit-scrollbar {
			width: 4px;
			height: 4px;
		}

		&::-webkit-scrollbar-thumb {
			border-radius: 2px;
			background: #b6b6b6;
		}
	}

	&__dropdown-item {
		display: flex;
		align-items: center;
		gap: 10px;
		padding: 12px 16px;
		color: var(--greyscale-grey-800);
		font-size: 14px;
		font-style: normal;
		font-weight: 500;
		line-height: normal;
		cursor: pointer;
		transition: var(--transition-base);

		&:hover {
			background: var(--Greyscale-Grey10);
		}

		&--add {
			color: var(--Blue-300);

			&:before {
				content: '';
				display: block;
				background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16' fill='none'%3E%3Cpath d='M7.50016 12.6693V8.5026H3.3335V7.5026H7.50016V3.33594H8.50016V7.5026H12.6668V8.5026H8.50016V12.6693H7.50016Z' fill='%230071AB'/%3E%3C/svg%3E");
				width: 16px;
				min-width: 16px;
				height: 16px;
			}
		}
	}

	&__loading {
		padding: 16px;
		display: flex;
		align-items: center;
		justify-content: center;
	}
}
