*[class*='--mt-0'] {
	margin-top: 0 !important;
}

*[class*='--mb-0'] {
	margin-bottom: 0 !important;
}

*[class*='--mb-40'] {
	margin-bottom: 40px;
}

*[class*="--gap-4"] {
	gap: 4px !important;
}

*[class*="--gap-40"] {
	gap: 40px !important;
}

.d-block {
	display: block !important;
}

.d-flex {
	display: flex !important;
}

.d-none {
	display: none !important;
}

.flex-column {
	flex-direction: column !important;
}

.flex-sm-row {

	@media only screen and (min-width: 575px) {
		flex-direction: row !important;
	}
}

@for $i from 1 through 20 {
	.mb-#{$i * 5} {
		margin-bottom: #{$i * 5}px !important;
	}
}

.mb-0 {
	margin-bottom: 0 !important;
}

.mb-4 {
	margin-bottom: 4px !important;
}

.mb-8 {
	margin-bottom: 8px !important;
}

.mb-12 {
	margin-bottom: 12px !important;
}

.mb-16 {
	margin-bottom: 16px !important;
}

@for $i from 1 through 20 {
	.mt-#{$i * 5} {
		margin-top: #{$i * 5}px !important;
	}
}

.mt-0 {
	margin-top: 0 !important;
}

.mt-16 {
	margin-top: 16px !important;
}

.mt-24 {
	margin-top: 24px !important;
}

@for $i from 1 through 20 {
	.gap-#{$i * 5} {
		gap: #{$i * 5}px !important;
	}
}

.gap-12 {
	gap: 12px !important;
}

@for $i from 1 through 20 {
	.gap-sm-#{$i * 5} {
		@media only screen and (min-width: 575px) {
			gap: #{$i * 5}px !important;
		}
	}
}

.w-full {
	width: 100% !important;
}

.w-sm-auto {
	@media only screen and (min-width: 575px) {
		width: auto !important;
	}
}

.text-center {
	text-align: center;
}

.text-left {
	text-align: left !important;
}

.justify-content-center {
	justify-content: center !important;
}

.justify-content-start {
	justify-content: flex-start !important;
}

.font-weight-semibold {
	font-weight: 600 !important;
}

.font-weight-medium {
	font-weight: 500 !important;
}
