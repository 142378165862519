.login-banner {
	display: none;
	position: relative;

	@media only screen and (min-width: 768px) {
		flex: 1 0 50%;
		display: block;
	}

	@media only screen and (min-width: 1024px) {
		flex: 1 0 540px;
	}

	img {
		max-width: 100%;
		height: auto;
		display: block;
	}

	&__text {
		color: var(--gray300);
		font-family: var(--font-sans);
		font-size: 12px;
		font-weight: 500;
		line-height: normal;
		position: absolute;
		top: 13%;
		left: 7%;

		@media only screen and (min-width: 992px) {
			top: 16%;
			font-size: 18px;
		}
	}
}
