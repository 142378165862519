.accordion {
	margin-top: 50px;
	display: flex;
	flex-direction: column;
	gap: 24px;

	&__item {
	}

	&__title {
		border-radius: 8px;
		background: var(--primary-blue-50);
		min-height: 56px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		gap: 10px;
		color: var(--greyscale-grey-800);
		font-size: 20px;
		font-style: normal;
		font-weight: 600;
		line-height: normal;
		padding: 10px 16px;
		cursor: pointer;

		@media only screen and (max-width: 767.98px) {
		}

		&.is-active {
			.accordion__title-arrow:before {
				transform: rotate(180deg);
			}
		}
	}

	&__title-arrow {
		border-radius: 6px;
		border: 1px solid var(--primary-blue-200);
		width: 34px;
		min-width: 34px;
		height: 34px;
		min-height: 34px;
		display: flex;
		align-items: center;
		justify-content: center;

		&:before {
			content: '';
			display: block;
			width: 20px;
			min-width: 20px;
			height: 20px;
			min-height: 20px;
			background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20' fill='none'%3E%3Cpath d='M15.8346 7.5L10.3549 12.9798C10.1596 13.175 9.84301 13.175 9.64775 12.9798L4.16797 7.5' stroke='%230071AB' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
			transition: var(--transition-base);
		}
	}

	&__content {
		padding: 24px 16px 16px 16px;

		p {
			color: var(--greyscale-grey-800);
			font-size: 16px;
			font-style: normal;
			font-weight: 400;
			line-height: 160%; /* 25.6px */
			margin-bottom: 20px;
		}

		a {
			color: var(--blue400);
			font-size: 16px;
			font-style: normal;
			font-weight: 400;
			line-height: 160%; /* 25.6px */
			text-decoration: underline;

			&:hover {
				text-decoration: none;
			}
		}

		ul {
			padding: 0;
			margin: 0 0 20px 0;
			list-style-type: none;

			li {
				color: var(--greyscale-grey-800);
				font-size: 16px;
				font-style: normal;
				font-weight: 400;
				line-height: 160%; /* 25.6px */
				position: relative;
				padding-left: 32px;

				&:before {
					content: '';
					display: block;
					background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'%3E%3Ccircle cx='12' cy='12' r='3' fill='%230071AB'/%3E%3C/svg%3E");
					width: 24px;
					min-width: 24px;
					height: 24px;
					min-height: 24px;
					position: absolute;
					top: 1px;
					left: 0;
				}

				&:not(:last-of-type) {
					margin-bottom: 10px;
				}
			}
		}
	}
}
