.active_filters {
	display: flex;
	align-items: center;
	justify-content: flex-end;
	gap: 16px;
	margin-top: 32px;
	margin-bottom: -14px;

	&.active_filters--bank-accounts-with-select {
		margin-top: 16px;
	}

	.active_filter {
		display: flex;
		align-items: center;
		gap: 8px;

		border: 1px solid #e6e8ec;
		border-radius: 4px;

		color: #173b58;
	}
}

.active_filter__button {
	color: #777e90;
}

.reset_filters {
	color: #fff;
	background: #3b97d3;
	border: 1px solid #3b97d3;
	border-radius: 4px;
}

.active_filter,
.active_filter__button,
.reset_filters {
	font: 400 12px/1 'Roboto', sans-serif;
}

.active_filter,
.reset_filters {
	height: 22px;
	padding: 0 8px;
}

.filters__search-block {
	float: right;
	display: flex;
	align-items: center;
	gap: 16px;

	.filter {
		margin-right: 0;
	}

	.phone-number-input {
		grid-template-columns: 0.4fr 1fr;
	}

	&.filters__search-block--transactions-trades {
		float: unset;

		.filter {
			margin-right: auto;
		}
	}

	&.filters__search-block--bank-accounts-with-select {
		float: unset;

		.filter {
			margin-right: auto;
		}

		.phone-number-input {
			width: 470px;
			grid-template-columns: 168px 1fr;
		}
	}

	@media screen and (max-width: 1500px) {
		float: unset;

		.filters__download-button {
			margin-left: auto;

			&:last-child {
				margin-left: 0;
			}
		}
	}

	&.filters__search-block--referral-by-user {
		@media screen and (max-width: 1650px) {
			.title {
				font-size: 22px !important;
			}
		}
		@media screen and (max-width: 1560px) {
			flex-wrap: wrap;
			.title-block.title-block--referral,
			.filter {
				width: 49%;
				margin-right: 0;
			}
			.filter {
				justify-content: flex-end;
			}
			.filters__download-button {
				margin-left: 0;
			}
		}
		@media screen and (max-width: 1150px) {
			.title-block.title-block--referral,
			.filter {
				width: 100%;
				margin-right: 0;
			}
			.filter {
				justify-content: unset;
			}
		}
	}
}

.filters__download-button {
	display: flex;
	align-items: center;
	color: #3b97d3;
	border: 1px solid #e6e8ec;
	border-radius: 8px;

	gap: 10px;
	height: 40px;
	padding: 0 24px;
}

.title-block.title-block--referral {
	margin-right: 20px;
}

.count-label--centered {
	position: relative;
	top: 15px;
}
