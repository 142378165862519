.custom-checkbox {
	display: inline-flex;
	align-items: center;
	gap: 6px;

	input {
		width: 18px;
		min-width: 18px;
		height: 18px;
	}
}
