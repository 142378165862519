.item-select {
	.select {
		background: #f7f7f7;

		.selectInput {
			font-family: 'Euclid Circular A', sans-serif;
			font-size: 14px;
			font-weight: 500;
			line-height: 22px;
			letter-spacing: 0;
			text-align: left;
			color: rgba(20, 20, 20, 1);

		}
	}
	.disabled-color {
		background: white;
	}
}
