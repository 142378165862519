.phone-dropdown {
	padding: 16px 8px;
	max-width: 273px;
	border: none;
	box-shadow: 0 0 2px rgb(40 41 61 / 4%), 0 4px 8px rgb(96 97 112 / 16%);
	border-radius: 8px;
	background: #ffffff;
	&::-webkit-scrollbar {
		width: 6px; /* ширина всей полосы прокрутки */
	}
	&::-webkit-scrollbar-thumb {
		border-radius: 2px;
		background-color: #b6b6b6;
	}
	&::-webkit-scrollbar-track {
		border-radius: 2px;
		margin: 80px 5px 10px 5px;
		background: #eeeeee;
	}
}

.phone-dropdown .country {
	display: flex;
	justify-content: space-between;
}

.react-tel-input .phone-dropdown .search-emoji {
	display: inline !important;
	visibility: hidden;
	position: absolute;
}

.react-tel-input .phone-dropdown .search-emoji::after {
	display: inline !important;
	visibility: visible;
	position: absolute;
	top: 22px;
	left: 20px;
	font-size: 15px;
	content: url(../../../inline-svg/search.svg);
}

.react-tel-input-wrapper {
	position: relative;

	.special-label {
		display: none;
	}

	.form-control {
		position: absolute;
		z-index: 10;
		left: 45px;
		top: 50%;
		padding: 0;
		width: 50px;
		height: 100%;
		border: none;
		pointer-events: none;
		background: transparent;
		transform: translateY(-50%);
		font-size: 14px;
		font-weight: 500;
		line-height: 180%; /* 25.2px */
		color: var(--greyscale-grey-800);
	}

	.react-tel-input {
		font-family: var(--font-sans);
		font-size: 14px;
		font-weight: 500;
		line-height: 180%; /* 25.2px */
		color: var(--greyscale-grey-800);
		position: absolute;
		top: 4px;
		left: 4px;
		z-index: 5;
		height: 36px;
		width: 117px;
	}

	.input-item {
		padding-left: 130px;
	}

	.selected-flag {
		width: 100%;
		margin-top: 0;
		padding: 0 0 0 8px;

		&:hover, &:focus {
			background-color: transparent;
		}
	}

	.flag-dropdown {
		width: 100%;
		border-radius: 4px;
		background: var(--primary-blue-50);
		border: 1px solid var(--primary-blue-50);
		height: 100%;
		top: 0;
		left: 0;
	}

	.react-tel-input .selected-flag {
		&.open {
			&:after {
				transform: rotate(180deg);
			}
		}

		&:after {
			content: '';
			display: block;
			position: absolute;
			top: calc(50% - 8.5px);
			right: 8px;
			width: 16px;
			min-width: 16px;
			height: 17px;
			min-height: 17px;
			background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='17' viewBox='0 0 16 17' fill='none'%3E%3Cpath d='M12.6654 6.5L8.35225 10.8131C8.15699 11.0084 7.84041 11.0084 7.64514 10.8131L3.33203 6.5' stroke='%23212C63' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
			transition: var(--transition-base);
		}

		.arrow {
			display: none;
		}

		.flag {
			//position: absolute;
			//top: 50%;
			//margin-top: -12px;
			//border-radius: 50%;
		}
	}

	/*.input--error .input-item,
	.input--error .textarea {
		border: 1px solid var(--greyscale-grey-100);
	}

	.input--success .input-item {
		border: 1px solid var(--greyscale-grey-100);
	}*/
}

.react-tel-input {

	.selected-flag .flag {
		margin-top: -10px;
	}

	.country-list {
		margin: 10px 0;
		border-radius: 8px;
		border: 1px solid var(--primary-blue-200);
		background: var(--additional-white);
		box-shadow: none;

		.flag {
			display: inline-block;
			position: absolute;
			margin: -2px 0 0 0;
			left: 16px;
			top: 50%;
			transform: translateY(-50%);
			background-image: url(../../../../assets/img/countries_sprite_30.png);
			background-repeat: no-repeat;
		}

		.ad {
			width: 30px;
			height: 30px;
			background-position: -5px -5px;
		}

		.ae {
			width: 30px;
			height: 30px;
			background-position: -45px -5px;
		}

		.af {
			width: 30px;
			height: 30px;
			background-position: -85px -5px;
		}

		.ag {
			width: 30px;
			height: 30px;
			background-position: -125px -5px;
		}

		.ai {
			width: 30px;
			height: 30px;
			background-position: -165px -5px;
		}

		.al {
			width: 30px;
			height: 30px;
			background-position: -205px -5px;
		}

		.am {
			width: 30px;
			height: 30px;
			background-position: -245px -5px;
		}

		.ao {
			width: 30px;
			height: 30px;
			background-position: -285px -5px;
		}

		.aq {
			width: 30px;
			height: 30px;
			background-position: -325px -5px;
		}

		.ar {
			width: 30px;
			height: 30px;
			background-position: -365px -5px;
		}

		.as {
			width: 30px;
			height: 30px;
			background-position: -405px -5px;
		}

		.at {
			width: 30px;
			height: 30px;
			background-position: -445px -5px;
		}

		.au {
			width: 30px;
			height: 30px;
			background-position: -485px -5px;
		}

		.aw {
			width: 30px;
			height: 30px;
			background-position: -525px -5px;
		}

		.ax {
			width: 30px;
			height: 30px;
			background-position: -565px -5px;
		}

		.az {
			width: 30px;
			height: 30px;
			background-position: -5px -45px;
		}

		.ba {
			width: 30px;
			height: 30px;
			background-position: -45px -45px;
		}

		.bb {
			width: 30px;
			height: 30px;
			background-position: -85px -45px;
		}

		.bd {
			width: 30px;
			height: 30px;
			background-position: -125px -45px;
		}

		.be {
			width: 30px;
			height: 30px;
			background-position: -165px -45px;
		}

		.bf {
			width: 30px;
			height: 30px;
			background-position: -205px -45px;
		}

		.bg {
			width: 30px;
			height: 30px;
			background-position: -245px -45px;
		}

		.bh {
			width: 30px;
			height: 30px;
			background-position: -285px -45px;
		}

		.bi {
			width: 30px;
			height: 30px;
			background-position: -325px -45px;
		}

		.bj {
			width: 30px;
			height: 30px;
			background-position: -365px -45px;
		}

		.bl {
			width: 30px;
			height: 30px;
			background-position: -405px -45px;
		}

		.bm {
			width: 30px;
			height: 30px;
			background-position: -445px -45px;
		}

		.bn {
			width: 30px;
			height: 30px;
			background-position: -485px -45px;
		}

		.bo {
			width: 30px;
			height: 30px;
			background-position: -525px -45px;
		}

		.bq {
			width: 30px;
			height: 30px;
			background-position: -565px -45px;
		}

		.br {
			width: 30px;
			height: 30px;
			background-position: -5px -85px;
		}

		.bs {
			width: 30px;
			height: 30px;
			background-position: -45px -85px;
		}

		.bt {
			width: 30px;
			height: 30px;
			background-position: -85px -85px;
		}

		.bv {
			width: 30px;
			height: 30px;
			background-position: -125px -85px;
		}

		.bw {
			width: 30px;
			height: 30px;
			background-position: -165px -85px;
		}

		.by {
			width: 30px;
			height: 30px;
			background-position: -205px -85px;
		}

		.bz {
			width: 30px;
			height: 30px;
			background-position: -245px -85px;
		}

		.ca {
			width: 30px;
			height: 30px;
			background-position: -285px -85px;
		}

		.cc {
			width: 30px;
			height: 30px;
			background-position: -325px -85px;
		}

		.cd {
			width: 30px;
			height: 30px;
			background-position: -365px -85px;
		}

		.cf {
			width: 30px;
			height: 30px;
			background-position: -405px -85px;
		}

		.cg {
			width: 30px;
			height: 30px;
			background-position: -445px -85px;
		}

		.ch {
			width: 30px;
			height: 30px;
			background-position: -485px -85px;
		}

		.ci {
			width: 30px;
			height: 30px;
			background-position: -525px -85px;
		}

		.ck {
			width: 30px;
			height: 30px;
			background-position: -565px -85px;
		}

		.cl {
			width: 30px;
			height: 30px;
			background-position: -5px -125px;
		}

		.cm {
			width: 30px;
			height: 30px;
			background-position: -45px -125px;
		}

		.cn {
			width: 30px;
			height: 30px;
			background-position: -85px -125px;
		}

		.co {
			width: 30px;
			height: 30px;
			background-position: -125px -125px;
		}

		.cr {
			width: 30px;
			height: 30px;
			background-position: -165px -125px;
		}

		.cu {
			width: 30px;
			height: 30px;
			background-position: -205px -125px;
		}

		.cv {
			width: 30px;
			height: 30px;
			background-position: -245px -125px;
		}

		.cw {
			width: 30px;
			height: 30px;
			background-position: -285px -125px;
		}

		.cx {
			width: 30px;
			height: 30px;
			background-position: -325px -125px;
		}

		.cy {
			width: 30px;
			height: 30px;
			background-position: -365px -125px;
		}

		.cz {
			width: 30px;
			height: 30px;
			background-position: -405px -125px;
		}

		.de {
			width: 30px;
			height: 30px;
			background-position: -445px -125px;
		}

		.dj {
			width: 30px;
			height: 30px;
			background-position: -485px -125px;
		}

		.dk {
			width: 30px;
			height: 30px;
			background-position: -525px -125px;
		}

		.dm {
			width: 30px;
			height: 30px;
			background-position: -565px -125px;
		}

		.do {
			width: 30px;
			height: 30px;
			background-position: -5px -165px;
		}

		.ds {
			width: 30px;
			height: 30px;
			background-position: -45px -165px;
		}

		.dz {
			width: 30px;
			height: 30px;
			background-position: -85px -165px;
		}

		.ec {
			width: 30px;
			height: 30px;
			background-position: -125px -165px;
		}

		.ee {
			width: 30px;
			height: 30px;
			background-position: -165px -165px;
		}

		.eg {
			width: 30px;
			height: 30px;
			background-position: -205px -165px;
		}

		.eh {
			width: 30px;
			height: 30px;
			background-position: -245px -165px;
		}

		.er {
			width: 30px;
			height: 30px;
			background-position: -285px -165px;
		}

		.es {
			width: 30px;
			height: 30px;
			background-position: -325px -165px;
		}

		.et {
			width: 30px;
			height: 30px;
			background-position: -365px -165px;
		}

		.fi {
			width: 30px;
			height: 30px;
			background-position: -405px -165px;
		}

		.fj {
			width: 30px;
			height: 30px;
			background-position: -445px -165px;
		}

		.fk {
			width: 30px;
			height: 30px;
			background-position: -485px -165px;
		}

		.fm {
			width: 30px;
			height: 30px;
			background-position: -525px -165px;
		}

		.fo {
			width: 30px;
			height: 30px;
			background-position: -565px -165px;
		}

		.fr {
			width: 30px;
			height: 30px;
			background-position: -5px -205px;
		}

		.ga {
			width: 30px;
			height: 30px;
			background-position: -45px -205px;
		}

		.gb {
			width: 30px;
			height: 30px;
			background-position: -85px -205px;
		}

		.gd {
			width: 30px;
			height: 30px;
			background-position: -125px -205px;
		}

		.ge {
			width: 30px;
			height: 30px;
			background-position: -165px -205px;
		}

		.gf {
			width: 30px;
			height: 30px;
			background-position: -205px -205px;
		}

		.gg {
			width: 30px;
			height: 30px;
			background-position: -245px -205px;
		}

		.gh {
			width: 30px;
			height: 30px;
			background-position: -285px -205px;
		}

		.gi {
			width: 30px;
			height: 30px;
			background-position: -325px -205px;
		}

		.gl {
			width: 30px;
			height: 30px;
			background-position: -365px -205px;
		}

		.gm {
			width: 30px;
			height: 30px;
			background-position: -405px -205px;
		}

		.gn {
			width: 30px;
			height: 30px;
			background-position: -445px -205px;
		}

		.gp {
			width: 30px;
			height: 30px;
			background-position: -485px -205px;
		}

		.gq {
			width: 30px;
			height: 30px;
			background-position: -525px -205px;
		}

		.gr {
			width: 30px;
			height: 30px;
			background-position: -565px -205px;
		}

		.gs {
			width: 30px;
			height: 30px;
			background-position: -5px -245px;
		}

		.gt {
			width: 30px;
			height: 30px;
			background-position: -45px -245px;
		}

		.gu {
			width: 30px;
			height: 30px;
			background-position: -85px -245px;
		}

		.gw {
			width: 30px;
			height: 30px;
			background-position: -125px -245px;
		}

		.gy {
			width: 30px;
			height: 30px;
			background-position: -165px -245px;
		}

		.hk {
			width: 30px;
			height: 30px;
			background-position: -205px -245px;
		}

		.hm {
			width: 30px;
			height: 30px;
			background-position: -245px -245px;
		}

		.hn {
			width: 30px;
			height: 30px;
			background-position: -285px -245px;
		}

		.hr {
			width: 30px;
			height: 30px;
			background-position: -325px -245px;
		}

		.ht {
			width: 30px;
			height: 30px;
			background-position: -365px -245px;
		}

		.hu {
			width: 30px;
			height: 30px;
			background-position: -405px -245px;
		}

		.id {
			width: 30px;
			height: 30px;
			background-position: -445px -245px;
		}

		.ie {
			width: 30px;
			height: 30px;
			background-position: -485px -245px;
		}

		.il {
			width: 30px;
			height: 30px;
			background-position: -525px -245px;
		}

		.im {
			width: 30px;
			height: 30px;
			background-position: -565px -245px;
		}

		.in {
			width: 30px;
			height: 30px;
			background-position: -5px -285px;
		}

		.io {
			width: 30px;
			height: 30px;
			background-position: -45px -285px;
		}

		.iq {
			width: 30px;
			height: 30px;
			background-position: -85px -285px;
		}

		.ir {
			width: 30px;
			height: 30px;
			background-position: -125px -285px;
		}

		.is {
			width: 30px;
			height: 30px;
			background-position: -165px -285px;
		}

		.it {
			width: 30px;
			height: 30px;
			background-position: -205px -285px;
		}

		.je {
			width: 30px;
			height: 30px;
			background-position: -245px -285px;
		}

		.jm {
			width: 30px;
			height: 30px;
			background-position: -285px -285px;
		}

		.jo {
			width: 30px;
			height: 30px;
			background-position: -325px -285px;
		}

		.jp {
			width: 30px;
			height: 30px;
			background-position: -365px -285px;
		}

		.ke {
			width: 30px;
			height: 30px;
			background-position: -405px -285px;
		}

		.kg {
			width: 30px;
			height: 30px;
			background-position: -445px -285px;
		}

		.kh {
			width: 30px;
			height: 30px;
			background-position: -485px -285px;
		}

		.ki {
			width: 30px;
			height: 30px;
			background-position: -525px -285px;
		}

		.km {
			width: 30px;
			height: 30px;
			background-position: -565px -285px;
		}

		.kn {
			width: 30px;
			height: 30px;
			background-position: -5px -325px;
		}

		.kp {
			width: 30px;
			height: 30px;
			background-position: -45px -325px;
		}

		.kr {
			width: 30px;
			height: 30px;
			background-position: -85px -325px;
		}

		.kw {
			width: 30px;
			height: 30px;
			background-position: -125px -325px;
		}

		.ky {
			width: 30px;
			height: 30px;
			background-position: -165px -325px;
		}

		.kz {
			width: 30px;
			height: 30px;
			background-position: -205px -325px;
		}

		.la {
			width: 30px;
			height: 30px;
			background-position: -245px -325px;
		}

		.lb {
			width: 30px;
			height: 30px;
			background-position: -285px -325px;
		}

		.lc {
			width: 30px;
			height: 30px;
			background-position: -325px -325px;
		}

		.li {
			width: 30px;
			height: 30px;
			background-position: -365px -325px;
		}

		.lk {
			width: 30px;
			height: 30px;
			background-position: -405px -325px;
		}

		.lr {
			width: 30px;
			height: 30px;
			background-position: -445px -325px;
		}

		.ls {
			width: 30px;
			height: 30px;
			background-position: -485px -325px;
		}

		.lt {
			width: 30px;
			height: 30px;
			background-position: -525px -325px;
		}

		.lu {
			width: 30px;
			height: 30px;
			background-position: -565px -325px;
		}

		.lv {
			width: 30px;
			height: 30px;
			background-position: -5px -365px;
		}

		.ly {
			width: 30px;
			height: 30px;
			background-position: -45px -365px;
		}

		.ma {
			width: 30px;
			height: 30px;
			background-position: -85px -365px;
		}

		.mc {
			width: 30px;
			height: 30px;
			background-position: -125px -365px;
		}

		.md {
			width: 30px;
			height: 30px;
			background-position: -165px -365px;
		}

		.me {
			width: 30px;
			height: 30px;
			background-position: -205px -365px;
		}

		.mf {
			width: 30px;
			height: 30px;
			background-position: -245px -365px;
		}

		.mg {
			width: 30px;
			height: 30px;
			background-position: -285px -365px;
		}

		.mh {
			width: 30px;
			height: 30px;
			background-position: -325px -365px;
		}

		.mk {
			width: 30px;
			height: 30px;
			background-position: -365px -365px;
		}

		.ml {
			width: 30px;
			height: 30px;
			background-position: -405px -365px;
		}

		.mm {
			width: 30px;
			height: 30px;
			background-position: -445px -365px;
		}

		.mn {
			width: 30px;
			height: 30px;
			background-position: -485px -365px;
		}

		.mo {
			width: 30px;
			height: 30px;
			background-position: -525px -365px;
		}

		.mp {
			width: 30px;
			height: 30px;
			background-position: -565px -365px;
		}

		.mq {
			width: 30px;
			height: 30px;
			background-position: -5px -405px;
		}

		.mr {
			width: 30px;
			height: 30px;
			background-position: -45px -405px;
		}

		.ms {
			width: 30px;
			height: 30px;
			background-position: -85px -405px;
		}

		.mt {
			width: 30px;
			height: 30px;
			background-position: -125px -405px;
		}

		.mu {
			width: 30px;
			height: 30px;
			background-position: -165px -405px;
		}

		.mv {
			width: 30px;
			height: 30px;
			background-position: -205px -405px;
		}

		.mw {
			width: 30px;
			height: 30px;
			background-position: -245px -405px;
		}

		.mx {
			width: 30px;
			height: 30px;
			background-position: -285px -405px;
		}

		.my {
			width: 30px;
			height: 30px;
			background-position: -325px -405px;
		}

		.mz {
			width: 30px;
			height: 30px;
			background-position: -365px -405px;
		}

		.na {
			width: 30px;
			height: 30px;
			background-position: -405px -405px;
		}

		.nc {
			width: 30px;
			height: 30px;
			background-position: -445px -405px;
		}

		.ne {
			width: 30px;
			height: 30px;
			background-position: -485px -405px;
		}

		.nf {
			width: 30px;
			height: 30px;
			background-position: -525px -405px;
		}

		.ng {
			width: 30px;
			height: 30px;
			background-position: -565px -405px;
		}

		.ni {
			width: 30px;
			height: 30px;
			background-position: -5px -445px;
		}

		.nl {
			width: 30px;
			height: 30px;
			background-position: -45px -445px;
		}

		.no {
			width: 30px;
			height: 30px;
			background-position: -85px -445px;
		}

		.np {
			width: 30px;
			height: 30px;
			background-position: -125px -445px;
		}

		.nr {
			width: 30px;
			height: 30px;
			background-position: -165px -445px;
		}

		.nu {
			width: 30px;
			height: 30px;
			background-position: -205px -445px;
		}

		.nz {
			width: 30px;
			height: 30px;
			background-position: -245px -445px;
		}

		.om {
			width: 30px;
			height: 30px;
			background-position: -285px -445px;
		}

		.pa {
			width: 30px;
			height: 30px;
			background-position: -325px -445px;
		}

		.pe {
			width: 30px;
			height: 30px;
			background-position: -365px -445px;
		}

		.pf {
			width: 30px;
			height: 30px;
			background-position: -405px -445px;
		}

		.pg {
			width: 30px;
			height: 30px;
			background-position: -445px -445px;
		}

		.ph {
			width: 30px;
			height: 30px;
			background-position: -485px -445px;
		}

		.pk {
			width: 30px;
			height: 30px;
			background-position: -525px -445px;
		}

		.pl {
			width: 30px;
			height: 30px;
			background-position: -565px -445px;
		}

		.pm {
			width: 30px;
			height: 30px;
			background-position: -5px -485px;
		}

		.pn {
			width: 30px;
			height: 30px;
			background-position: -45px -485px;
		}

		.pr {
			width: 30px;
			height: 30px;
			background-position: -85px -485px;
		}

		.ps {
			width: 30px;
			height: 30px;
			background-position: -125px -485px;
		}

		.pt {
			width: 30px;
			height: 30px;
			background-position: -165px -485px;
		}

		.pw {
			width: 30px;
			height: 30px;
			background-position: -205px -485px;
		}

		.py {
			width: 30px;
			height: 30px;
			background-position: -245px -485px;
		}

		.qa {
			width: 30px;
			height: 30px;
			background-position: -285px -485px;
		}

		.re {
			width: 30px;
			height: 30px;
			background-position: -325px -485px;
		}

		.ro {
			width: 30px;
			height: 30px;
			background-position: -365px -485px;
		}

		.rs {
			width: 30px;
			height: 30px;
			background-position: -405px -485px;
		}

		.ru {
			width: 30px;
			height: 30px;
			background-position: -445px -485px;
		}

		.rw {
			width: 30px;
			height: 30px;
			background-position: -485px -485px;
		}

		.sa {
			width: 30px;
			height: 30px;
			background-position: -525px -485px;
		}

		.sb {
			width: 30px;
			height: 30px;
			background-position: -565px -485px;
		}

		.sc {
			width: 30px;
			height: 30px;
			background-position: -5px -525px;
		}

		.sd {
			width: 30px;
			height: 30px;
			background-position: -45px -525px;
		}

		.se {
			width: 30px;
			height: 30px;
			background-position: -85px -525px;
		}

		.sg {
			width: 30px;
			height: 30px;
			background-position: -125px -525px;
		}

		.sh {
			width: 30px;
			height: 30px;
			background-position: -165px -525px;
		}

		.si {
			width: 30px;
			height: 30px;
			background-position: -205px -525px;
		}

		.sj {
			width: 30px;
			height: 30px;
			background-position: -245px -525px;
		}

		.sk {
			width: 30px;
			height: 30px;
			background-position: -285px -525px;
		}

		.sl {
			width: 30px;
			height: 30px;
			background-position: -325px -525px;
		}

		.sm {
			width: 30px;
			height: 30px;
			background-position: -365px -525px;
		}

		.sn {
			width: 30px;
			height: 30px;
			background-position: -405px -525px;
		}

		.so {
			width: 30px;
			height: 30px;
			background-position: -445px -525px;
		}

		.sr {
			width: 30px;
			height: 30px;
			background-position: -485px -525px;
		}

		.ss {
			width: 30px;
			height: 30px;
			background-position: -525px -525px;
		}

		.st {
			width: 30px;
			height: 30px;
			background-position: -565px -525px;
		}

		.sv {
			width: 30px;
			height: 30px;
			background-position: -5px -565px;
		}

		.sx {
			width: 30px;
			height: 30px;
			background-position: -45px -565px;
		}

		.sy {
			width: 30px;
			height: 30px;
			background-position: -85px -565px;
		}

		.sz {
			width: 30px;
			height: 30px;
			background-position: -125px -565px;
		}

		.tc {
			width: 30px;
			height: 30px;
			background-position: -165px -565px;
		}

		.td {
			width: 30px;
			height: 30px;
			background-position: -205px -565px;
		}

		.tf {
			width: 30px;
			height: 30px;
			background-position: -245px -565px;
		}

		.tg {
			width: 30px;
			height: 30px;
			background-position: -285px -565px;
		}

		.th {
			width: 30px;
			height: 30px;
			background-position: -325px -565px;
		}

		.tj {
			width: 30px;
			height: 30px;
			background-position: -365px -565px;
		}

		.tk {
			width: 30px;
			height: 30px;
			background-position: -405px -565px;
		}

		.tl {
			width: 30px;
			height: 30px;
			background-position: -445px -565px;
		}

		.tm {
			width: 30px;
			height: 30px;
			background-position: -485px -565px;
		}

		.tn {
			width: 30px;
			height: 30px;
			background-position: -525px -565px;
		}

		.to {
			width: 30px;
			height: 30px;
			background-position: -565px -565px;
		}

		.tr {
			width: 30px;
			height: 30px;
			background-position: -605px -5px;
		}

		.tt {
			width: 30px;
			height: 30px;
			background-position: -605px -45px;
		}

		.tv {
			width: 30px;
			height: 30px;
			background-position: -605px -85px;
		}

		.tw {
			width: 30px;
			height: 30px;
			background-position: -605px -125px;
		}

		.tz {
			width: 30px;
			height: 30px;
			background-position: -605px -165px;
		}

		.ua {
			width: 30px;
			height: 30px;
			background-position: -605px -205px;
		}

		.ug {
			width: 30px;
			height: 30px;
			background-position: -605px -245px;
		}

		.um {
			width: 30px;
			height: 30px;
			background-position: -605px -285px;
		}

		.us {
			width: 30px;
			height: 30px;
			background-position: -605px -325px;
		}

		.uy {
			width: 30px;
			height: 30px;
			background-position: -605px -365px;
		}

		.uz {
			width: 30px;
			height: 30px;
			background-position: -605px -405px;
		}

		.va {
			width: 30px;
			height: 30px;
			background-position: -605px -445px;
		}

		.vc {
			width: 30px;
			height: 30px;
			background-position: -605px -485px;
		}

		.ve {
			width: 30px;
			height: 30px;
			background-position: -605px -525px;
		}

		.vg {
			width: 30px;
			height: 30px;
			background-position: -605px -565px;
		}

		.vi {
			width: 30px;
			height: 30px;
			background-position: -5px -605px;
		}

		.vn {
			width: 30px;
			height: 30px;
			background-position: -45px -605px;
		}

		.vu {
			width: 30px;
			height: 30px;
			background-position: -85px -605px;
		}

		.wf {
			width: 30px;
			height: 30px;
			background-position: -125px -605px;
		}

		.ws {
			width: 30px;
			height: 30px;
			background-position: -165px -605px;
		}

		.xk {
			width: 30px;
			height: 30px;
			background-position: -205px -605px;
		}

		.ye {
			width: 30px;
			height: 30px;
			background-position: -245px -605px;
		}

		.yt {
			width: 30px;
			height: 30px;
			background-position: -285px -605px;
		}

		.za {
			width: 30px;
			height: 30px;
			background-position: -325px -605px;
		}

		.zm {
			width: 30px;
			height: 30px;
			background-position: -365px -605px;
		}

		.zw {
			width: 30px;
			height: 30px;
			background-position: -405px -605px;
		}

		.country-name {
			color: var(--text-secondary);
			font-size: 14px;
			font-style: normal;
			font-weight: 400;
			line-height: normal;
		}

		.country {
			position: relative;
			align-items: center;
			border-radius: 8px;
			padding: 12px 16px 12px 56px;
			background-color: transparent;
			transition: background-color var(--transition-base);

			@media only screen and (min-width: 576px) {
				padding-right: 90px;
			}

			&:hover,
			&.highlight {
				background-color: var(--greyscale-grey-10);
			}

			.dial-code {
				color: var(--gray800);
				font-size: 14px;
				font-style: normal;
				font-weight: 500;
				line-height: normal;
			}
		}

		.search {
			padding: 10px 0 0 0;
			z-index: 1;
		}

		.search-box {
			border: 1px solid var(--greyscale-grey-100);
			border-radius: 10px;
			font-size: 14px;
			line-height: 180%;
			margin-left: 0;

			&::-webkit-search-cancel-button {
				cursor: pointer;
				margin-right: 0;
				-webkit-appearance: none;
				height: 20px;
				width: 20px;
				background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20' fill='none'%3E%3Cpath d='M6.8763 13.9974L10.0013 10.8724L13.1263 13.9974L14.0013 13.1224L10.8763 9.9974L14.0013 6.8724L13.1263 5.9974L10.0013 9.1224L6.8763 5.9974L6.0013 6.8724L9.1263 9.9974L6.0013 13.1224L6.8763 13.9974ZM10.0013 18.3307C8.86241 18.3307 7.78603 18.112 6.77214 17.6745C5.75825 17.237 4.87283 16.6398 4.11589 15.8828C3.35894 15.1259 2.76172 14.2405 2.32422 13.2266C1.88672 12.2127 1.66797 11.1363 1.66797 9.9974C1.66797 8.84462 1.88672 7.76129 2.32422 6.7474C2.76172 5.73351 3.35894 4.85156 4.11589 4.10156C4.87283 3.35156 5.75825 2.75781 6.77214 2.32031C7.78603 1.88281 8.86241 1.66406 10.0013 1.66406C11.1541 1.66406 12.2374 1.88281 13.2513 2.32031C14.2652 2.75781 15.1471 3.35156 15.8971 4.10156C16.6471 4.85156 17.2409 5.73351 17.6784 6.7474C18.1159 7.76129 18.3346 8.84462 18.3346 9.9974C18.3346 11.1363 18.1159 12.2127 17.6784 13.2266C17.2409 14.2405 16.6471 15.1259 15.8971 15.8828C15.1471 16.6398 14.2652 17.237 13.2513 17.6745C12.2374 18.112 11.1541 18.3307 10.0013 18.3307ZM10.0013 17.0807C11.9735 17.0807 13.6471 16.3898 15.0221 15.0078C16.3971 13.6259 17.0846 11.9557 17.0846 9.9974C17.0846 8.02517 16.3971 6.35156 15.0221 4.97656C13.6471 3.60156 11.9735 2.91406 10.0013 2.91406C8.04297 2.91406 6.37283 3.60156 4.99089 4.97656C3.60894 6.35156 2.91797 8.02517 2.91797 9.9974C2.91797 11.9557 3.60894 13.6259 4.99089 15.0078C6.37283 16.3898 8.04297 17.0807 10.0013 17.0807Z' fill='%23212C63'/%3E%3C/svg%3E");
			}
		}
	}

	.flag {
		background-image: url(../../../../assets/img/countries_sprite_20.png);
		background-repeat: no-repeat;
		display: block;
	}

	.ad {
		width: 20px;
		height: 20px;
		background-position: -5px -5px;
	}

	.ae {
		width: 20px;
		height: 20px;
		background-position: -35px -5px;
	}

	.af {
		width: 20px;
		height: 20px;
		background-position: -65px -5px;
	}

	.ag {
		width: 20px;
		height: 20px;
		background-position: -95px -5px;
	}

	.ai {
		width: 20px;
		height: 20px;
		background-position: -125px -5px;
	}

	.al {
		width: 20px;
		height: 20px;
		background-position: -155px -5px;
	}

	.am {
		width: 20px;
		height: 20px;
		background-position: -185px -5px;
	}

	.ao {
		width: 20px;
		height: 20px;
		background-position: -215px -5px;
	}

	.aq {
		width: 20px;
		height: 20px;
		background-position: -245px -5px;
	}

	.ar {
		width: 20px;
		height: 20px;
		background-position: -275px -5px;
	}

	.as {
		width: 20px;
		height: 20px;
		background-position: -305px -5px;
	}

	.at {
		width: 20px;
		height: 20px;
		background-position: -335px -5px;
	}

	.au {
		width: 20px;
		height: 20px;
		background-position: -365px -5px;
	}

	.aw {
		width: 20px;
		height: 20px;
		background-position: -395px -5px;
	}

	.ax {
		width: 20px;
		height: 20px;
		background-position: -425px -5px;
	}

	.az {
		width: 20px;
		height: 20px;
		background-position: -5px -35px;
	}

	.ba {
		width: 20px;
		height: 20px;
		background-position: -35px -35px;
	}

	.bb {
		width: 20px;
		height: 20px;
		background-position: -65px -35px;
	}

	.bd {
		width: 20px;
		height: 20px;
		background-position: -95px -35px;
	}

	.be {
		width: 20px;
		height: 20px;
		background-position: -125px -35px;
	}

	.bf {
		width: 20px;
		height: 20px;
		background-position: -155px -35px;
	}

	.bg {
		width: 20px;
		height: 20px;
		background-position: -185px -35px;
	}

	.bh {
		width: 20px;
		height: 20px;
		background-position: -215px -35px;
	}

	.bi {
		width: 20px;
		height: 20px;
		background-position: -245px -35px;
	}

	.bj {
		width: 20px;
		height: 20px;
		background-position: -275px -35px;
	}

	.bl {
		width: 20px;
		height: 20px;
		background-position: -305px -35px;
	}

	.bm {
		width: 20px;
		height: 20px;
		background-position: -335px -35px;
	}

	.bn {
		width: 20px;
		height: 20px;
		background-position: -365px -35px;
	}

	.bo {
		width: 20px;
		height: 20px;
		background-position: -395px -35px;
	}

	.bq {
		width: 20px;
		height: 20px;
		background-position: -425px -35px;
	}

	.br {
		width: 20px;
		height: 20px;
		background-position: -5px -65px;
	}

	.bs {
		width: 20px;
		height: 20px;
		background-position: -35px -65px;
	}

	.bt {
		width: 20px;
		height: 20px;
		background-position: -65px -65px;
	}

	.bv {
		width: 20px;
		height: 20px;
		background-position: -95px -65px;
	}

	.bw {
		width: 20px;
		height: 20px;
		background-position: -125px -65px;
	}

	.by {
		width: 20px;
		height: 20px;
		background-position: -155px -65px;
	}

	.bz {
		width: 20px;
		height: 20px;
		background-position: -185px -65px;
	}

	.ca {
		width: 20px;
		height: 20px;
		background-position: -215px -65px;
	}

	.cc {
		width: 20px;
		height: 20px;
		background-position: -245px -65px;
	}

	.cd {
		width: 20px;
		height: 20px;
		background-position: -275px -65px;
	}

	.cf {
		width: 20px;
		height: 20px;
		background-position: -305px -65px;
	}

	.cg {
		width: 20px;
		height: 20px;
		background-position: -335px -65px;
	}

	.ch {
		width: 20px;
		height: 20px;
		background-position: -365px -65px;
	}

	.ci {
		width: 20px;
		height: 20px;
		background-position: -395px -65px;
	}

	.ck {
		width: 20px;
		height: 20px;
		background-position: -425px -65px;
	}

	.cl {
		width: 20px;
		height: 20px;
		background-position: -5px -95px;
	}

	.cm {
		width: 20px;
		height: 20px;
		background-position: -35px -95px;
	}

	.cn {
		width: 20px;
		height: 20px;
		background-position: -65px -95px;
	}

	.co {
		width: 20px;
		height: 20px;
		background-position: -95px -95px;
	}

	.cr {
		width: 20px;
		height: 20px;
		background-position: -125px -95px;
	}

	.cu {
		width: 20px;
		height: 20px;
		background-position: -155px -95px;
	}

	.cv {
		width: 20px;
		height: 20px;
		background-position: -185px -95px;
	}

	.cw {
		width: 20px;
		height: 20px;
		background-position: -215px -95px;
	}

	.cx {
		width: 20px;
		height: 20px;
		background-position: -245px -95px;
	}

	.cy {
		width: 20px;
		height: 20px;
		background-position: -275px -95px;
	}

	.cz {
		width: 20px;
		height: 20px;
		background-position: -305px -95px;
	}

	.de {
		width: 20px;
		height: 20px;
		background-position: -335px -95px;
	}

	.dj {
		width: 20px;
		height: 20px;
		background-position: -365px -95px;
	}

	.dk {
		width: 20px;
		height: 20px;
		background-position: -395px -95px;
	}

	.dm {
		width: 20px;
		height: 20px;
		background-position: -425px -95px;
	}

	.do {
		width: 20px;
		height: 20px;
		background-position: -5px -125px;
	}

	.ds {
		width: 20px;
		height: 20px;
		background-position: -35px -125px;
	}

	.dz {
		width: 20px;
		height: 20px;
		background-position: -65px -125px;
	}

	.ec {
		width: 20px;
		height: 20px;
		background-position: -95px -125px;
	}

	.ee {
		width: 20px;
		height: 20px;
		background-position: -125px -125px;
	}

	.eg {
		width: 20px;
		height: 20px;
		background-position: -155px -125px;
	}

	.eh {
		width: 20px;
		height: 20px;
		background-position: -185px -125px;
	}

	.er {
		width: 20px;
		height: 20px;
		background-position: -215px -125px;
	}

	.es {
		width: 20px;
		height: 20px;
		background-position: -245px -125px;
	}

	.et {
		width: 20px;
		height: 20px;
		background-position: -275px -125px;
	}

	.fi {
		width: 20px;
		height: 20px;
		background-position: -305px -125px;
	}

	.fj {
		width: 20px;
		height: 20px;
		background-position: -335px -125px;
	}

	.fk {
		width: 20px;
		height: 20px;
		background-position: -365px -125px;
	}

	.fm {
		width: 20px;
		height: 20px;
		background-position: -395px -125px;
	}

	.fo {
		width: 20px;
		height: 20px;
		background-position: -425px -125px;
	}

	.fr {
		width: 20px;
		height: 20px;
		background-position: -5px -155px;
	}

	.ga {
		width: 20px;
		height: 20px;
		background-position: -35px -155px;
	}

	.gb {
		width: 20px;
		height: 20px;
		background-position: -65px -155px;
	}

	.gd {
		width: 20px;
		height: 20px;
		background-position: -95px -155px;
	}

	.ge {
		width: 20px;
		height: 20px;
		background-position: -125px -155px;
	}

	.gf {
		width: 20px;
		height: 20px;
		background-position: -155px -155px;
	}

	.gg {
		width: 20px;
		height: 20px;
		background-position: -185px -155px;
	}

	.gh {
		width: 20px;
		height: 20px;
		background-position: -215px -155px;
	}

	.gi {
		width: 20px;
		height: 20px;
		background-position: -245px -155px;
	}

	.gl {
		width: 20px;
		height: 20px;
		background-position: -275px -155px;
	}

	.gm {
		width: 20px;
		height: 20px;
		background-position: -305px -155px;
	}

	.gn {
		width: 20px;
		height: 20px;
		background-position: -335px -155px;
	}

	.gp {
		width: 20px;
		height: 20px;
		background-position: -365px -155px;
	}

	.gq {
		width: 20px;
		height: 20px;
		background-position: -395px -155px;
	}

	.gr {
		width: 20px;
		height: 20px;
		background-position: -425px -155px;
	}

	.gs {
		width: 20px;
		height: 20px;
		background-position: -5px -185px;
	}

	.gt {
		width: 20px;
		height: 20px;
		background-position: -35px -185px;
	}

	.gu {
		width: 20px;
		height: 20px;
		background-position: -65px -185px;
	}

	.gw {
		width: 20px;
		height: 20px;
		background-position: -95px -185px;
	}

	.gy {
		width: 20px;
		height: 20px;
		background-position: -125px -185px;
	}

	.hk {
		width: 20px;
		height: 20px;
		background-position: -155px -185px;
	}

	.hm {
		width: 20px;
		height: 20px;
		background-position: -185px -185px;
	}

	.hn {
		width: 20px;
		height: 20px;
		background-position: -215px -185px;
	}

	.hr {
		width: 20px;
		height: 20px;
		background-position: -245px -185px;
	}

	.ht {
		width: 20px;
		height: 20px;
		background-position: -275px -185px;
	}

	.hu {
		width: 20px;
		height: 20px;
		background-position: -305px -185px;
	}

	.id {
		width: 20px;
		height: 20px;
		background-position: -335px -185px;
	}

	.ie {
		width: 20px;
		height: 20px;
		background-position: -365px -185px;
	}

	.il {
		width: 20px;
		height: 20px;
		background-position: -395px -185px;
	}

	.im {
		width: 20px;
		height: 20px;
		background-position: -425px -185px;
	}

	.in {
		width: 20px;
		height: 20px;
		background-position: -5px -215px;
	}

	.io {
		width: 20px;
		height: 20px;
		background-position: -35px -215px;
	}

	.iq {
		width: 20px;
		height: 20px;
		background-position: -65px -215px;
	}

	.ir {
		width: 20px;
		height: 20px;
		background-position: -95px -215px;
	}

	.is {
		width: 20px;
		height: 20px;
		background-position: -125px -215px;
	}

	.it {
		width: 20px;
		height: 20px;
		background-position: -155px -215px;
	}

	.je {
		width: 20px;
		height: 20px;
		background-position: -185px -215px;
	}

	.jm {
		width: 20px;
		height: 20px;
		background-position: -215px -215px;
	}

	.jo {
		width: 20px;
		height: 20px;
		background-position: -245px -215px;
	}

	.jp {
		width: 20px;
		height: 20px;
		background-position: -275px -215px;
	}

	.ke {
		width: 20px;
		height: 20px;
		background-position: -305px -215px;
	}

	.kg {
		width: 20px;
		height: 20px;
		background-position: -335px -215px;
	}

	.kh {
		width: 20px;
		height: 20px;
		background-position: -365px -215px;
	}

	.ki {
		width: 20px;
		height: 20px;
		background-position: -395px -215px;
	}

	.km {
		width: 20px;
		height: 20px;
		background-position: -425px -215px;
	}

	.kn {
		width: 20px;
		height: 20px;
		background-position: -5px -245px;
	}

	.kp {
		width: 20px;
		height: 20px;
		background-position: -35px -245px;
	}

	.kr {
		width: 20px;
		height: 20px;
		background-position: -65px -245px;
	}

	.kw {
		width: 20px;
		height: 20px;
		background-position: -95px -245px;
	}

	.ky {
		width: 20px;
		height: 20px;
		background-position: -125px -245px;
	}

	.kz {
		width: 20px;
		height: 20px;
		background-position: -155px -245px;
	}

	.la {
		width: 20px;
		height: 20px;
		background-position: -185px -245px;
	}

	.lb {
		width: 20px;
		height: 20px;
		background-position: -215px -245px;
	}

	.lc {
		width: 20px;
		height: 20px;
		background-position: -245px -245px;
	}

	.li {
		width: 20px;
		height: 20px;
		background-position: -275px -245px;
	}

	.lk {
		width: 20px;
		height: 20px;
		background-position: -305px -245px;
	}

	.lr {
		width: 20px;
		height: 20px;
		background-position: -335px -245px;
	}

	.ls {
		width: 20px;
		height: 20px;
		background-position: -365px -245px;
	}

	.lt {
		width: 20px;
		height: 20px;
		background-position: -395px -245px;
	}

	.lu {
		width: 20px;
		height: 20px;
		background-position: -425px -245px;
	}

	.lv {
		width: 20px;
		height: 20px;
		background-position: -5px -275px;
	}

	.ly {
		width: 20px;
		height: 20px;
		background-position: -35px -275px;
	}

	.ma {
		width: 20px;
		height: 20px;
		background-position: -65px -275px;
	}

	.mc {
		width: 20px;
		height: 20px;
		background-position: -95px -275px;
	}

	.md {
		width: 20px;
		height: 20px;
		background-position: -125px -275px;
	}

	.me {
		width: 20px;
		height: 20px;
		background-position: -155px -275px;
	}

	.mf {
		width: 20px;
		height: 20px;
		background-position: -185px -275px;
	}

	.mg {
		width: 20px;
		height: 20px;
		background-position: -215px -275px;
	}

	.mh {
		width: 20px;
		height: 20px;
		background-position: -245px -275px;
	}

	.mk {
		width: 20px;
		height: 20px;
		background-position: -275px -275px;
	}

	.ml {
		width: 20px;
		height: 20px;
		background-position: -305px -275px;
	}

	.mm {
		width: 20px;
		height: 20px;
		background-position: -335px -275px;
	}

	.mn {
		width: 20px;
		height: 20px;
		background-position: -365px -275px;
	}

	.mo {
		width: 20px;
		height: 20px;
		background-position: -395px -275px;
	}

	.mp {
		width: 20px;
		height: 20px;
		background-position: -425px -275px;
	}

	.mq {
		width: 20px;
		height: 20px;
		background-position: -5px -305px;
	}

	.mr {
		width: 20px;
		height: 20px;
		background-position: -35px -305px;
	}

	.ms {
		width: 20px;
		height: 20px;
		background-position: -65px -305px;
	}

	.mt {
		width: 20px;
		height: 20px;
		background-position: -95px -305px;
	}

	.mu {
		width: 20px;
		height: 20px;
		background-position: -125px -305px;
	}

	.mv {
		width: 20px;
		height: 20px;
		background-position: -155px -305px;
	}

	.mw {
		width: 20px;
		height: 20px;
		background-position: -185px -305px;
	}

	.mx {
		width: 20px;
		height: 20px;
		background-position: -215px -305px;
	}

	.my {
		width: 20px;
		height: 20px;
		background-position: -245px -305px;
	}

	.mz {
		width: 20px;
		height: 20px;
		background-position: -275px -305px;
	}

	.na {
		width: 20px;
		height: 20px;
		background-position: -305px -305px;
	}

	.nc {
		width: 20px;
		height: 20px;
		background-position: -335px -305px;
	}

	.ne {
		width: 20px;
		height: 20px;
		background-position: -365px -305px;
	}

	.nf {
		width: 20px;
		height: 20px;
		background-position: -395px -305px;
	}

	.ng {
		width: 20px;
		height: 20px;
		background-position: -425px -305px;
	}

	.ni {
		width: 20px;
		height: 20px;
		background-position: -5px -335px;
	}

	.nl {
		width: 20px;
		height: 20px;
		background-position: -35px -335px;
	}

	.no {
		width: 20px;
		height: 20px;
		background-position: -65px -335px;
	}

	.np {
		width: 20px;
		height: 20px;
		background-position: -95px -335px;
	}

	.nr {
		width: 20px;
		height: 20px;
		background-position: -125px -335px;
	}

	.nu {
		width: 20px;
		height: 20px;
		background-position: -155px -335px;
	}

	.nz {
		width: 20px;
		height: 20px;
		background-position: -185px -335px;
	}

	.om {
		width: 20px;
		height: 20px;
		background-position: -215px -335px;
	}

	.pa {
		width: 20px;
		height: 20px;
		background-position: -245px -335px;
	}

	.pe {
		width: 20px;
		height: 20px;
		background-position: -275px -335px;
	}

	.pf {
		width: 20px;
		height: 20px;
		background-position: -305px -335px;
	}

	.pg {
		width: 20px;
		height: 20px;
		background-position: -335px -335px;
	}

	.ph {
		width: 20px;
		height: 20px;
		background-position: -365px -335px;
	}

	.pk {
		width: 20px;
		height: 20px;
		background-position: -395px -335px;
	}

	.pl {
		width: 20px;
		height: 20px;
		background-position: -425px -335px;
	}

	.pm {
		width: 20px;
		height: 20px;
		background-position: -5px -365px;
	}

	.pn {
		width: 20px;
		height: 20px;
		background-position: -35px -365px;
	}

	.pr {
		width: 20px;
		height: 20px;
		background-position: -65px -365px;
	}

	.ps {
		width: 20px;
		height: 20px;
		background-position: -95px -365px;
	}

	.pt {
		width: 20px;
		height: 20px;
		background-position: -125px -365px;
	}

	.pw {
		width: 20px;
		height: 20px;
		background-position: -155px -365px;
	}

	.py {
		width: 20px;
		height: 20px;
		background-position: -185px -365px;
	}

	.qa {
		width: 20px;
		height: 20px;
		background-position: -215px -365px;
	}

	.re {
		width: 20px;
		height: 20px;
		background-position: -245px -365px;
	}

	.ro {
		width: 20px;
		height: 20px;
		background-position: -275px -365px;
	}

	.rs {
		width: 20px;
		height: 20px;
		background-position: -305px -365px;
	}

	.ru {
		width: 20px;
		height: 20px;
		background-position: -335px -365px;
	}

	.rw {
		width: 20px;
		height: 20px;
		background-position: -365px -365px;
	}

	.sa {
		width: 20px;
		height: 20px;
		background-position: -395px -365px;
	}

	.sb {
		width: 20px;
		height: 20px;
		background-position: -425px -365px;
	}

	.sc {
		width: 20px;
		height: 20px;
		background-position: -5px -395px;
	}

	.sd {
		width: 20px;
		height: 20px;
		background-position: -35px -395px;
	}

	.se {
		width: 20px;
		height: 20px;
		background-position: -65px -395px;
	}

	.sg {
		width: 20px;
		height: 20px;
		background-position: -95px -395px;
	}

	.sh {
		width: 20px;
		height: 20px;
		background-position: -125px -395px;
	}

	.si {
		width: 20px;
		height: 20px;
		background-position: -155px -395px;
	}

	.sj {
		width: 20px;
		height: 20px;
		background-position: -185px -395px;
	}

	.sk {
		width: 20px;
		height: 20px;
		background-position: -215px -395px;
	}

	.sl {
		width: 20px;
		height: 20px;
		background-position: -245px -395px;
	}

	.sm {
		width: 20px;
		height: 20px;
		background-position: -275px -395px;
	}

	.sn {
		width: 20px;
		height: 20px;
		background-position: -305px -395px;
	}

	.so {
		width: 20px;
		height: 20px;
		background-position: -335px -395px;
	}

	.sr {
		width: 20px;
		height: 20px;
		background-position: -365px -395px;
	}

	.ss {
		width: 20px;
		height: 20px;
		background-position: -395px -395px;
	}

	.st {
		width: 20px;
		height: 20px;
		background-position: -425px -395px;
	}

	.sv {
		width: 20px;
		height: 20px;
		background-position: -5px -425px;
	}

	.sx {
		width: 20px;
		height: 20px;
		background-position: -35px -425px;
	}

	.sy {
		width: 20px;
		height: 20px;
		background-position: -65px -425px;
	}

	.sz {
		width: 20px;
		height: 20px;
		background-position: -95px -425px;
	}

	.tc {
		width: 20px;
		height: 20px;
		background-position: -125px -425px;
	}

	.td {
		width: 20px;
		height: 20px;
		background-position: -155px -425px;
	}

	.tf {
		width: 20px;
		height: 20px;
		background-position: -185px -425px;
	}

	.tg {
		width: 20px;
		height: 20px;
		background-position: -215px -425px;
	}

	.th {
		width: 20px;
		height: 20px;
		background-position: -245px -425px;
	}

	.tj {
		width: 20px;
		height: 20px;
		background-position: -275px -425px;
	}

	.tk {
		width: 20px;
		height: 20px;
		background-position: -305px -425px;
	}

	.tl {
		width: 20px;
		height: 20px;
		background-position: -335px -425px;
	}

	.tm {
		width: 20px;
		height: 20px;
		background-position: -365px -425px;
	}

	.tn {
		width: 20px;
		height: 20px;
		background-position: -395px -425px;
	}

	.to {
		width: 20px;
		height: 20px;
		background-position: -425px -425px;
	}

	.tr {
		width: 20px;
		height: 20px;
		background-position: -455px -5px;
	}

	.tt {
		width: 20px;
		height: 20px;
		background-position: -455px -35px;
	}

	.tv {
		width: 20px;
		height: 20px;
		background-position: -455px -65px;
	}

	.tw {
		width: 20px;
		height: 20px;
		background-position: -455px -95px;
	}

	.tz {
		width: 20px;
		height: 20px;
		background-position: -455px -125px;
	}

	.ua {
		width: 20px;
		height: 20px;
		background-position: -455px -155px;
	}

	.ug {
		width: 20px;
		height: 20px;
		background-position: -455px -185px;
	}

	.um {
		width: 20px;
		height: 20px;
		background-position: -455px -215px;
	}

	.us {
		width: 20px;
		height: 20px;
		background-position: -455px -245px;
	}

	.uy {
		width: 20px;
		height: 20px;
		background-position: -455px -275px;
	}

	.uz {
		width: 20px;
		height: 20px;
		background-position: -455px -305px;
	}

	.va {
		width: 20px;
		height: 20px;
		background-position: -455px -335px;
	}

	.vc {
		width: 20px;
		height: 20px;
		background-position: -455px -365px;
	}

	.ve {
		width: 20px;
		height: 20px;
		background-position: -455px -395px;
	}

	.vg {
		width: 20px;
		height: 20px;
		background-position: -455px -425px;
	}

	.vi {
		width: 20px;
		height: 20px;
		background-position: -5px -455px;
	}

	.vn {
		width: 20px;
		height: 20px;
		background-position: -35px -455px;
	}

	.vu {
		width: 20px;
		height: 20px;
		background-position: -65px -455px;
	}

	.wf {
		width: 20px;
		height: 20px;
		background-position: -95px -455px;
	}

	.ws {
		width: 20px;
		height: 20px;
		background-position: -125px -455px;
	}

	.xk {
		width: 20px;
		height: 20px;
		background-position: -155px -455px;
	}

	.ye {
		width: 20px;
		height: 20px;
		background-position: -185px -455px;
	}

	.yt {
		width: 20px;
		height: 20px;
		background-position: -215px -455px;
	}

	.za {
		width: 20px;
		height: 20px;
		background-position: -245px -455px;
	}

	.zm {
		width: 20px;
		height: 20px;
		background-position: -275px -455px;
	}

	.zw {
		width: 20px;
		height: 20px;
		background-position: -305px -455px;
	}

	.phone-dropdown {
		padding: 0 6px 10px 10px;
		margin-left: -4px;

		@media only screen and (min-width: 576px) {
			width: 366px;
			max-width: 366px;
			max-height: 278px;
			top: 100%;
			left: 0;
		}

		&::-webkit-scrollbar {
			width: 4px;
		}

		&::-webkit-scrollbar-track {
			border-radius: 2px;
			margin: 16px 3px 5px 3px;
			background: var(--Border-Line);
		}

		&::-webkit-scrollbar-thumb {
			border-radius: 2px;
			background: var(--text-additional);
		}

		.search-emoji:after {
			top: 12px;
			left: 16px;
			height: 20px;
			width: 20px;
		}
	}
}

.searchfield input {

}

.searchfield input {
	border-left: 1px solid var(--border-main-color);
	display: block;
	border: 1px solid var(--ui-input-border-color) !important;
	outline: none;
	width: 100%;
	height: 44px;
	padding: 0 16px 0 46px !important;
	margin: 0;
	font-size: 14px;
	line-height: 180%;
	font-weight: 400;
	border-radius: 10px !important;
	transition: all 0.15s ease;
}


