.currencyButtonFile {
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;

	@media only screen and (max-width: 767.98px) {
		flex-direction: column;
		gap: 12px;
		align-items: flex-start;
	}

	.fileButton {
		display: flex;
		gap: 10px;

		button {
			margin: 0;
		}
	}
}

.currencyButton {
	border-radius: 12px;
	border: 1px solid var(--gray100);
	background: var(--additional-white);
	padding: 4px;
	display: inline-flex;

	&.currencyButton--full {
		display: grid;
		width: 100%;
		grid-template-columns: (1fr 1fr);

		button, a {
			width: 100%;
		}
	}

	@media only screen and (max-width: 575.98px) {
		width: 100%;
	}

	button, a {
		width: 180px;
		height: 42px;
		border-radius: 8px;
		background: transparent;
		display: flex;
		align-items: center;
		justify-content: center;
		color: var(--greyscale-grey-500);
		font-size: 18px;
		font-style: normal;
		font-weight: 400;
		line-height: normal;
		transition: var(--transition-base);

		@media only screen and (max-width: 575.98px) {
			width: 50%;
		}

		&.active {
			color: var(--primary-blue-400);
			font-weight: 600;
			border-radius: 8px;
			background: var(--primary-blue-100);
			box-shadow: 0 2px 8px 0 rgba(33, 44, 99, 0.10);
		}
	}
}
