.table {
	border: none;
	font-size: 12px;
	font-weight: 400;
	line-height: 160%;

	.table__input-wrap {
		display: grid;
		grid-template-columns: 1fr 1fr;
		gap: 8px;

		.table__input {
			max-width: 100%;
		}
	}

	.table__input {
		width: 100%;
		max-width: 140px;
		height: 24px;
		border-radius: 6px;
		border: 1px solid var(--greyscale-grey-100);
		background: var(--additional-white);
		padding: 0 8px;
		color: var(--greyscale-grey-800);
		font-size: 14px;
		font-style: normal;
		font-weight: 400;
		line-height: 160%; /* 22.4px */
		transition: border-color var(--transition-base);

		&::placeholder {
			color: var(--greyscale-grey-200);
			font-size: 14px;
			font-style: normal;
			font-weight: 400;
			line-height: 160%; /* 22.4px */
		}

		&:focus {
			border-color: var(--primary-blue-200);
		}
	}

	.table__input-error {
		color: red;
		font-size: 12px;
		font-weight: 300;
		margin-top: 2px;
	}

	.td-sorting-arrow-button {
		display: flex;
		align-items: center;
		justify-content: space-between;
		color: var(--greyscale-grey-600);
		font-size: 14px;
		font-style: normal;
		font-weight: 400;
		line-height: normal;
		width: 100%;
	}

	.tr {
		&.is-edit {
			border-color: var(--greyscale-grey-50);
			background: var(--primary-blue-50);
		}
	}

	.td {
		&:first-child {
			padding-left: 10px;
		}

		&:last-child {
			padding-right: 10px;
		}

		&--center {
			text-align: center;

			* {
				justify-content: center;
			}
		}

		&__wrap {
			padding: 0 8px;
			color: var(--greyscale-grey-800);
			font-size: 14px;
			font-style: normal;
			font-weight: 400;
			line-height: 160%; /* 22.4px */
			display: flex;
			align-items: center;
			gap: 8px;

			@media only screen and (max-width: 1199px) {
				padding: 0;
			}

			&--column {
				flex-direction: column;
				align-items: flex-start;
			}

			&--column-no-gap {
				gap: 0;
			}

			&--total {
				color: var(--greyscale-grey-500);
				font-size: 14px;
				font-style: normal;
				font-weight: 400;
				line-height: 160%; /* 22.4px */

				span {
					color: var(--greyscale-grey-800);
					font-weight: 500;
				}
			}

			img {
				width: 24px;
				min-width: 24px;
				height: 24px;
				min-height: 24px;
			}

			a {
				color: var(--blue400);
				font-size: 14px;
				font-style: normal;
				font-weight: 400;
				line-height: 160%; /* 22.4px */
				text-decoration: underline;

				&:hover {
					text-decoration: none;
				}
			}
		}

		&__text {
			color: var(--greyscale-grey-800);
			font-size: 14px;
			font-style: normal;
			font-weight: 400;
			line-height: 160%; /* 22.4px */
		}

		&__more {
			color: var(--text-secondary);
			font-size: 12px;
			font-style: normal;
			font-weight: 400;
			line-height: normal;
			margin-top: 4px;
		}

		&__coin-pair {
			padding: 0 8px;
			display: flex;
			align-items: center;
			gap: 8px;
		}

		&__coin-pair-item {
			color: var(--greyscale-grey-800);
			font-size: 14px;
			font-style: normal;
			font-weight: 400;
			line-height: 160%; /* 22.4px */
			display: flex;
			align-items: center;
			gap: 8px;
			min-width: 70px;

			img {
				width: 24px;
				min-width: 24px;
				height: 24px;
				min-height: 24px;
			}
		}

		&__coin-pair-divider {
			color: var(--greyscale-grey-200);
			font-size: 16px;
			font-style: normal;
			font-weight: 500;
			line-height: normal;
		}
		&--currency {
			display: flex;
			gap: 8px;
			align-items: center;
			img {
				width: 24px;
				min-width: 24px;
				height: 24px;
				min-height: 24px;
			}
		}
	}

	.table-body {
		.tr:not(:last-child) {
			border-bottom: 1px solid var(--greyscale-grey-50);
		}

		.td {
			padding: 10px;
		}

		.input-item {
			padding: 0 8px;
		}
	}

	.table-buttons {
		display: flex;
		gap: 10px;

		button {
			flex: inherit;
			max-width: inherit;

			+ button {
				margin-left: 0;
			}
		}
	}

	&--type2 {

		.tr {
			.td:first-child {
				padding-left: 10px;
			}

			.td:last-child {
				padding-right: 10px;
			}
		}
	}

	&--type2.table--fiat-balance--custom {

		.tr {
			grid-template-columns: 1fr 1fr 1fr;

			.th {
				padding: 10px 4px 14px 4px;
				font-size: 14px;
				font-weight: 600;

				p {
					display: flex;
					align-items: center;
					justify-content: space-between;
					line-height: normal;
					background: var(--gray300);
					padding: 6px 8px;
					border-radius: 6px;
					width: 100%;
					color: var(--gray600);
					font-size: 14px;
					font-weight: 400;
				}
			}

			.th:first-child {
				padding-left: 10px;
			}

			.th:last-child {
				padding-right: 10px;
			}

			.td {
				padding: 18px 4px;

				&:first-child {
					padding-left: 10px;
				}

				&:last-child {
					padding-right: 10px;
				}

				> p {

					@media only screen and (min-width: 1200px) {
						padding-left: 8px;
						padding-right: 8px;
					}
				}

				> div.flex {

					@media only screen and (min-width: 1200px) {
						padding-left: 8px;
						padding-right: 8px;
					}
				}

				&.total {
					p {
						text-align: right;
						font-size: 16px;
						font-weight: 400;
						line-height: 20px;
						letter-spacing: 0;
						display: flex;
						flex-direction: column;
						justify-content: center;
						color: rgba(20, 20, 20, 1);
					}
					span {
						font-size: 14px;
						font-weight: 400;
						line-height: 18px;
						letter-spacing: 0;
						margin-top: 5px;
						color:rgba(99, 99, 99, 1);
					}
				}
			}
		}
	}

	&--type2.table--crypto-balance--custom {

		.tr {
			grid-template-columns: 1fr 1fr 1fr 1fr 264px;

			.th {
				padding: 10px 4px 14px 4px;
				font-size: 14px;
				font-weight: 600;

				p {
					display: flex;
					align-items: center;
					justify-content: space-between;
					line-height: normal;
					background: var(--gray300);
					padding: 6px 8px;
					border-radius: 6px;
					width: 100%;
					color: var(--gray600);
					font-size: 14px;
					font-weight: 400;
				}
			}

			.th:first-child {
				padding-left: 10px;
			}

			.th:last-child {
				padding-right: 10px;
			}

			.td {
				padding: 18px 4px;

				&:first-child {
					padding-left: 10px;
				}

				&:last-child {
					padding-right: 10px;
				}

				> p {

					@media only screen and (min-width: 1200px) {
						padding-left: 8px;
						padding-right: 8px;
					}
				}

				> div.crypto-flex {

					@media only screen and (min-width: 1200px) {
						padding-left: 8px;
						padding-right: 8px;
					}
				}

				&.total {
					p {
						text-align: right;
						font-size: 16px;
						font-weight: 400;
						line-height: 20px;
						letter-spacing: 0;
						display: flex;
						flex-direction: column;
						justify-content: center;
						color: rgba(20, 20, 20, 1);
					}
					span {
						font-size: 14px;
						font-weight: 400;
						line-height: 18px;
						letter-spacing: 0;
						margin-top: 5px;
						color:rgba(99, 99, 99, 1);
					}
				}
			}
		}
	}

	&--account-notes {

		.table-header,
		.table-body {

			.tr {

				@media only screen and (min-width: 768px) {
					display: grid;
					grid-template-columns: minmax(120px, 134px) 1fr minmax(114px, 114px);
				}
			}

			.td {
				padding: 10px 4px;

				&:first-child {
					padding-left: 10px;
					@media only screen and (max-width: 767.98px) {
						padding-left: 4px;
					}
				}

				&:last-child {
					padding-right: 10px;
					@media only screen and (max-width: 767.98px) {
						padding-right: 4px;
					}
				}
			}
		}

		.table-header {
		}

		.table-body {

			.td {

				&__wrap {

					img {
						width: auto;
						min-width: auto;
						height: auto;
						min-height: auto;
					}
				}

				&__wrap--column {
					gap: 4px;
				}
			}
		}
	}

	&--payment-deposit-accounts {
		.tr {
			grid-template-columns: 2fr 1.7fr 1.3fr 1.4fr 1.4fr 1.8fr 0.9fr;
			gap: 3px;
			padding: 10px;
		}

		.td {
			padding: 0;

			&:first-child,
			&:last-child {
				padding-left: 0;
			}
		}

		.table-body {
			color: var(--gray800);

			.tr {
				border-bottom: 1px solid var(--gray100);
				align-items: flex-start;

				&:last-child,
				&:only-child {
					border: none;
				}

				.td {
					padding: 0 0 0 8px;
				}

				.td-title-name {
					font-weight: 500;
					font-size: 14px;
					margin-bottom: 4px;
				}

				.td-subtitle-name {
					font-weight: 400;
					color: var(--gray600);
					font-size: 12px;
				}

				&.active {
					background: var(--primary-blue-50);
					justify-content: flex-start;

					.td {
						display: flex;
						flex-direction: column;
						gap: 4px;
						padding: 0 8px 0 0;
					}

					.table-buttons {
						justify-content: flex-start;
						padding: 0 0 0 8px;
					}
				}
			}

			.input {
				font-family: var(--font-sans);

				.input-item {
					width: 100%;
					border-radius: 6px;
					border: 1px solid var(--greyscale-grey-50);

					&:focus {
						border: 1px solid var(--primary-blue-200);
					}
				}
			}
		}

		.table-pagination {
			border-top: 1px solid var(--greyscale-grey-50);
			padding: 0px 20px;
			margin-top: 0;

			@media only screen and (max-width: 767.98px) {
				flex-direction: column;
				gap: 16px;
			}

			.pagination-block {
				margin: 20px 0px;
			}

			.select-page {
				.select--per-page-wrapper {
					display: flex;
					color: var(--blue600);
					align-items: center;
					gap: 16px;
					font-size: 14px;
					font-style: normal;
					font-weight: 400;
					line-height: normal;

					.select {
						width: 80px;
						height: 32px;
						border-radius: 6px;
						border: 1px solid var(--greyscale-grey-100);
						background: var(--greyscale-grey-10);
						&.active {
							border: 1px solid var(--primary-blue-200);
							background: var(--additional-white);
						}

						.select__drop {
							display: block !important;
							padding: 2px;

							.select__drop-scroll {
								max-height: 165px;
							}

							ul > li > button {
								padding: 5px 10px;
								border-radius: 4px;
								color: var(--greyscale-grey-500);
								font-size: 14px;
								font-style: normal;
								font-weight: 400;
								line-height: 160%;
								background: transparent;

								&:hover {
									background: #f1f7fc;
									background: var(--primary-blue-50);
									color: var(--greyscale-grey-800);
									font-weight: 500;
								}
							}
						}
					}

					.select__current {
						padding: 0 10px;
						color: var(--greyscale-grey-600);
						font-size: 14px;
						font-style: normal;
						font-weight: 500;
						line-height: normal;
					}

					.select__current-arrow {
						margin-left: 0;
					}

					.select__drop {
						top: unset;
						bottom: calc(100% + 5px);
					}
				}
			}
		}
	}

	&--transaction-crypto-transfer,
	&--ledger,
	&--transaction-fiat,
	&--transaction-crypto,
	&--transactions-trades,
	&--transactions-transfer-limits,
	&--transaction-fiat-transfer,
	&--transaction-fiat-withdrawal,
	&--transaction-crypto-withdrawal,
	&--transactions-pooled-deposit,
	&--transactions-profit-wallet,
	&--transaction-crypto-withdrawal,
	&--transactions-transfer-limits,
	&--transactions-trading-limits,
	&--transactions-pending-withdrawal,
	&--transactions-pending-withdrawal-fiat,
	&--transfer-history-crypto,
	&--transaction-history-fiat,
	&--transaction-history-trade,
	&--deposit-history-crypto,
	&--deposit-history-fiat,
	&--transactions-pending-deposit-fiat,
	&--transactions-pending-deposit,
	&--messages,
	&--manage-users {
		@media screen and (max-width: 1199px) {
			min-width: auto !important;
		}

		.table-header {
			.tr {
				padding: 10px;
				gap: 3px;
				position: relative;
				.select {
					height: 100%;
				}

				.td {
					padding: 0;
					// padding-left: 15px;

					p,
					button {
						min-height: 30px;
						padding: 0 8px;
						justify-content: space-between;
					}

					&--center {
						p {
							justify-content: center;
						}
					}
				}
			}

			.td-name,
			button {
				background: var(--gray300);
				padding: 0;
				border-radius: 6px;
				width: 100%;
				color: var(--gray600);
				font-size: 14px;
				font-weight: 400;
			}
		}

		.table-body {
			border-bottom: none;
			.tr {
				padding: 10px;
				height: fit-content;

				&--editable {
					background: var(--primary-blue-50);
					.select {
						border: 1px solid var(--greyscale-grey-50);
						background: var(--additional-white);
						height: 30px;
					}
					.input-item {
						border: 1px solid var(--greyscale-grey-50);
						&:focus {
							border: 1px solid var(--primary-blue-200);
						}
					}
				}

				@media screen and (max-width: 1199px) {
					.tooltip-note {
						transform: translate(15%, -120%);
					}

					.tooltip-note i {
						top: 100%;
						left: 10%;

						&::after {
							transform: translate(-50%, -50%) rotate(45deg);
						}
					}
				}

				.tooltip--bottom {
					transform: translate(-50%, -30%);
					max-height: 300px;
					overflow-y: auto;
					overflow-x: hidden;
				}

				&:last-child,
				&:nth-last-child(2) {
					.tooltip--bottom {
						transform: translate(-50%, -100%);
					}

					.tooltip-note {
						top: unset;
						bottom: calc(100% + 3px);

						i {
							top: 100%;
							&::after {
								transform: translate(-50%, -50%) rotate(45deg);
							}
						}
					}
				}
				&:last-child:not(:first-child) {
					.select__drop {
						//transform: translate(-50%, -155%);
						top: auto;
						bottom: calc(100% + 5px);
					}
				}
				&:nth-last-child(3) {
					.tooltip--bottom {
						transform: translate(-50%, -80%);
					}
				}

				&:nth-last-child(3),
				&:nth-last-child(4),
				&:nth-last-child(5) {
					.tooltip-note {
						top: unset;
						bottom: calc(100% + 3px);

						i {
							top: 100%;

							&::after {
								transform: translate(-50%, -50%) rotate(45deg);
							}
						}
					}
				}

				.td {
					padding: 0 8px;

					.td-name--flex {
						font-size: 14px;
						font-style: normal;
						font-weight: 500;
					}

					a {
						padding-left: 0;
						color: var(--blue400);
						font-size: 14px;
						font-style: normal;
						font-weight: 500;
						line-height: 160%;
						text-decoration: none;
						&:hover {
							color: var(--text-color-main);
						}
					}

					&--width-150 {
						max-width: 150px;
					}

					.select {
						padding: 0;

						@media screen and (max-width: 1199px) {
							width: 200px;
						}

						&.active {
							border: 0;

							.select__current {
								border-color: var(--primary-blue-200);
							}
						}
					}

					.select__drop {
						min-width: 110px;
						padding: 2px;
						border-radius: 6px;

						@media screen and (max-width: 1199px) {
							width: 200px;
						}

						ul > li > button {
							padding: 5px 10px;
							color: var(--greyscale-grey-500);
							font-size: 14px;
							font-style: normal;
							font-weight: 400 !important;
							line-height: 160%;
							min-height: 32px;
							display: flex;
							align-items: center;
							flex-direction: row;
							gap: 8px;
							border-radius: 4px;

							&:hover {
								background: var(--primary-blue-50);
								color: var(--greyscale-grey-800);
								font-weight: 500 !important;
							}

							img {
								width: 18px;
								min-width: 18px;
								height: 18px;
								min-height: 18px;
							}
						}
					}

					.select__current {
						justify-content: space-between;
						color: var(--greyscale-grey-800);
						font-size: 12px;
						font-style: normal;
						font-weight: 500 !important;
						line-height: normal;
						padding: 4px 8px;
						border-radius: 6px;
						border: 1px solid var(--additional-white);
						background: var(--additional-white);
					}

					.select__current-arrow {
						background-size: 16px;
						width: 16px;
						min-width: 16px;
						height: 16px;
						min-height: 16px;
						flex: 0 0 16px;
					}

					&--right {
						@media screen and (max-width: 1199px) {
							display: flex;
							flex-direction: column;
						}
					}

					&--center {
						p {
							text-align: center;
						}
					}

					.td-bold-text {
						font-weight: 500;
					}
				}
				.copy-button {
					justify-content: center;
				}
			}

			input {
				color: var(--greyscale-grey-800);
				max-height: 30px;
				border-radius: 6px;

				&:focus {
					border: 1px solid var(--primary-blue-200, #a8dbf9);
				}

				.select__drop-item {
					background-color: var(--additional-white);

					ul li {
						&:hover {
							color: var(--greyscale-grey-800);
							background: var(--primary-blue-50);
							font-weight: 500 !important;
						}
					}

					button {
						background-color: var(--additional-white);

						&:hover {
							background: var(--primary-blue-50);
						}
					}
				}
			}
		}

		.table-body--one-elements {
			.tr {
				.tooltip-note {
					max-height: 100px;
					overflow-y: auto;
					overflow-x: hidden;
					transform: translate(-95%, -60%);
					bottom: auto !important;
				}

				.tooltip--bottom {
					transform: translate(-50%, -35%) !important;
				}
			}
		}

		.table-body--two-elements {
			.tr {
				.tooltip-note {
					max-height: 100px;
					overflow-y: auto;
					overflow-x: hidden;
					bottom: auto !important;
				}

				.tooltip--bottom {
					max-height: 290px;
					transform: translate(-50%, -35%) !important;
				}

				&:last-child {
					.tooltip--bottom {
						max-height: 290px;
						transform: translate(-50%, 53%) !important;
					}
				}
			}
		}

		.table-body--three-elements {
			.tr {
				.tooltip-note {
					max-height: 100px;
					overflow-y: auto;
					overflow-x: hidden;
					bottom: auto !important;
					transform: translate(-95%, -60%);
				}

				.tooltip--bottom {
					max-height: 290px;
				}

				&:first-child {
					.tooltip--bottom {
						transform: translate(-50%, -35%);
					}
				}

				&:last-child {
					.tooltip--bottom {
						transform: translate(-50%, -80%);
					}
				}

				&:nth-last-child(2) {
					.tooltip--bottom {
						transform: translate(-50%, -58%);
					}
				}
			}
		}

		.select {
			&--table {
				padding: 0 2px;

				.btn {
					background: var(--blue600);
					color: #fff;
					justify-content: center !important;
					font-weight: 600 !important;
				}
			}

			.select__drop {
				left: 50%;
				transform: translateX(-50%);
				min-width: fit-content;
				border-radius: 8px;
				overflow: hidden;

				&--date-picker {
					left: 0;
					transform: translateX(0%);

					button {
						background: none;
						top: -6px;
						padding: 6px;
						border-radius: 6px;
						background: var(--primary-blue-400);
						width: 28px;
						min-height: 28px !important;
					}
				}

				input {
					width: 140px;
					border-radius: 10px;
					font-size: 14px;
					font-weight: 500;
				}

				&--range {
					padding: 24px;
				}

				&--status {
					min-width: 120px;
				}

				.select__drop-item {
					background-color: var(--additional-white);
					ul li {
						:hover {
							color: var(--greyscale-grey-800);
							background: var(--primary-blue-50);
							font-weight: 500 !important;
						}
					}
					button {
						background-color: var(--additional-white);
						:hover {
							background: var(--primary-blue-50);
						}
					}
				}
			}
		}
	}

	&--transaction-fiat {

		@media only screen and (min-width: 1199px) {
			min-width: 1280px;
		}

		.table-header .tr {
			grid-template-columns: 1fr 0.7fr 1fr 1fr 1fr 1.4fr 1fr 1fr 1.6fr 1fr 1fr 0.9fr;
			gap: 0;

			.td {
				padding: 0 1.5px;
			}
		}

		.table-body .tr {
			height: fit-content;
			grid-template-columns: 1fr 0.7fr 1fr 1fr 1fr 1.4fr 1fr 1fr 1.6fr 1fr 1fr 0.9fr;
			gap: 0;
			grid-template-areas: 'col1 col2 col12 col3 col4 col5 col6 col7 col8 col10 col11';

			&--editable {
				grid-template-columns: 1fr 0.7fr 1fr 1.4fr 1fr 1fr 1.6fr 1fr 1fr 0.9fr;
				.select__current {
					justify-content: space-between;
				}
			}

			.td {
				padding: 0 8px;

				&--editable {
					grid-area: col10;
					grid-row: 2;
				}
			}
		}
	}

	&--transaction-fiat-withdrawal {
		@media only screen and (min-width: 1199px) {
			min-width: 1380px;
		}

		.table-header .tr {
			grid-template-columns: 1fr 0.7fr 1fr 1fr 1fr 1.4fr 1fr 1fr 1.6fr 1fr 1fr 0.9fr;
			gap: 0;

			.td {
				padding: 0 1.5px;
			}
		}

		.table-body .tr {
			height: fit-content;
			grid-template-columns: 1fr 0.7fr 1fr 1fr 1fr 1.4fr 1fr 1fr 1.6fr 1fr 1fr 0.9fr;
			gap: 0;
			grid-template-areas: 'col1 col2 col12 col3 col4 col5 col6 col7 col8 col10 col11';

			&--editable {
				grid-template-columns: 1fr 0.7fr 1fr 1fr 1.4fr 1fr 1fr 1.6fr 1fr 1.9fr;

				.select__current {
					justify-content: space-between;
				}
			}

			.td {
				padding: 0 8px;

				&--editable {
					grid-area: col10;
					grid-row: 2;
				}
			}
		}
	}

	&--transaction-crypto {

		@media only screen and (min-width: 1199px) {
			min-width: 1380px;
		}

		.table-header,
		.table-body {

			.tr {
				grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1.4fr 1fr 1fr 1.2fr 1.1fr 0.7fr 1fr 0.8fr;
				gap: 3px;
			}
		}
	}

	&--transactions-trades {

		@media only screen and (min-width: 1199px) {
			min-width: 1280px;
		}

		.table-header,
		.table-body {
			.tr {
				grid-template-columns: 1.1fr 0.7fr 1fr 0.8fr 1fr 1fr 1.3fr 1.4fr 1.2fr 1fr 0.6fr;
				gap: 3px;
			}
		}
	}

	&--transaction-crypto-transfer {

		@media only screen and (min-width: 1199px) {
			min-width: 1480px;
		}

		.table-header .tr {
			grid-template-columns: 1.1fr 0.8fr 1fr 0.8fr 1.1fr 1fr 1.3fr 1fr 1fr 1.2fr 1.2fr 0.7fr 1.2fr 1fr;
			gap: 3px;
		}

		.table-body .tr {
			grid-template-columns: 1.1fr 0.8fr 1fr 0.8fr 1.1fr 1fr 1.3fr 1fr 1fr 1.2fr 1.2fr 0.7fr 1.2fr 1fr;
			gap: 3px;
			grid-template-areas: 'col1 col2 col13 col3 col4 col5 col6 col7 col8 col9 col10 col11 col12';
			&--editable {
				grid-template-columns: 1.1fr 0.8fr 1.1fr 1fr 1.3fr 1fr 1fr 1.2fr 1.2fr 0.7fr 1.2fr 1fr;
				.select__current {
					justify-content: space-between;
				}
			}
			.td {
				padding: 0 8px;
				&--editable {
					//grid-area: col10;
					//grid-row: 2;
				}
				a {
					margin-left: 10px;
				}
			}
		}
	}

	&--transaction-fiat-transfer {

		@media only screen and (min-width: 1199px) {
			min-width: 1380px;
		}

		.table-header .tr {
			grid-template-columns: 1.1fr 1fr 1.1fr 1fr 1fr 1.2fr 1.5fr 1fr 1fr 1.3fr 1fr 1.1fr 0.8fr;
			gap: 3px;
		}

		.table-body .tr {
			grid-template-columns: 1.1fr 1fr 1.1fr 1fr 1fr 1.2fr 1.5fr 1fr 1fr 1.3fr 1fr 1.1fr 0.8fr;
			gap: 3px;
			grid-template-areas: 'col1 col2 col12 col3 col4 col5 col6 col7 col8 col9 col10 col11';

			&--editable {
				grid-template-columns: 1.1fr 1fr 1fr 1.2fr 1.5fr 1.5fr 1fr 1fr 1fr 1.9fr;

				.select__current {
					justify-content: space-between;
				}
			}

			.td {
				padding: 0 8px;

				&--editable {
					grid-area: col10;
					grid-row: 2;
				}
			}
		}
	}

	&--transactions-pooled-deposit {

		.table-header .tr {
			grid-template-columns: 1.3fr 1fr 1fr 1.3fr 1fr 1fr 1fr 1fr 1.1fr;
			gap: 3px;
		}

		.table-body .tr {
			grid-template-columns: 1.3fr 1fr 1fr 1.3fr 1fr 1fr 1fr 1fr 1.1fr;
		}
	}

	&--transaction-crypto-withdrawal {
		@media only screen and (min-width: 1199px) {
			min-width: 1480px;
		}

		.table-header .tr {
			grid-template-columns: 1.5fr 1fr 1.2fr 1.2fr 1.5fr 1.6fr 1.5fr 1.5fr 1.5fr 1.5fr 1fr 1.5fr 1.3fr;
			gap: 3px;
		}

		.table-body .tr {
			grid-template-columns: 1.5fr 1fr 1.2fr 1.2fr 1.5fr 1.6fr 1.5fr 1.5fr 1.5fr 1.5fr 1fr 1.5fr 1.3fr;
			grid-template-areas: 'col1 col2 col13 col3 col4 col5 col6 col7 col8 col9 col11 col12';

			&--editable {
				grid-template-columns: 1.5fr 1fr 1.2fr 1.2fr 1.5fr 1.6fr 1.5fr 1.5fr 1.5fr 1.5fr 1fr 1.5fr 1.3fr;

				.select__current {
					justify-content: space-between;
				}
			}

			.td {
				padding: 0 8px;

				&--editable {
					//grid-area: col10;
					//grid-row: 2;
				}
			}
		}
	}

	&--transactions-profit-wallet .table-header .tr {
		grid-template-columns: 1.3fr 1fr 1.3fr 1fr 1fr 1fr 1fr 1.1fr;
	}
	&--transactions-profit-wallet .table-body .tr {
		grid-template-columns: 1.3fr 1fr 1.3fr 1fr 1fr 1fr 1fr 1.1fr;
	}

	&--transactions-pending-deposit {
		background: #fff;

		@media only screen and (min-width: 1199px) {
			min-width: 1280px;
		}

		.table-header {
			.tr {
				grid-template-columns: 0.9fr 0.8fr 1fr 0.8fr 0.9fr 0.8fr 0.8fr 0.8fr 1fr 1.2fr 0.8fr 0.9fr 0.7fr;
			}
		}

		.table-body .tr {
			grid-template-columns: 0.9fr 0.8fr 1fr 0.8fr 0.9fr 0.8fr 0.8fr 0.8fr 1fr 1.2fr 0.8fr 0.9fr 0.7fr;
			.copy-button {
				// padding: 8px;
			}
		}

		.table-footer {
			border: 0;

			.select__drop {
				border-radius: 6px;
				overflow: hidden;
				min-width: 120px;
				left: 0;
				transform: translateX(0);
			}
		}
	}

	&--transactions-pending-withdrawal {
		background: #fff;

		@media only screen and (min-width: 1199px) {
			min-width: 1380px;
		}

		.table-header,
		.table-body {
			.tr {
				grid-template-columns: 1.2fr 0.8fr 1.2fr 1fr 1fr 1.2fr 1.2fr 1.2fr 1.7fr 1.4fr 1.1fr 1fr 1.3fr 1.1fr;
			}
		}

		.table-footer {
			border: 0;

			.select__drop {
				border-radius: 6px;
				overflow: hidden;
				min-width: 120px;
				left: 0;
				transform: translateX(0);
			}
		}
	}

	&--transactions-pending-deposit-fiat {

		/*@media only screen and (min-width: 1199px) {
			min-width: 1280px;
		}*/

		.table-header .tr {
			grid-template-columns: 0.8fr 0.6fr 0.8fr 0.7fr 0.7fr 0.7fr 0.7fr 0.7fr 1.3fr 0.6fr 0.8fr 0.8fr;
		}

		.table-body .tr {
			grid-template-columns: 0.8fr 0.6fr 0.8fr 0.7fr 0.7fr 0.7fr 0.7fr 0.7fr 1.3fr 0.6fr 0.8fr 0.8fr;
		}
	}

	&--transactions-pending-withdrawal-fiat {

		@media only screen and (min-width: 1199px) {
			min-width: 1380px;
		}

		.table-header,
		.table-body {

			.tr {
				grid-template-columns: 0.8fr 0.7fr 0.8fr 0.8fr 1fr 0.8fr 0.8fr 0.8fr 1fr 1fr 0.5fr 0.9fr 0.8fr;
			}
		}
	}

	&--ledger {
		background: #fff;

		@media only screen and (min-width: 1199px) {
			min-width: 1480px;
		}

		.table-header,
		.table-body {
			.tr {
				grid-template-columns: 1.2fr 0.8fr 1.1fr 1fr 1.2fr 1.2fr 1fr 1fr 0.9fr 1fr 1fr 0.9fr 1.3fr 1.2fr 1.1fr 0.8fr;
			}
		}

		.table-footer {
			border: 0;

			.select__drop {
				border-radius: 6px;
				overflow: hidden;
				min-width: 120px;
				left: 0;
				transform: translateX(0);
			}
		}
	}

	&--messages {
		.table-header {
			.tr {
				grid-template-columns: 0.9fr 3.5fr 1.8fr 1fr;
			}
		}

		.table-body {
			.tr {
				grid-template-columns: 0.9fr 3.5fr 1.8fr 1fr;
			}
		}
	}

	&--manage-users {
		.table-header {
			.tr {
				grid-template-columns: 0.8fr 1.1fr 1.1fr 1.6fr 2.2fr 1.5fr 1.2fr;
				input {
					margin-left: 12px;
				}
			}
		}

		.table-body {
			.tr {
				grid-template-columns: 0.8fr 1.1fr 1.1fr 1.6fr 2.2fr 1.5fr 1.2fr;
			}
		}
		input[type='checkbox'] {
			box-sizing: border-box;
			width: 20px;
			height: 20px;
			border: 1px solid var(--primary-blue-600);
			border-radius: 2px;
			position: relative;
			-webkit-appearance: none;
			appearance: none;
			&:checked {
				background-color: var(--primary-blue-600);
			}
			&:checked:after {
				content: url("data:image/svg+xml,%3Csvg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.5418 3.21094L4.92204 10.7943L1.4585 7.3473' stroke='white' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
				top: 8%;
				right: 8%;
				position: absolute;
			}
			&:focus {
				border: 1px solid var(--primary-blue-600);
			}
		}
	}

	&--admin-management {
		.tr {
			@media only screen and (min-width: 1199px) {
				align-items: center;
				grid-template-columns: 1.3fr 2fr 2fr 0.9fr;
			}

			&.is-edit {
				background: var(--primary-blue-50);
			}
		}
	}

	&--liquidity-crypto {
		.td-sorting-arrow-button {
			font-size: 12px;
		}

		.table-header {
			.td-name {
				border-radius: 6px;
				background: var(--greyscale-grey-10);
				padding: 8px 7px;
				width: 100%;
				color: var(--greyscale-grey-600);
				font-size: 12px;
				font-style: normal;
				font-weight: 400;
				line-height: normal;
			}
		}

		.tr {
			@media only screen and (min-width: 1199px) {
				align-items: center;
				grid-template-columns: 2fr 1.7fr 1.9fr 1.7fr 1.6fr 2fr 1.5fr 1.6fr 1.8fr;
			}
		}

		.td {
			padding: 10px 5px;

			@media only screen and (max-width: 1199px) {
				padding: 10px;
			}
		}
	}

	&--liquidity-fiat {
		.td-sorting-arrow-button {
			font-size: 12px;
		}

		.table-header {
			.td-name {
				border-radius: 6px;
				background: var(--greyscale-grey-10);
				padding: 8px 7px;
				width: 100%;
				color: var(--greyscale-grey-600);
				font-size: 12px;
				font-style: normal;
				font-weight: 400;
				line-height: normal;
			}
		}

		.tr {
			@media only screen and (min-width: 1199px) {
				align-items: center;
				grid-template-columns: 2fr 2fr 2fr 2fr 2fr;
			}
		}

		.td {
			padding: 10px 5px;
		}
	}

	&.table--referral-system-referrals {
		.tr {
			@media only screen and (min-width: 1199px) {
				grid-template-columns: 1.6fr 1.6fr 2.5fr 2.4fr 2.4fr 1.6fr 1.1fr;
			}
		}
	}

	&.table--referral-trades {
		.tr {
			@media only screen and (min-width: 1199px) {
				grid-template-columns: 0.8fr 1fr 0.8fr 0.8fr 0.5fr 0.5fr 0.8fr 0.8fr 0.8fr 0.7fr 0.6fr;
			}
		}

		.td {
			padding: 10px 2px;
		}
	}

	&.table--referral-system-referrals-by-user {
		.tr {
			@media only screen and (min-width: 1199px) {
				grid-template-columns: 1.4fr 1.4fr 1.7fr 1.8fr 1.8fr 1.6fr 2fr 1fr;
			}
		}

		.td {
			padding: 10px 5px;
		}
	}

	&--user-management-reset-request {
		.tr {
			@media only screen and (min-width: 1199px) {
				grid-template-columns: 1.4fr 2fr 1.4fr 1.7fr 1.7fr 2fr 2fr 1.5fr;
			}
		}

		.td {
			padding: 10px 6px;
		}
	}

	&--user-management-reset-request--admin {
		.tr {
			@media only screen and (min-width: 1199px) {
				grid-template-columns: 1.4fr 2fr 1.4fr 1.7fr 1.7fr 2fr 2fr 1.5fr;
			}
		}

		.td {
			padding: 10px 6px;
		}
	}

	&--required-emails {
		.tr {
			@media only screen and (min-width: 1199px) {
				grid-template-columns: 1fr 1fr 1fr 1fr;
			}
		}

		.table-body {
			.tr {
				align-items: stretch;
			}

			.td p {
				line-height: 160%;
			}
		}
	}

	&--required-emails-user {
		.tr {
			@media only screen and (min-width: 1199px) {
				grid-template-columns: 4fr 2fr 1fr;
			}
		}

		.table-body {
			.tr {
				align-items: stretch;
			}

			.td p {
				line-height: 160%;
				margin-top: 0;
			}
		}
	}

	&--fee-management-withdraw-crypto {
		.tr {
			@media only screen and (min-width: 1199px) {
				grid-template-columns: 2fr 2fr 2fr 1fr;
			}
		}
	}

	&--fee-management-trade {
		.tr {
			@media only screen and (min-width: 1199px) {
				grid-template-columns: 1.7fr 1.3fr 1.4fr 1.6fr 1.5fr 1.6fr 1.5fr 1.6fr 1.2fr;
			}
		}
		.td {
			padding-left: 5px;
			padding-right: 5px;

			&:first-child {
				padding-left: 5px;
			}
			&:last-child {
				padding-right: 5px;
			}
		}
	}

	&--user-management-fee-management-trade {
		.tr {
			@media only screen and (min-width: 1199px) {
				grid-template-columns: 1.7fr 1.3fr 1.4fr 1.6fr 1.5fr 1.6fr 1.5fr 1.7fr 1.2fr;
			}
		}
		.td {
			padding-left: 5px;
			padding-right: 5px;

			&:first-child {
				padding-left: 5px;
			}
			&:last-child {
				padding-right: 5px;
			}
		}
	}

	&--fee-management-spread-management {
		.tr {
			@media only screen and (min-width: 1199px) {
				grid-template-columns: 3fr 3fr 1fr;
			}
		}
	}

	&--fee-management-currencles {
		.tr {
			@media only screen and (min-width: 1199px) {
				grid-template-columns: 2fr 2fr 2fr 2fr;
			}
		}
	}

	&.table--bank-accounts-with-select {
		.table-header {
			.td {
				padding: 10px 5px;
			}
		}

		.table-body {
			.td {
				padding: 10px 5px;
			}
		}

		.tr {
			@media only screen and (min-width: 1199px) {
				grid-template-columns: 1.1fr 1.3fr 1fr 1fr 1fr 1fr 1.1fr 1fr 1fr;
			}

			.td {
			}
		}

		.select--table {
			padding: 0;
			height: auto;

			&.active {
				border: 0;
				background: transparent;
			}

			button {
				font-weight: 400;
				color: var(--greyscale-grey-600);
			}
		}
	}

	&--transfer-history-crypto {

		@media only screen and (min-width: 1199px) {
			min-width: 1280px;
		}

		.table-header,
		.table-body {
			.tr {
				grid-template-columns: 1fr 1fr 1.2fr 1.2fr 1fr 1fr 1fr 1.3fr 1.3fr 0.7fr 1.2fr;
				gap: 4px;
			}
		}

		.selected__current_text {
			display: flex;
			gap: 8px;
			align-items: center;

			img {
				width: 18px;
				min-width: 18px;
				height: 18px;
				min-height: 18px;
			}
		}
	}

	&--transaction-history-fiat {

		.table-header,
		.table-body {

			.tr {
				grid-template-columns: 1fr 1fr 1.3fr 1.5fr 1fr 1fr 1fr 1.6fr 0.7fr 1.1fr;
			}
		}
	}

	&--transaction-history-trade {

		.table-header,
		.table-body {

			.tr {
				grid-template-columns: 0.9fr 0.9fr 1fr 1.2fr 1.4fr 1.4fr 1.5fr 0.8fr 0.9fr;
			}
		}
	}

	&--deposit-history-crypto {

		.table-header,
		.table-body {

			.tr {
				grid-template-columns: 1.2fr 1.1fr 1fr 1.1fr 1.3fr 1.1fr 1.5fr 1.5fr 1fr 1.3fr;
			}
		}
	}

	&--deposit-history-fiat {
		.table-header,
		.table-body {

			.tr {
				grid-template-columns: 1fr 0.9fr 1.2fr 1.1fr 1fr 1fr 1.5fr 0.8fr 0.9fr;
			}
		}
	}

	&.table--trading-limits-crypto {
		.table-header {
			.td {
				padding: 10px;
			}
		}

		.table-body {
			.td {
				padding: 10px;
			}
		}

		.table-footer {
			border-top: 0;
		}
	}

	&.table--trade-management {
		.table-header {
			.td-name {
				width: auto;
				display: inline-block;
				min-width: 100px;
				text-align: center;

				p {
					justify-content: center !important;
				}
			}
		}

		.table-body {
			min-height: inherit;
			border-bottom: 0;
		}
	}

	&.table--referral.one--user {
		.table-header {
			.td {
				padding: 10px 5px;
			}
		}

		.table-body {
			.td {
				padding: 10px 5px;
			}
		}

		.table-footer {
			border-top: 0;
		}

		.tr {
			@media only screen and (min-width: 1199px) {
				grid-template-columns: 1fr 1.2fr 1.5fr 1.5fr 1.4fr 1.4fr 1.2fr 1fr;
			}

			.td {
				&:first-child {
					padding-left: 10px;
				}

				&:last-child {
					padding-right: 10px;
				}
			}
		}
	}
}

.table-block {
	margin-top: 10px;
	border-radius: 14px;
	border: 1px solid var(--gray100);
}

.table-header {
	@media screen and (max-width: 1199.98px) {
		display: none;
	}

	.tr {
		border: none;

		&:last-child {
			margin: auto;
		}
	}

	.td-name {
		background: var(--gray300);
		padding: 6px 8px;
		border-radius: 6px;
		width: 100%;
		color: var(--gray600);
		font-size: 14px;
		font-weight: 400;
	}
}

.table-title {
	font-size: 20px;
	font-weight: 600;
	line-height: normal;
	margin-bottom: 0;
}

.table-wrap {
	display: flex;
	align-items: center;
	justify-content: space-between;

	@media screen and (max-width: 720px) {
		width: 100%;
	}
}

.table-footer {
	display: flex;
	justify-content: space-between;
	padding: 20px 16px;
	border-radius: 0 0 14px 14px;
	border-top: 1px solid var(--greyscale-grey-50);
	background-color: var(--additional-white);
	&--bank-accounts-with-select {
		border: 1px solid var(--gray100);
		border-radius: 0 0 14px 14px;
	}

	.pagination-block {
		padding: 0;
		margin: 0;
		display: flex;
		justify-content: space-between;
		align-items: center;
		width: 100%;

		@media only screen and (max-width: 767.98px) {
			flex-direction: column;
			gap: 12px;
		}
	}
}

.table.table--transaction-fiat
// .table.table--transaction-crypto-withdrawal
{
	//min-width: 1300px;
}

.tr--editable {
	background: var(--primary-blue-50);
}

