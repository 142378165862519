.account-admin-files {
	display: flex;
	flex-flow: row wrap;
	gap: 10px;

	&__upload {
		border-radius: 6px;
		border: 0.5px solid var(--primary-blue-400);
		background: var(--primary-blue-50);
		display: block;
		width: 110px;
		height: 96px;
	}

	&__upload-label {
		padding: 10px;
		width: 100%;
		height: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	&__item {
		position: relative;

		&:hover .account-admin-files__delete {
			display: block;
		}
	}

	&__link {
		margin-right: 0;
		border-radius: 6px;
		border: 1px solid var(--primary-blue-200);
		background: var(--additional-white);
		width: 115px;
		min-width: 115px;
		min-height: 80px;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		padding: 10px;
		cursor: pointer;

		img {
			width: 52px;
		}
	}

	&__delete {
		position: absolute;
		top: 8px;
		right: 8px;
		width: 24px;
		min-width: 24px;
		height: 24px;
		background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect x='0.4' y='0.4' width='23.2' height='23.2' rx='11.6' fill='%23FEECEB'/%3E%3Crect x='0.4' y='0.4' width='23.2' height='23.2' rx='11.6' stroke='%23FE0000' stroke-width='0.8'/%3E%3Cpath d='M8.35033 18C8.07533 18 7.83991 17.9021 7.64408 17.7063C7.44824 17.5104 7.35033 17.275 7.35033 17V7.5H6.66699V6.5H9.80033V6H14.2003V6.5H17.3337V7.5H16.6503V17C16.6503 17.2667 16.5503 17.5 16.3503 17.7C16.1503 17.9 15.917 18 15.6503 18H8.35033ZM15.6503 7.5H8.35033V17H15.6503V7.5ZM10.117 15.5667H11.117V8.91667H10.117V15.5667ZM12.8837 15.5667H13.8837V8.91667H12.8837V15.5667Z' fill='%23FE0000'/%3E%3C/svg%3E%0A");
		display: none;
	}

	&__name {
		color: var(--greyscale-grey-800);
		font-size: 12px;
		font-style: normal;
		font-weight: 700;
		line-height: normal;
		width: 100%;
		overflow: hidden;
		text-overflow: ellipsis;
		margin-top: 8px;
		text-wrap: nowrap;
	}
}
