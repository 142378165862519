.table--associated-accounts {
	margin-top: 10px;
}
.associated-accounts {
	.table-block:first-child {
		border-bottom-left-radius: 0px;
		border-bottom-right-radius: 0px;
		border-bottom: none;
	}
}
.table--associated-accounts .table-header .tr .td > p {
	border-radius: 6px;
	background: var(--greyscale-grey-10);
	padding: 2px 10px;
	height: 34px;
	display: flex;
	align-items: center;
	color: var(--greyscale-grey-600);
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
}

.table--associated-accounts {
	.tr {
		border-bottom: 1px solid #F2F2F2;
	}
	.table-body {
		.td:last-child {
			display: flex;
			justify-content: center;
			align-items: center;
		}
	}
	@media (max-width: 1200px) {
		.table-body .td:last-child {
			display: block;
		}
	}
	.table-header .tr {
		border-bottom: none;
	}
	.td__text {
		//styleName: Body/Body4 14 Regular;
		font-family: Euclid Circular A;
		font-size: 14px;
		font-weight: 400;
		line-height: 22.4px;
		text-align: left;
		color: var(--gray800);
		//span {
		//	//styleName: Body/Body6 12 Regular;
		//		position: relative;
		//		right: -10px;
		//		background: rgba(241, 247, 252, 1);
		//		border: 0.8px solid rgba(0, 113, 171, 1);
		//		color: rgba(0, 113, 171, 1);
		//		border-radius: 50%;
		//		padding: 2px 6px;
		//
		//		font-family: Euclid Circular A;
		//		font-weight: 400;
		//		font-size: 12px;
		//		line-height: 15.22px;
		//		letter-spacing: 0px;
		//		text-align: center;
		//}
	}
	.td__more {
		//styleName: Body/Body6 12 Regular;
		font-family: Euclid Circular A;
		font-size: 12px;
		font-weight: 400;
		line-height: 15.22px;
		text-align: left;
		color: var(--text-color2);
	}
	.td {
		padding: 0px 6px;
	}
	.table, .table-body, .td {
		margin-top: 4px;
	}
	.table ,.td__wrap {
		gap:0px;
}
.td__wrap {
padding: 0px;
display: flex;
flex-direction: column;
justify-content: flex-start;
align-items: flex-start;
margin-left: 6px;
}
.btn--icon {
cursor: pointer;
display: flex;
justify-content: center;
align-items: center;
border-radius: 6px;
border: 1px solid #a8dbf9;
border: 1px solid var(--blue200);
background: #d4edfc;
background: var(--blue100);
box-shadow: 0 2px 6px 0 rgba(33, 44, 99, .09);
width: 38px;
min-width: 38px;
height: 38px;
min-height: 38px;
padding: 0;
img {

}
}
.td-more ,.table {
margin-top: 0px;
}
}
.table--associated-accounts {
.table-block .pagination-block {
border-top: none;
}
}
.associated-accounts {
	.no-border {
		border: none !important;
	}
	.border {
		border: 1px solid #e4e4e4 !important;
	}
	.currencyButton {

		border: 1px solid #e4e4e4;
		padding: 4px;
		margin: 15px;
	}
	.currencyButton button {
		position: relative;
	}

	/* Стили для бейджа */
	.badge {
		position: relative;
		right: -8px;
		background: rgba(241, 247, 252, 1);
		border: 0.8px solid rgba(0, 113, 171, 1);
		color: rgba(0, 113, 171, 1);
		border-radius: 50%;
		padding: 2px 6px;

		font-family: Euclid Circular A;
		font-weight: 400;
		font-size: 12px;
		line-height: 15.22px;
		letter-spacing: 0px;
		text-align: center;

	}
}

