.tooltip {
	background: var(--primary-blue-50);
	border: 1px solid var(--primary-blue-200);
	position: absolute;
	z-index: 999;
	transform: translateX(-50%);
	display: none;
	padding: 20px;
	border-radius: 10px;
	top: calc(100% + 3px);
	&::-webkit-scrollbar {
		width: 4px;
	}
	&::-webkit-scrollbar-thumb {
		border-radius: 2px;
		background-color: #b6b6b6;
	}
	&::-webkit-scrollbar-track {
		border-radius: 2px;
		margin: 10px 5px 10px 5px;
		background: #eeeeee;
	}

	&--width-720 {
		width: 720px;
	}

	&__hash {
		word-wrap: break-word;
	}

	.bank-info {
		display: flex;
		gap: 16px;
		flex-direction: column;
		&--title {
			display: flex;
			padding: 15px 16px;
			flex-direction: column;
			align-items: flex-start;
			gap: 10px;
			align-self: stretch;
			background: var(--additional-white);
			border-radius: 8px;
			border: 1px solid var(--primary-blue-200);
			color: var(--greyscale-grey-800);
			font-size: 14px;
			font-weight: 500;
		}
		&--list {
			display: flex;
			padding-left: 8px;
			align-items: flex-start;
			gap: 8px;
			flex-direction: row;
		}
		&--item {
			width: 160px;
			span {
				color: var(--greyscale-grey-600);
				padding-left: 0px !important;
				font-size: 14px;
				font-style: normal;
				font-weight: 400;
			}
			p {
				color: var(--greyscale-grey-800);
				font-size: 14px;
				font-style: normal;
				font-weight: 500 !important;
			}
		}
		&--text {
			margin-left: 8px;
			color: var(--greyscale-grey-600);
			padding-left: 0px !important;
			font-size: 14px;
			font-style: normal;
			font-weight: 400;
			p {
				padding-left: 8px;

				color: var(--greyscale-grey-800);
				font-size: 14px;
				font-style: normal;
				font-weight: 400;
			}
		}
	}
}

.tooltip-item {
	position: relative;
	display: inline-block;
	overflow: visible;
	width: 15px;
	height: 14px;
	&__title {
		font-weight: 600;
	}
	&:hover {
		.tooltip {
			display: block;
		}
	}
}

.tooltip-item-note {
	position: relative;
	display: inline-block;
	overflow: visible;
	&__title {
		font-weight: 600;
	}
	&:hover {
		.tooltip-item-text {
			color: var(--blue700) !important;
		}
		.tooltip-note {
			display: block;
		}
	}
}

.tooltip-note {
	background: var(--primary-blue-50);
	border: 1px solid var(--primary-blue-200);
	min-width: 320px;
	max-width: 500px;
	transform: translate(-80%, 0);
	padding: 12px 16px;
	font-size: 14px;
	border-radius: 10px;
	position: absolute;
	box-sizing: border-box;
	display: none;
	z-index: 999;
	color: var(--text-primary);
	i {
		position: absolute;
		bottom: 100%;
		left: 90%;
		margin-left: -12px;
		width: 24px;
		height: 12px;
		overflow: hidden;
		&::after {
			content: '';
			position: absolute;
			width: 12px;
			height: 12px;
			left: 50%;
			transform: translate(-50%, 50%) rotate(45deg);
			background: var(--primary-blue-50);
			border: 1px solid var(--primary-blue-200);
		}
	}
	&::-webkit-scrollbar {
		width: 4px;
	}
	&::-webkit-scrollbar-thumb {
		border-radius: 2px;
		background-color: #b6b6b6;
	}
	&::-webkit-scrollbar-track {
		border-radius: 2px;
		margin: 10px 5px 10px 5px;
		background: #eeeeee;
	}
}

