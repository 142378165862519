.loading-spinner {
	display: block;
	width: 30px;
	height: 30px;
	content: '';
	border: 5px solid var(--greyscale-grey-200);
	border-top: 5px solid var(--blue600);
	border-radius: 50%;
	box-sizing: border-box;
	animation: loading-spin 1s linear infinite;
}

@keyframes loading-spin {
	0% {
		transform: rotate(0deg);
	}

	100% {
		transform: rotate(360deg);
	}
}
