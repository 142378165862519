.create-admin {

	&__title {
		margin-bottom: 16px;
		color: var(--greyscale-grey-700);
		font-size: 18px;
		font-style: normal;
		font-weight: 500;
		line-height: normal;
		display: flex;
		align-items: center;
		gap: 10px;

		.create-admin__number {
			color: var(--primary-blue-600);
			font-size: 16px;
			font-style: normal;
			font-weight: 600;
			line-height: normal;
			display: flex;
			align-items: center;
			justify-content: center;
			border-radius: 50%;
			background: var(--additional-white);
			width: 28px;
			min-width: 28px;
			height: 28px;
			min-height: 28px;
		}
	}

	&__table {
		display: flex;
		flex-direction: column;
		gap: 16px;

		@media only screen and (max-width: 767.98px) {
			gap: 26px;
		}
	}

	&__table-item {
		display: flex;
		align-items: center;
		justify-content: space-between;

		@media only screen and (max-width: 767.98px) {
			flex-direction: column;
			align-items: flex-start;
			gap: 8px;
		}
	}

	&__table-title {
		color: var(--greyscale-grey-700);
		font-size: 14px;
		font-style: normal;
		font-weight: 500;
		line-height: 160%; /* 22.4px */
	}

	&__table-toggler {
		display: flex;
		gap: 30px;

		@media only screen and (min-width: 768px) {
			gap: 80px;
		}

		.switch {

			@media only screen and (min-width: 768px) {
				min-width: 128px;
			}
		}
	}

	&__footer-buttons {
		margin-top: 24px;
		display: flex;
		align-items: center;
		justify-content: flex-end;
		gap: 16px;

		@media only screen and (max-width: 575.98px) {
			justify-content: flex-start;
			align-items: flex-start;
			flex-direction: column;
		}

		button {

			@media only screen and (max-width: 575.98px) {
				width: 100%;
			}

			@media only screen and (min-width: 768px) {
				min-width: 160px;
			}
		}
	}
}
