.admin-balance-block {
	&.balance-info {
		padding: 28px;

		@media (max-width: 1500px) {
			flex-wrap: wrap;
		}

		@media (max-width: 560px) {
			padding: 28px 12px;
		}
	}
}

.balance-block-list {
	.balance-info-list__item {
		cursor: pointer;
		p {
			line-height: 1.167;
		}
	}
	.coin--type5 .coin__text--fz-12 {
		line-height: 1.167;
	}
}

.balance-info-list__item {
	.coin__text {
		line-height: 14px;
	}
	p {
		line-height: 14px;
	}
}

.buttons-flexcontainer {
	display: flex;
	flex: 1;
	max-width: 1150px;
	justify-content: flex-end;
}


.sidebar {
	width: 314px !important;
	padding: 0px;
}

.main-content {
	padding: 40px 30px !important;
}

.admin-panel {
	.tabs-buttons {
		display: flex;
	}

	.title-block {
		align-items: flex-start!important;
		flex-wrap: wrap;
		.title {
			font-family: "Euclid Circular A", sans-serif !important;
			color: black !important;
			font-size: 38px !important;
			font-style: normal !important;
			font-weight: 600 !important;
			line-height: 100% !important;
		}
	}

	.balance-info, .admin-balance-block {
		display: flex;
		flex-direction: column !important;
	}

	.title-info {
		display: flex;
		justify-content: space-between;
		align-items: center;
		background: #f1f7fc;
		padding: 16px 24px;
		border-radius: 10px;
		&_left {
			font-family: Euclid Circular A;
			font-size: 20px;
			font-weight: 600;
			line-height: 25px;
			letter-spacing: 0em;
			text-align: left;
		}

		&_right {
			display: flex;
			.info {
				font-family: Euclid Circular A;
				font-size: 18px;
				font-weight: 400;
				line-height: 23px;
				letter-spacing: 0px;
				text-align: left;

			}
			.results {
				margin-left: 3px;
				font-family: Euclid Circular A;
				font-size: 18px;
				font-weight: 500;
				line-height: 23px;
				letter-spacing: 0px;
				text-align: left;

			}
		}
	}
	.balance-info-list {

	}
	@media (max-width: 1100px) {
		.balance-info-list {
			display: flex;
			flex-wrap: wrap !important;
			width: 100%;
		}
		.balance-info-list__items  {
			width: 100% !important;
		}
		.balance-info-date {
			width: 100%;
		}
		.admin-panel .recharts-wrapper {
			 right: 0px;
			 top: 0px;
		}
	}
	.balance-info-list__items {
		cursor: pointer;
		width: 360px;
		height: 60px;
		padding: 16px;
		border-radius: 10px;
		border: 1px solid rgba(242, 242, 242, 1);
		display: flex;
		justify-content: space-between;
		align-items: center;
		.box-info {
			display: flex;
			align-items: center;
			height: 100%;
			&_img {
				display: flex;
				align-items: center;
				img {
					width: 28px;
					height: 28px;
				}
			}
			&_name {
				margin-left: 10px;
				display: flex;
				align-items: center;
				span {
					font-size: 16px;
					font-weight: 500; /* Medium font weight */
					line-height: 24px;
					letter-spacing: 0em; /* 0em can be simply 0, but it's fine to use 'em' unit */
					text-align: left;
				}
			}
		}
		.box-info-coin {
			height: 100%;
			align-items: center;
			display: flex;
			span {
				color: rgba(20, 20, 20, 1);
				font-family: 'Euclid Circular A', sans-serif;
				font-size: 14px;
				font-weight: 400; /* Regular font weight */
				line-height: 22px;
				letter-spacing: 0px; /* No additional spacing between letters */
				text-align: right; /* Aligns text to the right */
			}
			p {
				color: rgba(67, 67, 67, 1);
				font-family: 'Euclid Circular A', sans-serif;
				font-size: 14px;
				font-weight: 400; /* Regular font weight */
				line-height: 22px;
				letter-spacing: 0px; /* No additional spacing between letters */
				text-align: right; /* Aligns text to the right */
			}
		}
	}
	.custom-tooltip_block {
		padding: 10px;
		display: flex;
		flex-direction: column;
		justify-content: center;
		text-align: center;
		border-radius: 4px;
		background: rgba(255, 255, 255, 1);
		.crypto {
			font-family: 'Euclid Circular A', sans-serif;
			font-size: 12px;
			font-weight: 500;
			line-height: 14px;
			letter-spacing: 0em;
			text-align: center;
		}
		.eur {
			font-family: 'Euclid Circular A', sans-serif;
			font-size: 10px;
			font-weight: 400;
			line-height: 14px;
			letter-spacing: 0em;
			text-align: center;
		}
	}
	.custom-tooltip_block:before {
		content: '';
		position: absolute;
		bottom: 3px;
		left: 50%;
		width: 0;
		height: 0;
		border: 15px solid transparent;
		border-top-color: #FFFFFFFF; /* Same as bubble's background */
		border-bottom: 0;
		margin-left: -15px;
		margin-bottom: -15px;
	}
	.recharts-wrapper {
		right: -172px;
		top: 5px;
	}

		//.active {
		//	background: #d4edfc;
		//}

}
@media (max-width: 1205px) {
	.balance-info-schedule {
		display: flex;
		justify-content: center;
		width: 100%;
	}
	.admin-panel .recharts-wrapper {

		right: 0px;
		top: 5px;
	}
}



