.NewUserRequestsInfo {
	.popup-header__title {
		font-family: Euclid Circular A;
		font-weight: 600;
		font-size: 20px;
		line-height: 25.36px;
		letter-spacing: 0%;
		text-align: center;

	}
	.popup-header__body {
		a {
			color: rgba(0, 113, 171, 1);
		}
		font-family: Euclid Circular A;
		font-weight: 400;
		font-size: 16px;
		line-height: 25.6px;
		letter-spacing: 0px;
		text-align: center;

	}
}
