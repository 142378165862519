.maintenance-page {

	.wrapper {
		overflow: hidden;
		display: flex;
		flex-direction: column;
		min-height: 100vh;
	}

	main {
		display: flex;
		flex-direction: column;
		flex: 1 1 auto;
	}
}

.maintenance {
	position: relative;
	display: flex;
	flex-direction: column;
	flex: 1 0 auto;
	align-items: center;
	justify-content: center;
	padding: 130px 0 40px 0;

	@media only screen and (max-width: 767.98px) {
		padding: 90px 0 40px 0;
	}

	&__logo {
		position: absolute;
		top: 80px;
		left: 0;
		width: 100%;
		display: flex;
		justify-content: center;

		@media only screen and (max-width: 767.98px) {
			top: 40px;
		}
	}

	&__content {
		padding: 0 15px;
	}

	&__icon {
		text-align: center;
		margin-bottom: 32px;

		img {
			display: block;
			margin: 0 auto;
		}
	}

	&__title {
		color: var(--Greyscale-Grey-800);
		text-align: center;
		font-size: 38px;
		font-style: normal;
		font-weight: 600;
		line-height: 100%;
		margin-bottom: 22px;
	}

	&__text {
		color: var(--Greyscale-Grey-600);
		text-align: center;
		font-size: 16px;
		font-style: normal;
		font-weight: 400;
		line-height: 160%;

		@media only screen and (min-width: 768px) {
			max-width: 370px;
			margin: 0 auto;
		}
	}
}
