@font-face {
	font-family: 'Euclid Circular A';
	src: url('../../fonts/subset-EuclidCircularA-Italic.woff2') format('woff2'),
		url('../../fonts/subset-EuclidCircularA-Italic.woff') format('woff');
	font-weight: normal;
	font-style: italic;
	font-display: swap;
}

@font-face {
	font-family: 'Euclid Circular A';
	src: url('../../fonts/subset-EuclidCircularA-LightItalic.woff2') format('woff2'),
		url('../../fonts/subset-EuclidCircularA-LightItalic.woff') format('woff');
	font-weight: 300;
	font-style: italic;
	font-display: swap;
}

@font-face {
	font-family: 'Euclid Circular A';
	src: url('../../fonts/subset-EuclidCircularA-Medium.woff2') format('woff2'),
		url('../../fonts/subset-EuclidCircularA-Medium.woff') format('woff');
	font-weight: 500;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Euclid Circular A';
	src: url('../../fonts/subset-EuclidCircularA-MediumItalic.woff2') format('woff2'),
		url('../../fonts/subset-EuclidCircularA-MediumItalic.woff') format('woff');
	font-weight: 500;
	font-style: italic;
	font-display: swap;
}

@font-face {
	font-family: 'Euclid Circular A';
	src: url('../../fonts/subset-EuclidCircularA-Bold.woff2') format('woff2'),
		url('../../fonts/subset-EuclidCircularA-Bold.woff') format('woff');
	font-weight: bold;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Euclid Circular A';
	src: url('../../fonts/subset-EuclidCircularA-BoldItalic.woff2') format('woff2'),
		url('../../fonts/subset-EuclidCircularA-BoldItalic.woff') format('woff');
	font-weight: bold;
	font-style: italic;
	font-display: swap;
}

@font-face {
	font-family: 'Euclid Circular A';
	src: url('../../fonts/subset-EuclidCircularA-Light.woff2') format('woff2'),
		url('../../fonts/subset-EuclidCircularA-Light.woff') format('woff');
	font-weight: 300;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Euclid Circular A';
	src: url('../../fonts/subset-EuclidCircularA-SemiBold.woff2') format('woff2'),
		url('../../fonts/subset-EuclidCircularA-SemiBold.woff') format('woff');
	font-weight: 600;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Euclid Circular A';
	src: url('../../fonts/subset-EuclidCircularA-Regular.woff2') format('woff2'),
		url('../../fonts/subset-EuclidCircularA-Regular.woff') format('woff');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Euclid Circular A';
	src: url('../../fonts/subset-EuclidCircularA-SemiBoldItalic.woff2') format('woff2'),
		url('../../fonts/subset-EuclidCircularA-SemiBoldItalic.woff') format('woff');
	font-weight: 600;
	font-style: italic;
	font-display: swap;
}
