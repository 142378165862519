.popup-search {
	display: flex;
	align-items: center;
	gap: 16px;
	padding: 8px;
	border-radius: 10px;
	border: 1px solid var(--greyscale-grey-100);
	background: var(--additional-white);

	.select {
		height: 28px;
		border: 0;
		border-radius: 0;

		&.active {

			.select__current {
				background-color: #fff;
				border-color: var(--primary-blue-200);
			}
		}

		&__current {
			width: 100%;
			height: 100%;
			border-radius: 6px;
			background: var(--primary-blue-50);
			border: 1px solid var(--primary-blue-50);
			padding: 0 10px;
			display: flex;
			align-items: center;
			justify-content: space-between;
			flex: 1 0 auto;
		}

		&__current-text {
			color: var(--greyscale-grey-800);
			font-size: 14px;
			font-style: normal;
			font-weight: 500;
			line-height: 160%; /* 22.4px */
			white-space: nowrap;
		}

		&__current-arrow {
			background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16' fill='none'%3E%3Cpath d='M11.3337 6.0026L8.35388 8.98238C8.15862 9.17765 7.84203 9.17765 7.64677 8.98238L4.66699 6.0026' stroke='%23141414' stroke-width='1.2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
			width: 16px;
			min-width: 16px;
			height: 16px;
			min-height: 16px;
			margin-left: 5px;

			svg {
				display: none;
			}
		}

		.select__drop {
			padding: 2px;

			ul>li>button {
				padding: 5px 10px;
				border-radius: 4px;
				color: var(--greyscale-grey-500);
				font-size: 14px;
				font-style: normal;
				font-weight: 400;
				line-height: 160%;
				background: transparent;

				&:hover {
					background: var(--primary-blue-50);
					color: var(--greyscale-grey-800);
					font-weight: 500;
				}
			}
		}
	}

	.input {
		margin-bottom: 0;
		height: 28px;
		width: 100%;
	}

	.input-wrapper {
		height: 100%;
	}

	.input-item {
		height: 100%;
		border-radius: 0;
		border: 0;
		padding: 0 26px 0 0;
		color: var(--greyscale-grey-800);
		font-size: 14px;
		font-style: normal;
		font-weight: 500;
		line-height: 160%; /* 22.4px */
	}

	.input-icon {
		position: absolute;
		top: 50%;
		left: auto;
		right: 0;
		transform: translateY(-50%);
	}
}
