.form {
	margin-top: 0;
	width: 100%;
	max-width: 100%;
	background: #ffffff;
	border: 0;
	border-radius: 0;
	padding: 0;

	&--type2 {
		border-radius: 14px;
		border: 1px solid var(--gray100);
		background: var(--additional-white);
		padding: 24px;

		.form-body {
			width: 100%;
			max-width: 610px;
			border-radius: 10px;
			background: #f1f7fc;
			margin: 0;
			padding: 20px;
		}

		.input {
			display: flex;
			justify-content: flex-start;
			gap: 10px;
			margin: 0;

			@media screen and (max-width: 767.98px) {
				flex-wrap: wrap;
				gap: 0;
			}

			&--row {
				gap: 8px;
				display: flex;
				flex-direction: column;
			}

			&--col-2 {
				display: grid;
				grid-template-columns: repeat(2, 1fr);
				grid-gap: 10px;
				gap: 10px;

				@media screen and (max-width: 767.98px) {
					grid-template-columns: repeat(1, 1fr);
					gap: 0;
					grid-gap: 0;
				}
			}
		}

		.input__name {
			color: var(--gray600);
			font-size: 14px;
			font-style: normal;
			font-weight: 400;
			line-height: 160%; /* 22.4px */
		}

		.input-item {
			height: 44px;
			border-radius: 10px;
			background: var(--additional-white);
			border: 1px solid var(--additional-white);
			padding: 12px 16px;
			color: var(--gray800);
			font-size: 14px;
			font-style: normal;
			font-weight: 500;
			line-height: 160%; /* 22.4px */

			&::placeholder {
				font-family: var(--font-sans);
				color: var(--greyscale-grey-200);
				font-size: 14px;
				font-style: normal;
				font-weight: 400;
				line-height: 160%; /* 22.4px */
				opacity: 1;
			}
		}
		.textarea-item {
			height: 100px;
		}

		.select {
			width: 100%;
			height: 44px;
			border: none;
			border-radius: 10px;
			.select__custom {
				font-weight: 500;
				font-size: 14px;
				font-family: var(--font-sans);
				.select-value {
					overflow: visible;
				}
				.upper__text {
					font-weight: 500;
					font-family: var(--font-sans);
					color: var(--gray800);
				}
			}
			@media screen and (max-width: 767.98px) {
				width: 100%;
			}
		}
	}

	&--type3 {
		border-radius: 14px;
		border: 1px solid var(--gray100);
		background: var(--additional-white);
		padding: 24px;

		.form-body {
			width: 100%;
			border-radius: 10px;
			background: var(--primary-blue-50);
			margin: 0;
			padding: 20px;
		}

		.input {
			margin-bottom: 0;

			&--grid {
				display: grid;
				grid-template-columns: 1fr 1fr;
				gap: 16px;
			}
			&--row {
				gap: 9px;
				display: flex;
				flex-direction: column;
				justify-content: start;
			}

			&--col-2 {
				display: grid;
				grid-template-columns: 2.2fr 1fr;
				grid-gap: 10px;
				gap: 10px;

				@media screen and (max-width: 767.98px) {
					grid-template-columns: repeat(1, 1fr);
					gap: 0;
					grid-gap: 0;
				}
			}
			.input-icon {
				svg path {
					stroke: var(--primary-blue-400);
				}
			}
		}

		.input__name {
			color: var(--gray600);
			font-size: 14px;
			font-style: normal;
			font-weight: 400;
			line-height: 160%; /* 22.4px */
		}

		.input-item {
			border-radius: 10px;
			border: 1px solid var(--greyscale-grey-100);
			background: var(--additional-white);
			height: 44px;
			padding: 12px 16px;
			color: var(--gray800);
			font-size: 14px;
			font-style: normal;
			font-weight: 500;
			line-height: 160%; /* 22.4px */

			&::placeholder {
				font-family: var(--font-sans);
				color: var(--greyscale-grey-200);
				font-size: 14px;
				font-style: normal;
				font-weight: 400;
				line-height: 160%; /* 22.4px */
				opacity: 1;
			}
		}

		.textarea-item {
			height: 100px;
		}

		.select {
			width: 100%;
			height: 44px;
			border: none;
			border-radius: 10px;

			@media screen and (max-width: 767.98px) {
				width: 100%;
			}

			.select__custom {
				font-weight: 500;
				font-size: 14px;
				font-family: var(--font-sans);

				.select-value {
					overflow: visible;
				}

				.upper__text {
					font-weight: 500;
					font-family: var(--font-sans);
					color: var(--gray800);
				}
			}
			&--detailed {
				border: 1px solid var(--greyscale-grey-100);
			}
		}

		.form__divider {
			margin: 18px 0;
			border-top: 1px solid #c7e0ef;
		}
		.react-datepicker__input-container input {
			border-radius: 10px;
			color: var(--greyscale-grey-800);
			height: 44px;
		}
		.react-datepicker__navigation {
			top: 0px;
			&--next {
				right: 5px;
			}
		}
	}

	&--type4 {
		border-radius: 14px;
		border: 1px solid var(--gray100);
		background: var(--additional-white);
		padding: 24px;

		.form-body {
			width: 100%;
			max-width: 610px;
			border-radius: 10px;
			background: #f1f7fc;
			margin: 0;
			padding: 20px;
		}

		.input {
			margin: 0 0 16px 0;

			&--row {
				gap: 8px;
				display: flex;
				flex-direction: column;
			}

			@media screen and (max-width: 767.98px) {
				flex-wrap: wrap;
			}
		}

		.input__name {
			color: var(--gray600);
			font-size: 14px;
			font-style: normal;
			font-weight: 400;
			line-height: 160%; /* 22.4px */
		}

		.input--error {
			.input-item {
				border-color: var(--additional-error);
			}
		}

		.input-item {
			height: 44px;
			border-radius: 10px;
			background: var(--additional-white);
			border: 1px solid var(--additional-white);
			padding: 12px 16px;
			color: var(--gray800);
			font-size: 14px;
			font-style: normal;
			font-weight: 500;
			line-height: 160%; /* 22.4px */

			&::placeholder {
				font-family: var(--font-sans);
				color: var(--greyscale-grey-200);
				font-size: 14px;
				font-style: normal;
				font-weight: 400;
				line-height: 160%; /* 22.4px */
				opacity: 1;
			}
		}
		.textarea-item {
			height: 100px;
		}

		.select {
			width: 100%;
			height: 44px;
			border: none;
			border-radius: 10px;
			.select__custom {
				font-weight: 500;
				font-size: 14px;
				font-family: var(--font-sans);
				.select-value {
					overflow: visible;
				}
				.upper__text {
					font-weight: 500;
					font-family: var(--font-sans);
					color: var(--gray800);
				}
			}
			@media screen and (max-width: 767.98px) {
				width: 100%;
			}
		}
	}

	.show-pass {
		width: 22px;

		svg path {
			stroke: var(--blue600);
		}
	}

	.input--success .input-item {
		border: 1px solid var(--greyscale-grey-100);
	}

	.input-wrap {
		&--col-2 {
			display: grid;
			grid-template-columns: repeat(2, 1fr);
			gap: 10px;
		}
	}

	.form-title {
		font-size: 24px;
		font-style: normal;
		font-weight: 600;
		font-size: 24px;
		line-height: 30px;
		text-align: start;
		margin-bottom: 24px;
	}
}

