// 📌 Account details

.account-tabs {
	a {
		padding: 0 12px;
		height: 50px;
		display: flex;
		justify-content: center;
		align-items: center;
		font-weight: 400;
		font-size: 14px;
		border-right: 1px solid #e6e8ec;
		color: #777e90;
		background: #f5f5f5;

		&:first-child {
			border-radius: 8px 0 0;
		}

		&.active {
			color: #173b58;
			background: #fff;
		}
	}
}

.tabs-buttons.tabs-buttons---flex {
	display: inline-flex;
}

.table---custom-payment-method-fiat.table--payment-method-fiat .tr {
	grid-template-columns: 1.5fr 1.56fr 1.46fr 1.2fr 1.44fr 1.55fr 0.8fr;
}

.table--payment-method-fiat_5.table---custom-payment-method-fiat_5 .tr {
	grid-template-columns: 1.4fr 1.4fr 1.4fr 1.4fr 0.8fr;
}

.table--payment-method-crypto.table--payment-method-crypto--custom .tr {
	grid-template-columns: 1.5fr 1.5fr 1.5fr 3fr 0.8fr;
}

.td--fiat--right {
	justify-self: right;
}

.pagination-block--full {
	justify-content: space-between !important;
}

.user-management-list-item__name {
	&--color {
		color: #173b58 !important;
	}

	&--margin-bottom {
		margin-bottom: 30px;
	}
}

.user-management-list-item__info.custom-phone-number-input-wrapper {
	position: relative;
	z-index: 100;
	.react-tel-input {
		.form-control.custom-phone-number-input {
			height: 40px;
		}

		.flag-dropdown {
			height: 40px;
			width: 70px;
		}
	}
}

.user-management-list-item__info.custom-phone-number-input-info-wrapper {
	.react-tel-input {
		.form-control.input-item.custom-phone-number-input {
			padding: 0 0 0 25px;
			border: 0;
			font-size: 14px;
			color: #173b58;
			height: fit-content;
		}

		.flag-dropdown {
			height: unset;
			padding: 0;
			border-radius: 0;
			border-right: 0;
			bottom: 1px;
			left: -8px;

			.arrow {
				display: none;
			}
			.selected-flag {
				padding: 0 0 0 8px;
			}
		}
	}
}

.user-management-header__actions {
	button {
		width: 38px;
		height: 38px;
		display: flex;
		justify-content: center;
		align-items: center;
	}
}

.user-management-list .table-buttons {
	button {
		width: 38px;
		height: 38px;
	}
}
