.add-account-search-list {
	margin-bottom: 24px;
	max-height: 168px;
	padding: 0;
	background: #FFFFFF;
	border-radius: 10px;
	border: 1px solid var(--greyscale-grey-100);

	.add-account-search-item {
		width: 100%;
		height: auto;
		padding: 12px 16px;
		display: flex;
		align-items: center;
		gap: 10px;
		color: var(--greyscale-grey-800);
		font-size: 14px;
		font-style: normal;
		font-weight: 500;
		line-height: normal;

		span {
			color: var(--text-secondary);
			font-weight: 400;
		}
	}
}
