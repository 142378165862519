.login-form {
	width: 100%;
	max-width: 400px;
	gap: 30px;
	display: flex;
	flex-direction: column;

	&--w-540 {
		max-width: 540px;
	}

	&--w-580 {
		max-width: 580px;
	}

	&__title {
		line-height: 1;
		text-align: center;

		p {
			font-weight: 600;
			font-size: 38px;
			color: var(--gray800);
			text-align: center;
			line-height: 100%; /* 38px */

			&.login-form__subtitle {
				color: var(--greyscale-grey-600);
				font-size: 16px;
				font-style: normal;
				font-weight: 400;
				line-height: 160%; /* 25.6px */
				margin-top: 16px;
			}
		}
	}
}
