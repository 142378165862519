.trade-size {

	&:hover,
	&.active {

		.trade-size__drop {
			display: block;
		}
	}

	svg {
		cursor: pointer;
	}

	&__drop {
		display: block;
	}
}
