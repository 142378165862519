.input {
	.input-notify__text {
		font-weight: 400;
		font-size: 12px;
		line-height: 180%;
		color: var(--ui-error-color);
		margin-left: 3px;
	}

	.input-notify__char {
		display: none;
	}

	&.input--error .input-item {
		border-width: 1px;
	}

	&.input--success .input-item {
		border: 1px solid var(--border-main-color);
	}
}

//Old

.login-section {
	/*.input--success .input-item {
		border: none;
	}*/

	.input__notification--ta-r {
		margin: 10px;
	}

	.input-notify__char {
		display: none;
	}

	.authorization {
		position: relative;
		width: 100%;
		max-width: 421px;
		margin: auto;
		padding: 25px 0 40px;
	}

	.show-pass {
		top: 24px;
	}

	.input--error .input-item {
		border-width: 1px;
	}

	.grecaptcha-badge {
		display: none;
	}

	.additional {
		position: relative;
		min-height: 327px;

		&__icon {
			width: 60px;
		}
	}

	.input-notify {
		&__text {
			color: var(--ui-error-color);
		}
	}

	.google-2fa-button {
		font-weight: normal;
		font-size: 16px;
		line-height: 1.3;
		color: #777e90;
		display: flex;
		align-items: center;
		justify-content: flex-start;
	}

	.google-2fa-input {
		padding-top: 10px;
	}

	.google-2fa-icon {
		display: inline-block;
		width: 20px;
		margin-left: 5px;
		transition: all 0.3s linear;

		&--rotate {
			transform: rotate(180deg);
			transition: all 0.3s linear;
		}
	}

	.popup-window {
		&__close {
			position: absolute;
			width: 100%;
			height: 100%;
			top: 0;
			left: 0;
		}
	}

	.captcha-modal {
		z-index: 1;
	}

	.additional__title.email-confirm-check {
		font-size: 30px;
	}
}

.input-notification__link {
	width: 100%;
	font-size: inherit;
}

.login-form__text {
	font-size: 12px;
	text-align: center;
	color: var(--text-color2);
}
