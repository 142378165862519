.beneficiaries {
	.tabs-buttons {
		margin-top: 20px;
		margin-bottom: 10px;
	}
	.td-name {

	}
	.table--type2, .table-header, .tr, .td-name {
		p {
			font-weight: 400 !important;
		}
	}
	.table-body {
		p {
			margin-left: 5px;
			//styleName: Body/Body3 14 Medium;
			font-family: Euclid Circular A;
			font-size: 14px;
			font-weight: 500 !important;
			line-height: 22px;
			letter-spacing: 0px;
			text-align: left;

		}
		span {
			margin-left: 5px;
		}
	}
	.input-item--type-td {
		max-width: 300px;
	}
	.table--type2 .tr .td:first-child  {
		height: 100%;
		display: flex;
		align-items: center;
	}
	.table--type2 .tr .td {
		height: 100%;
		display: flex;
		align-items: center;
	}
	.td-colum {
		display: flex;
		flex-direction: column;
		align-items: flex-start !important;
		justify-content: center;
	}
	.td--right {
		display: flex;
		justify-content: right;
	}
}
