.dropdown_wrapper {
	display: flex;
	flex-direction: row;
	justify-content: space-around;
	align-items: center;
	padding: 0px;
	gap: 16px;

	width: 196px;
	height: 40px;
}

.select_pagination {
	display: flex;
	flex-direction: row;
	justify-content: space-around;
	align-items: center;
	gap: 10px;
	padding-left: 5px;
	padding-right: 5px;
	width: 89px;
	height: 40px;
}

.dropdown-title {
	font-family: 'Roboto', 'sans-serif';
	font-style: normal;
	font-weight: 400;
	font-size: 13px;
	line-height: 180%;

	display: flex;
	align-items: center;

	color: var(--text-color-main);
}

.select_drop--pagination {
	top: -220px !important;
}
