.header {
	@media only screen and (max-width: 991px) {
		top: 0;
		z-index: 100;
		position: fixed;
		width: 100vw;
	}
}
.main-section {
	.sidebar .sidebar-nav__list a {
		border-radius: 0;
	}
	@media only screen and (max-width: 991px) {
		padding-top: 82px;

		.sidebar {
			display: block;
			width: unset !important;
			min-width: 55px;
			height: calc(100vh - 82px);

			position: fixed;
			left: 0;
			z-index: 99;

			transform: translate(-100%, 0);
			transition: all .5s ease;
		}

		.sidebar.active {
			transform: translate(0);
			transition: all .5s ease;
		}
	}
}
