.title {
	font-size: 30px !important;
	line-height: 1.367 !important;
}

.text--center {
	text-align: center;
}

.copy-button__text.copy-button__text--max-width {
	width: unset;
	max-width: 90px;
}

.popup-submit--gap {
	gap: 20px;
}

.selectedCurrency {
	.select-block {
		display: flex;
		align-items: center;
		.select-block__name {
			margin-top: 9px;
			font-size: 14px;
			margin-right: 30px;
		}
	}
	.select {
		width: 130px;
	}
	width: 100%;
	.form {
		width: 100%;
	}
}
.selectedCurrency {
	.select {
		position: relative;
	}
}
.balance-info-schedule {
	font-size: 13px;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}
.table--referral.one--user .tr {
	grid-template-columns: 1fr 1.88fr 1.72fr 1.7fr 1.7fr 1fr 1fr;
}
//.table-wrapper {
//	overflow: visible !important;
//}

.text--capitalize {
	text-transform: capitalize;
}

.react-datepicker {
	display: flex !important;
}

.coin.coin--type3 {
	align-items: center;
	.coin__text {
		padding-top: 2px;
	}
}

.fiat-fiat-trade {
	p {
		//styleName: Heading/H2;
		font-family: Euclid Circular A;
		font-size: 24px;
		font-weight: 600;
		line-height: 30.43px;
		text-align: left;
		color: #141414;
		margin-bottom: 24px;
		margin-top: 24px;
	}
}
.fiat-fiat-td {
	 .td-right {
		 .td-name {
			 display: flex;
			 justify-content: flex-end;
			 text-align: right;
		 }

	}
}
