.form textarea.input-item {
	padding: 12px 16px;
	min-height: 96px;
	resize: vertical;
}

.inputs {
	.input--success .input-item {
		border: none;
	}

	.input__notification--ta-r {
		margin: 10px;
	}

	.input-notify__text {
		font-weight: 400;
		font-size: 12px;
		line-height: 180%;
		color: var(--ui-error-color);
		margin-left: 3px;
	}

	.input-notify__char {
		display: none;
	}

	.authorization {
		position: relative;
		width: 100%;
		max-width: 421px;
		margin: auto;
		padding: 25px 0 40px;

		.show-pass {
			top: 24px;
			transform: inherit;
		}
	}

	.show-pass {
		top: 25px;
	}

	.input--error .input-item {
		border-width: 1px;
	}

	.grecaptcha-badge {
		display: none;
	}

	.additional {
		position: relative;
		min-height: 327px;

		&__icon {
			width: 60px;
		}
	}

	.input-notify {
		&__text {
			color: var(--ui-error-color);
		}
	}

	.google-2fa-button {
		font-weight: normal;
		font-size: 16px;
		line-height: 1.3;
		color: #777e90;
		display: flex;
		align-items: center;
		justify-content: flex-start;
	}

	.google-2fa-input {
		padding-top: 10px;
	}

	.google-2fa-icon {
		display: inline-block;
		width: 20px;
		margin-left: 5px;
		transition: all 0.3s linear;

		&--rotate {
			transform: rotate(180deg);
			transition: all 0.3s linear;
		}
	}

	.popup-window {
		&__close {
			position: absolute;
			width: 100%;
			height: 100%;
			top: 0;
			left: 0;
		}
	}

	.captcha-modal {
		z-index: 1;
	}

	.additional__title.email-confirm-check {
		font-size: 30px;
	}
}

.select__drop ul > li > button {
	white-space: nowrap;
}

.phone-number-input {
	.select__drop {
		width: auto;
	}
}

.input-item.input-item---fullWidth {
	width: 100%;
}

.textarea.textarea---height-100 {
	height: 100px;
}

.input-item--type-td {
	&.syze__lg {
		width: 105px;
	}
}

.number__input,
.table-block {
	input::-webkit-outer-spin-button,
	input::-webkit-inner-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}

	input[type='number'] {
		-moz-appearance: textfield;
	}
}

.react-datepicker__header:not(.react-datepicker__header--has-time-select) {
	border: none;
}

.react-datepicker .react-datepicker__navigation--previous {
	top: 0;
	left: 18px;
}

.react-datepicker .react-datepicker__navigation--next {
	top: 0;
	right: 18px;
}

.react-datepicker .react-datepicker__month {
	width: 216px;
}

.select.select--date {
	.select__current-date-icon {
		color: #777e90;
		margin-left: 12px;
	}

	.select__drop {
		width: auto;
		height: auto;
	}

	.react-datepicker {
		border: none;
		box-shadow: none;
	}
}

.select-page.select-page--drop-top {
	.select__drop {
		top: unset;
		left: 0;
		bottom: calc(100% + 10px);
	}
}

.input-wrapper.input-wrapper--datepicker {
	position: relative;

	.react-datepicker-popper {
		z-index: 10;
	}
}

.select-block .select.select--add-fiat-transaction {
	border-radius: 8px;
	position: relative;

	.select__current {
		justify-content: flex-end;
	}

	.select-input {
		border: none;
		position: absolute;
		height: 100%;
		width: 100%;
		left: 0;
		top: 0;
		padding-left: 15px;
		border-radius: 8px;
		pointer-events: none;

		&::placeholder {
			color: #bbbec7;
		}
	}

	.select__current-arrow {
		position: relative;
	}
}

.input.input--user-search {
	position: relative;

	.user-search__dropdown {
		display: none;
		position: absolute;
		width: 100%;
		padding: 12px 8px;
		top: calc(100% + 10px);
		right: -1px;
		left: auto;
		border: none;
		box-shadow: 0 0 2px rgb(40 41 61 / 4%), 0 4px 8px rgb(96 97 112 / 16%);
		border-radius: 8px;
		background: #ffffff;
		z-index: 10;

		&.active {
			display: block;
		}
	}

	.user-search__dropdown-item {
		font-size: 12px;
		color: #173b58;
		cursor: pointer;
		padding: 12px 8px;
		border-radius: 8px;

		&:hover {
			background: #f5f5f5;
		}
	}

	.dropdown-item__block {
		display: flex;
		align-items: center;
		gap: 8px;
	}

	.dropdown-item__key {
		font-weight: 600;
		position: relative;

		&:after {
			content: ':';
		}
	}
}

.select--bank-accounts {
	.select__drop {
		max-width: 100%;
	}

	.bank-account__item {
		display: flex;
		align-items: flex-start;
		justify-content: space-between;
	}

	.bank-account__name,
	.bank-account__balance {
		color: #173b58;
		font-size: 14px;
		font-weight: 400;
	}

	.bank-account__balance-title {
		color: #777e90;
		font-size: 12px;
		font-weight: 400;
		margin-bottom: 5px;
	}

	&.disabled {
		pointer-events: none;

		.select-input {
			background: #fafafb;
		}
	}
}

.input-line .input-wrapper--datepicker .input-icon {
	pointer-events: none;
}

.input.input-item--auto-calc {
	pointer-events: none;
	.input-item {
		background: #fafafb;
	}
}

.select.select--range {
	.select__drop {
		padding: 16px;
		min-width: 268px;
	}
	.form__range-inputs {
		display: flex;
		gap: 8px;
		margin-bottom: 16px;
	}
	.select__current-arrow {
		flex: unset;
		max-width: unset;
		margin-left: 8px;
	}
	.input-item {
		height: 40px;
	}
}

.input-notification--after {
	margin-bottom: 15px;
	margin-top: 0;
}

.phone-number-input {
	display: grid;
	grid-template-columns: 110px 237px;
}

.select .select-value,
.select__current {
	overflow: hidden;
}

.select.disabled {
	background: #fafafb;
	pointer-events: none;
}

