.popup-overlay {
	position: fixed;
	display: block;
	width: 100%;
	height: 100%;
	left: 0;
	top: 0;
	overflow: auto;
	z-index: 201;
	background: rgba(119, 126, 144, 0.3);
	backdrop-filter: blur(10px);
	transition: 1s;
}
.popup-content {
	animation: 1s cubic-bezier(0.38, 0.1, 0.36, 0.9) forwards a;
}

.popup-img {
	&.block,
	&.archive {
		path {
			stroke: white;
		}
	}
	&.unblock {
		path {
			stroke: white;
		}
	}
}

.capitalize {
	text-transform: capitalize;
}

