.table--required-emails {
	.tr {
		align-items: center;
		-ms-grid-columns: 5fr 3.22fr 2.17fr 1fr;
		grid-template-columns: 5fr 3.22fr 2.17fr 1fr;
		.td .td-name {
			display: flex;
			flex-direction: column;
		}
	}
}

.flex--column {
	-ms-flex-direction: column;
	flex-direction: column;
	gap: 12px;
}

.td--right {
	text-align: right;
}

.flex.flex--column--end {
	align-items: flex-end;
}

.table--required-emails-user .tr {
	-ms-flex-align: center;
	align-items: center;
	-ms-grid-columns: 4fr 2fr 1fr;
	grid-template-columns: 4fr 2fr 1fr;
}

.table--required-emails-user.table--required-emails-user--grid .tr {
	-ms-flex-align: center;
	align-items: center;
	-ms-grid-columns: 4fr 2fr 1fr;
	grid-template-columns: 4fr 2fr 1fr;
}

