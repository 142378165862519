.table-users {
	padding: 8px 16px;
	display: flex;
	gap: 5px;
	color: var(--greyscale-grey-500);
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 160%; /* 22.4px */

	&__total {
		color: var(--greyscale-grey-800);
		font-weight: 500;
	}
}
