.table-panel {
	display: flex;
	align-items: stretch;
	justify-content: space-between;
	gap: 0;

	@media only screen and (max-width: 1023.98px) {
		flex-direction: column;
		align-items: flex-start;
		gap: 20px;
	}

	.currencyButton {
		border-radius: 12px;
	}
}
