.table-filter {
	border-radius: 14px;
	border: 1px solid var(--greyscale-grey-100);
	background: var(--additional-white);
	min-width: 100%;
	min-height: 64px;
	padding: 10px;
	justify-content: space-between;
	margin-bottom: 28px;

	&.table-filter--reset-request {
		margin: 10px 0 0;
		padding: 10px 16px;
		display: flex;
		align-items: center;
		justify-content: space-between;

		.input-wrapper input {
			width: 200px;
		}

		.filter {
			margin: 0;
		}

		.filter__wrap {
			display: flex;
			align-items: center;
			gap: 40px;
		}

		.filter__total {
			color: var(--greyscale-grey-800);
			font-size: 16px;
			font-style: normal;
			font-weight: 500;
			line-height: 150%; /* 24px */
		}
		.filter__status {
			display: flex;
			flex-direction: row;
			align-items: center;
			border-radius: 6px;
			background: var(--greyscale-grey-10);
			gap: 10px;
			height: 38px;
			padding: 6px 10px;
			p {
				color: var(--primary-blue-600);
				font-size: 14px;
			}
			.select {
				width: 130px;
				border-radius: 6px;
				padding: 0;
				align-items: center;
				height: 28px;
				background: var(--additional-white);
				.select__current {
					justify-content: space-between;
					padding: 0 10px;
					color: var(--greyscale-grey-800);
					font-size: 14px;
					font-weight: 500;
				}
				.select__drop {
					padding: 0;

					ul > li > button {
						padding: 5px 10px;
						border-radius: 4px;
						color: var(--greyscale-grey-500);
						font-size: 14px;
						font-style: normal;
						font-weight: 400;
						line-height: 160%;
						background: transparent;

						&:hover {
							background: var(--primary-blue-50);
							color: var(--greyscale-grey-800);
							font-weight: 500;
						}
					}
				}
			}
		}
	}

	.filter {
		background: #fafafa;
		padding: 5px 10px;
		border-radius: 8px;

		.select--type4 {
			height: 28px;
			border-radius: 6px;
			background: var(--additional-white) !important;
			border: 1px solid var(--additional-white);
			position: relative;
			transition: border-color var(--transition-base);

			&.active {
				border-color: var(--primary-blue-200);
			}

			.select__drop {
				display: block;
				padding: 2px;

				ul > li > button {
					padding: 5px 10px;
					border-radius: 4px;
					color: var(--greyscale-grey-500);
					font-size: 14px;
					font-style: normal;
					font-weight: 400;
					line-height: 160%;
					background: transparent;

					&:hover {
						background: var(--primary-blue-50);
						color: var(--greyscale-grey-800);
						font-weight: 500;
					}
				}
			}
		}

		.search-input {
			height: 30px;
			display: flex;
			align-items: center;

			.input--mb-0 {
				margin-left: 10px;
				margin-bottom: 0 !important;
			}

			.select__current {
				padding-left: 10px;
				padding-right: 10px;
				min-width: 120px;
				font-size: 14px;
				font-weight: 500;
				line-height: 18px;
				letter-spacing: 0;
				text-align: left;
				white-space: nowrap;
			}
		}
	}

	.select {
		&.active {
		}
	}

	.input-wrapper {
		input {
			height: 28px;
			border: 1px solid var(--additional-white);
			font-size: 12px;
			color: black;
			width: 270px;
			padding: 0 10px;
			border-radius: 6px;
			background: var(--additional-white);
			transition: border-color var(--transition-base);

			&::placeholder {
				font-size: 12px;
				color: var(--greyscale-grey-200);
				font-weight: 300;
				line-height: normal;
				letter-spacing: 0;
				text-align: left;
			}

			&:focus {
				border-color: var(--primary-blue-200);
			}
		}
	}

	.selectedCurrency {
		.select-block {
			&__name {
				margin: 0 10px 0 0;
				color: var(--primary-blue-600);
				font-size: 14px;
				font-style: normal;
				font-weight: 400;
				line-height: normal;
			}
		}

		.select {
			width: 164px;
			height: 28px;
			border-radius: 6px;
			background: var(--additional-white);
			border-color: var(--additional-white);
			transition: border-color var(--transition-base);

			&.active {
				border-color: var(--blue200);
			}
		}

		.select__drop {
			display: block;
			padding: 2px;
			border-radius: 6px;
			border: 1px solid var(--blue200);
			background: var(--additional-white);
			box-shadow: 0 4px 15px 0 rgba(33, 44, 99, 0.09);
			overflow: hidden;
			top: calc(100% + 5px);

			ul > li > button {
				padding: 5px 10px;
				color: var(--greyscale-grey-500);
				font-size: 14px;
				font-style: normal;
				font-weight: 400;
				line-height: 160%;
				min-height: 32px;
				display: flex;
				align-items: center;
				flex-direction: row;
				grid-gap: 8px;
				gap: 8px;
				border-radius: 4px;

				&:hover {
					background: var(--primary-blue-50);
					color: var(--greyscale-grey-800);
					font-weight: 500;
				}

				img {
					width: 18px;
					min-width: 18px;
					height: 18px;
					min-height: 18px;
				}
			}
		}

		.select__current {
			padding: 0 10px;
		}

		.select__current-text {
			display: flex;
			align-items: center;
			flex-direction: row;
			gap: 8px;

			img {
				width: 18px;
				min-width: 18px;
				height: 18px;
				min-height: 18px;
			}

			p {
				color: var(--gray800);
				font-size: 14px;
				font-style: normal;
				font-weight: 500;
				line-height: normal;
			}
		}
	}
}

@media only screen and (max-width: 768px) {
	.title-block-button {
		margin: 5px !important;
	}
}
@media only screen and (max-width: 498px) {
	.search-input {
		display: flex;
		flex-direction: column;
	}
	.table-filter .filter {
		padding: 35px 10px;
	}
	.input--mb-0 {
		margin-top: 10px;
	}
}
.search-input-wrap {
	display: flex;
	align-items: center;
}
.search-input-label {
	font-family: Euclid Circular A;
	font-weight: 400;
	font-size: 14px;
	line-height: 17.75px;
	letter-spacing: 0px;
	color: rgba(33, 44, 99, 1);
	margin-right: 10px;

}
