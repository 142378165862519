.react-datepicker {
	padding: 8px;
	&__navigation-icon--previous,
	&__navigation-icon--next {
		background: none;
		left: 0;
	}
	&__navigation--next {
		right: 0px;
	}
	&__navigation--previous {
		left: 0px;
	}
	&__header {
		button {
			border-radius: 6px;
			background: var(--primary-blue-400);
			padding: 6px;
			width: 28px;
			height: 28px;

			span {
				background: var(--primary-blue-400);
				left: 0px;
			}
		}
	}
	&__time-list {
		&::-webkit-scrollbar {
			width: 4px;
		}
		&::-webkit-scrollbar-thumb {
			border-radius: 2px;
			background-color: #b6b6b6;
		}
		&::-webkit-scrollbar-track {
			border-radius: 2px;
			margin: 10px 5px 10px 5px;
			background: #eeeeee;
		}
	}

	&__day-names {
		display: flex;
		justify-content: space-between;
	}
	&__month {
		margin: 14px 0px 0px 0px;
		width: 100%;
	}
	&__day {
		border-radius: 4px;
		padding: 4px;
		width: 28px;
		font-size: 12px;
		color: var(--primary-blue-400);
		background: var(--primary-blue-50);
		&--outside-month {
			background: var(--greyscale-grey-10);
			color: var(--greyscale-grey-500);

			&:hover {
				background: var(--greyscale-grey-10);
				color: var(--greyscale-grey-500);
			}
		}
	}

	&__day--selected {
		background: var(--blue600) !important;
		color: var(--additional-white);
		border-radius: 4px !important;
		font-weight: 500 !important;
	}

	&__current-month {
		font-size: 14px;
	}
	&__day {
		&:hover {
			border-radius: 4px;
			background: var(--blue400) !important;
			color: var(--additional-white);
		}
	}

	&__input-container input {
		font-weight: 500;
		font-size: 14px;
	}
	&__day--in-selecting-range {
		background-color: rgba(33, 107, 165, 0.5) !important;
	}
	&__day--in-range {
		border-radius: 4px !important;
		background: var(--blue600) !important;
		color: var(--additional-white) !important;
		font-weight: 500;
		&::before {
			background: none;
		}
	}
	&__selected-range {
	}
}
