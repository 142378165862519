.wallet-operations-header-wrap {
	margin-bottom: 20px;
	display: flex;
	align-items: center;
	justify-content: space-between;

	.wallet-operations-header {
		margin-bottom: 0;
		color: var(--gray800);
		font-size: 20px;
		font-style: normal;
		font-weight: 700;
		line-height: normal;
	}
}
