.login-section {
	display: block;
	padding: 40px 0;
	background: var(--gray300);

	.container {
		padding: 0 15px;
		width: 100%;
		display: flex;
		align-items: stretch;
		gap: 15px;

		@media only screen and (min-width: 1024px) {
			padding: 0 40px;
			gap: 40px;
		}
	}

	.input__notification--ta-r {
		margin: 5px 0 0 0;
		text-align: left;
	}
}
