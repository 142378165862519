.deposit-accounts {
	&__title {
		color: var(--gray800);
		font-size: 38px;
		font-style: normal;
		font-weight: 600;
		line-height: normal;
	}
}

