.MultiSelect {
	position: relative;
	display: flex;
	align-items: center;
	width: 225px;
	background: #fafafa;
	border-radius: 8px;
	padding: 8px;

	&__label {
		margin-right: 7px;
	}

	&__selected {
		position: relative;
		width: 100%;
		// Добавляем отступ справа, чтобы освободить место под стрелку
		padding: 3px 30px 3px 15px;

		background: #fff;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		cursor: pointer;

		// Стрелка
		.select__current-arrow {
			position: absolute;
			top: 50%;
			right: 8px;
			transform: translateY(-50%);
			transition: transform 0.2s ease;
			display: inline-flex;
			// Если хотите «придерживать» ширину под иконку, можно задать ей ширину/высоту
		}
	}

	&__selected--open {
		.select__current-arrow {
			transform: translateY(-50%) rotate(180deg);
		}
	}

	&__dropdown {
		position: absolute;
		top: 100%;
		left: 0;
		width: 100%;
		border: 1px solid #ccc;
		background-color: #fff;
		padding: 8px;
		z-index: 999;
	}

	&__item {
		margin-bottom: 4px;
	}
}
