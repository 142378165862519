.user-management-body {
	padding: 0;
	display: flex;
	flex-direction: column;
	gap: 18px;

	@media screen and (max-width: 767px) {
		padding: 18px 12px;
	}

	&--type2 {
		display: block;

		@media screen and (max-width: 767px) {
			padding: 24px 12px;
		}
	}
}

.user-management {
	&__heading {
		color: var(--greyscale-grey-800);
		font-size: 24px;
		font-style: normal;
		font-weight: 600;
		line-height: normal;
	}

	.table-body {
		min-height: 200px;
		//border-bottom: 1px solid #e6e8ec;

		.tr {
			min-height: 58px;

			.td {
				padding: 0 15px;

				p {
					font-family: var(--font-sans);
					font-size: 14px;
					font-weight: 400;
					line-height: 22px;
					letter-spacing: 0;
					text-align: left;
					color: var(--greyscale-grey-800);
				}

				a {
					text-decoration: underline;
					font-family: var(--font-sans);
					font-size: 14px;
					font-weight: 400;
					line-height: 22px;
					letter-spacing: 0;
					text-align: left;
					color: rgba(0, 113, 171, 1);
				}
			}
		}
	}

	.select--table {
		background: var(--gray300);
		padding: 4px 8px;
		border-radius: 6px;
		width: 100%;
		color: var(--gray600);

		button {
			font-family: var(--font-sans);
			font-size: 14px;
			font-weight: 400 !important;
			line-height: 22px;
			letter-spacing: 0;
			text-align: left;
			color: rgba(99, 99, 99, 1);
		}
	}

	.table-buttons {
		button {
			display: flex;
			align-items: center !important;
			justify-content: center !important;
		}

		.unblock {
			border-radius: 6px;
			border: 1px solid var(--additional-green);
			background: var(--transparent-green);
			box-shadow: 0 2px 6px 0 rgba(33, 44, 99, 0.09);
			width: 38px;
			min-width: 38px;
			height: 38px;
			min-height: 38px;
			padding: 0;
		}

		.block {
			border-radius: 6px;
			border: 1px solid var(--blue200);
			background: var(--blue100);
			box-shadow: 0 2px 6px 0 rgba(33, 44, 99, 0.09);
			width: 38px;
			min-width: 38px;
			height: 38px;
			min-height: 38px;
			padding: 0;
		}

		.trash {
			border-radius: 6px;
			border: 1px solid var(--additional-error);
			background: var(--transparent-red);
			box-shadow: 0 2px 6px 0 rgba(33, 44, 99, 0.09);
			width: 38px;
			min-width: 38px;
			height: 38px;
			min-height: 38px;
			padding: 0;
		}
	}

	.table-pagination {
		.select--per-page-wrapper {
			gap: 16px;
			display: flex;
			align-items: center;
			font-size: 14px;
			color: var(--blue600);

			.select {
				width: 80px;
				height: 32px;
				border-radius: 6px;
				border: 1px solid var(--greyscale-grey-100);
				background: var(--greyscale-grey-10);

				&.active {
					border: 1px solid var(--primary-blue-200);
					background: var(--additional-white);
				}

				button {
					font-weight: 500;
					font-size: 14px;
					color: var(--greyscale-grey-600);
				}

				.select__drop {
					top: unset;
					bottom: calc(100% + 5px);
				}
			}
		}
	}

	.trade-history-wrapper .active-trade_filters {
		margin-top: 20px;
	}
	.transfer-history-wrapper .active-trade_filters {
		justify-content: flex-start;
		.reset_trade-filters {
			margin-left: auto;
		}
	}
	.select-block__name {
		color: var(--gray600);
	}
	.error-red span {
		color: var(--additional-error);
		font-weight: 400;
	}
	.error-red {
		color: var(--additional-error);
	}
}

.user-management,
.main-content {
	.react-datepicker {
		padding: 8px;

		&__navigation-icon--previous,
		&__navigation-icon--next {
			background: none;
			left: 0;
		}

		&__navigation--next {
			right: 0;
			top: -5px;
		}

		&__navigation--previous {
			left: 0;
			top: -5px;
		}

		&__selected-range {
			display: flex;
			justify-content: space-around;
			color: var(--greyscale-grey-800);
			font-size: 12px;
			font-weight: 500;
			margin-bottom: 16px;

			p {
				color: var(--greyscale-grey-200);
				font-size: 12px;
				font-style: normal;
				font-weight: 300;
			}

			svg {
				padding: 6px;
			}

			.selected-range-input {
				border-radius: 6px;
				width: 100px;
				background: var(--greyscale-grey-10);
				padding: 6px;
				display: flex;
				justify-content: center;
			}
		}

		&__day-names {
			display: flex;
			justify-content: space-between;
		}
		&__month {
			margin: 14px 0 0 0;
			width: 100%;
		}
		&__day {
			&:hover {
				border-radius: 4px;
				background: var(--blue400) !important;
				color: var(--additional-white);
			}
		}
		&__day--in-range {
			border-radius: 4px !important;
			background: var(--blue600) !important;
			color: var(--additional-white) !important;
			font-weight: 500;
			&::before {
				background: none;
			}
		}
		&__day--keyboard-selected {
			color: var(--primary-blue-400);
			border-radius: 4px !important;
			font-weight: 500;
		}
		&__current-month {
			font-size: 14px;
		}
		&__day--range-end {
			background: var(--blue600) !important;
			color: var(--additional-white) !important;

			&.react-datepicker__day--in-range {
				border-radius: 4px !important;
			}
		}
		&__day--in-selecting-range {
			background-color: rgba(33, 107, 165, 0.5) !important;
		}
	}
}

.table.table--user-management {

	.tr {
		grid-template-columns: 1fr 1.2fr 1.3fr 1fr 1.6fr 1.6fr 1.5fr 1.2fr 1.2fr;

		.select {
			height: 31.19px;
		}
	}

	.td {
		padding-left: 3px;
		padding-right: 3px;

		&:first-child {
		}

		&:last-child {
		}
	}

	.table-body {

		.td {

			p {
				padding: 8px 0;
			}
		}
	}
}

@media only screen and (max-width: 1200px) {
	.user-management {

		.tr {
			height: 100% !important;
		}

		.td {
			padding: 5px !important;
		}

		.td-hidden-name {
			color: black !important;
			font-weight: 600 !important;
		}
	}
}

.user-management-wrap {
	margin-top: 40px;
	border: none;
	border-radius: 8px;
	text-align: left;

	&--create-account {
		border-radius: 14px;
		border: 1px solid var(--greyscale-grey-100);
		background: var(--additional-white);
		padding: 24px;
		margin: 0;
	}
}

