.pagination-block {
	padding: 0 10px;
	color: var(--greyscale-grey-600);
	font-size: 14px;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
	margin-bottom: 24px;

	.select--per-page-wrapper {
		display: flex;
		flex-direction: row;
		justify-content: space-around;
		align-items: center;
		gap: 16px;
		width: auto;
		height: auto;

		font-size: 14px;
		font-weight: 400;
		line-height: 18px;
		letter-spacing: 0;
		text-align: left;
		color: var(--primary-blue-600);
		font-family: var(--font-sans);

		.select .select__drop {
			display: block;
			bottom: calc(100% + 5px) !important;
			top: auto !important;
		}
	}

	.dropdown_wrapper {
		gap: 16px;
		width: auto;
		height: auto;

		.select .select__drop {
			display: block;
		}
	}

	.select,
	.select_pagination {
		width: 80px;
		height: 32px;
		border-radius: 6px;
		border: 1px solid var(--greyscale-grey-100);
		background: var(--greyscale-grey-10);
		padding: 0;

		button {
			height: 100%;
			padding: 0 10px;
			color: var(--greyscale-grey-600);
			font-size: 14px;
			font-style: normal;
			font-weight: 500;
			line-height: normal;
		}
	}

	.select_drop--pagination {
		bottom: calc(100% + 5px) !important;
		top: auto !important;
	}

	.dropdown-title {
		font-size: 14px;
		font-weight: 400;
		line-height: 18px;
		letter-spacing: 0;
		text-align: left;
		color: var(--primary-blue-600);
		font-family: var(--font-sans);
	}

	.select__drop,
	.select_drop--pagination {
		padding: 0;

		ul {
			li {
				button {
					padding: 5px 10px;
					border-radius: 4px;
					color: var(--greyscale-grey-500);
					font-size: 14px;
					font-style: normal;
					font-weight: 400;
					line-height: 160%;
					background: transparent;

					&:hover,
					&.active {
						background: var(--primary-blue-50);
						color: var(--greyscale-grey-800);
						font-weight: 500;
					}
				}
			}
		}
	}
}

