.account-header {
	.back-btn {
		min-width: 38px;
		width: 38px;
		height: 38px;
		display: flex;
		align-items: center;
		justify-content: center;
		border-radius: 6px;
		border: 1px solid var(--blue200);
		background: var(--blue100);
		box-shadow: 0 2px 6px 0 rgba(33, 44, 99, 0.09);
		background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M9 19L2 12L9 5L10.05 6.05L4.85 11.25H22V12.75H4.85L10.05 17.95L9 19Z' fill='%230071AB'/%3E%3C/svg%3E%0A");
		background-size: 24px 24px;
		background-repeat: no-repeat;
		background-position: center;
	}
	.title-block-wrap {
		display: flex;
		flex-direction: column;

		.title__user-id {
			width: 97px;
			height: 24px;
			padding: 6px;
			border-radius: 20px;
			border: 0.8px solid rgba(0, 113, 171, 1);
			background: rgba(241, 247, 252, 1);

			font-family: Euclid Circular A;
			font-size: 12px;
			font-weight: 400;
			line-height: 15px;
			letter-spacing: 0px;
			text-align: center;
			color: rgba(0, 113, 171, 1);
			display: flex;
			align-items: center;
			justify-content: center;
		}

		.title,
		.title--type2 {
			font-family: 'Euclid Circular A', sans-serif;
			font-size: 24px !important;
			font-weight: 600;
			line-height: 30px;
			letter-spacing: 0px;
			text-align: left;
		}

		.typeTitle {
			font-family: 'Euclid Circular A', sans-serif !important;
			font-size: 38px !important;
			font-weight: 600 !important;
			line-height: 38px !important;
			letter-spacing: 0em !important; /* '0em' is equivalent to '0', so you could also just use '0' here */
			text-align: left !important;
			margin-bottom: 23px;
		}

		.title__info {
			font-family: 'Euclid Circular A', sans-serif !important;
			font-size: 16px !important;
			font-weight: 400 !important;
			line-height: 26px !important;
			letter-spacing: 0px !important;
			text-align: left !important;
			top: calc(100% + 0px) !important;
		}
	}
	&__actions {
		display: flex;
		gap: 10px;
		margin-bottom: 10px;
		margin-top: 30px;
	}
}

