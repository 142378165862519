.table--associated-accounts_main__newRequests {
	.td__wrap {
		position: relative;
	}
	.select__current {
		top: -21px;
		left: -31px;
		width: 120px;
	}
}
.select__drop__portal {
	top:70px;
	left:-50px;
	.button-portal {
		font-size: 14px;
	}
	.select__drop__portal {
		display: block;
		opacity: 1;
		transition: opacity 0.3s;
		border-radius: 6px;
	}

	.select__drop-scroll {
		max-height: 150px;
		overflow-y: auto;
		padding: 4px;
	}
	.select__drop-item ul {
		list-style: none;
		margin: 0;
		padding: 0;
	}

	.select__drop-item li {
		margin: 4px 0;
	}
	.button-portal {
		width: 100%;
		padding: 8px 16px;
		border-radius: 4px;
		text-align: left;
		cursor: pointer;
		transition: background-color 0.3s, color 0.3s;
		font-size: 14px;
	}

	.button-portal:hover {
		background-color: #eaeaea;
		color: #000;
	}

	.button-portal:active {
		background-color: #dadada;
	}
	.button-portal:focus {
		outline: none;
		box-shadow: 0 0 0 2px #b3b3b3;
	}
}

.tippy-content_portal {
	background: white;
	border-radius: 6px;
	box-shadow: 0 4px 14px rgba(0, 0, 0, 0.15);
	transition-duration: 300ms;
	font-family: sans-serif;
	color: #333;
	border: 1px solid var(--primary-blue-200);
}
