.progress-wrapper {
	width: 100%;
	padding: 10px;
	display: flex;
	height: 100%;
	align-items: center;
	justify-content: center;
}

.progress-bar-container {
	width: 100%;
	height: 3px;
	border-radius: 8px;
	justify-content: flex-start;
	align-items: center;
	display: flex;
	gap: 10px;
	background: var(--primary-blue-100);
}

.progress-bar {
	height: 100%;
	background: var(--primary-blue-400);
	border-radius: 4px;
	transition: width 0.3s ease;
	text-align: center;
	color: white;
	display: flex;
	align-items: center;
	justify-content: center;
}

.progress-text {
	margin: 0;
	font-size: 12px;
	line-height: 1.2;
	color: var(--greyscale-grey-800);
}

.progress-bar-wrapper {
	width: 100%;
	display: flex;
	align-items: center;
	flex-direction: column;
	gap: 6px;
	justify-content: center;
}

