.wallet-content-balance-item {
	padding: 26px 32px;
	border-radius: 14px;
	width: calc(33.33333333333333% - 20px);
	margin: 0 10px;

	@media only screen and (max-width: 1080px) {
		margin: 10px 0;
		width: 100%;
	}

	&--blue {
		background: #9dc7de;
	}

	&--yellow {
		background: #fff0b1;

		.wallet-content-balance-item__user-currensy {
			background: rgba(255, 255, 255, 0.45);
		}
	}

	&--blue-type2 {
		background: var(--blue200);
	}

	&__title {
		color: var(--gray700);
		font-size: 16px;
		font-style: normal;
		font-weight: 500;
		line-height: normal;
		margin-bottom: 12px;
	}

	&__value {
		margin-bottom: 12px;

		p {
			color: var(--gray800);
			font-size: 24px;
			font-style: normal;
			font-weight: 600;
			line-height: normal;

			span {
				color: var(--gray700);
				font-size: 16px;
				font-style: normal;
				font-weight: 500;
				line-height: 150%;
				margin-left: 8px;
			}
		}
	}

	&__user-currensy {
		border-radius: 4px;
		background: #c9e0ed;
		padding: 4px 8px;
		display: inline-flex;

		p {
			color: var(--gray800);
			font-size: 14px;
			font-style: normal;
			font-weight: 400;
			line-height: normal;
		}
	}
}
