.title {
	position: relative;
	display: flex;
	align-items: center;
	gap: 16px;
	font-size: 38px !important;
	line-height: 100%;
	font-weight: 600;
	@media screen and (max-width: 720px) {
		font-size: 24px;
	}
}

.title-block-wrap {
	display: flex;
	justify-content: space-between;
	gap: 16px;
	@media screen and (max-width: 720px) {
		flex-wrap: wrap;
	}
}

.title-block {
	display: flex;
	align-items: center;
	&--deposit {
		margin-bottom: 20px;
	}
}

.title-message {
	font-style: normal;
	font-weight: 700;
	font-size: 28px;
	line-height: 42px;
	max-width: 75%;
	word-wrap: break-word;
}

.title__message-id {
	max-width: fit-content;
	max-height: 34px;
	padding: 6px;
	border-radius: 20px;
	border: 0.8px solid var(--primary-blue-400);
	background: #f1f7fc;
	font-size: 12px;
	font-weight: 400;
	line-height: 15px;
	letter-spacing: 0px;
	text-align: center;
	color: var(--primary-blue-400);
	display: flex;
	align-items: center;
	justify-content: center;
}

.title__user-id {
	&--container {
		display: flex;
		flex-direction: row;
		gap: 8px;
		flex-wrap: wrap;
		width: 100%;
	}
	&--wrapper {
		width: 70px;
		height: 24px;
		padding: 6px;
		border-radius: 20px;
		border: 0.8px solid var(--primary-blue-400);
		font-size: 12px;
		font-weight: 400;
		line-height: 15px;
		letter-spacing: 0px;
		text-align: center;
		color: var(--primary-blue-400);
		display: flex;
		align-items: center;
		justify-content: flex-end;
		gap: 16px;
		svg {
			cursor: pointer;
			margin-bottom: 2px;
		}
	}
	.input-item {
		flex: 1 1 auto;
	}
	&--more {
		width: 70px;
		height: 24px;
		padding: 6px;
		border-radius: 20px;
		border: 0.8px solid var(--primary-blue-400);
		font-size: 12px;
		font-weight: 400;
		line-height: 15px;
		letter-spacing: 0px;
		text-align: center;
		color: var(--primary-blue-400);
		display: flex;
		align-items: center;
		justify-content: center;
		gap: 2px;
		cursor: pointer;
		p {
			font-size: 14px;
			margin-bottom: 2px;
		}
	}
}

