.tabs-account {
	display: flex;
	gap: 8px;
	flex-flow: row wrap;

	a {
		color: var(--greyscale-grey-500);
		font-size: 14px;
		font-style: normal;
		font-weight: 400;
		line-height: 160%; /* 22.4px */
		border-radius: 30px;
		border: 1px solid var(--greyscale-grey-300);
		background: var(--additional-white);
		padding: 3px 11px;
		min-height: 30px;
		display: inline-flex;
		align-items: center;
		justify-content: center;

		&:hover,
		&.active {
			border: 1px solid var(--primary-blue-600);
			background: var(--primary-blue-600);
			color: var(--additional-white);
			font-weight: 600;
		}
	}
}
