.create-account-progress {
	margin: 30px auto 70px auto;
	max-width: 480px;
	width: 100%;
	display: grid;
	grid-template-columns: auto 1fr auto;
	gap: 20px;
	padding: 0 40px;

	&__step {
		position: relative;

		&.active {
			.create-account-progress__step-number {
				color: #fff;
				background: var(--primary-blue-400);
			}

			.create-account-progress__step-text {
				color: var(--greyscale-grey-800);
			}
		}
	}

	&__step-text {
		color: var(--greyscale-grey-800);
		font-size: 16px;
		font-style: normal;
		font-weight: 500;
		line-height: 150%; /* 24px */
		margin-bottom: 0;
		position: absolute;
		top: -30px;
		left: 50%;
		white-space: nowrap;
		transform: translateX(-50%);
	}

	&__step-number {
		margin: 0 auto;
		width: 44px;
		height: 44px;
		display: flex;
		justify-content: center;
		align-items: center;
		color: var(--primary-blue-400);
		font-size: 18px;
		font-style: normal;
		font-weight: 500;
		line-height: normal;
		background: #fff;
		border: 1px solid var(--primary-blue-400);
		border-radius: 50%;
		transition: var(--transition-base);

		&.is-completed {
			position: relative;
			background: transparent;
			border: 0;

			&:before {
				content: '';
				display: block;
				position: absolute;
				top: 50%;
				left: 50%;
				width: 60px;
				height: 60px;
				border-radius: 50%;
				background: #f0f6fa;
				z-index: 1;
				transform: translate(-50%, -50%);
			}

			&:after {
				content: '';
				display: block;
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
				width: 17px;
				height: 14px;
				background-image: url("data:image/svg+xml,%3Csvg width='17' height='14' viewBox='0 0 17 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 6.86959L5.99412 12.0673L16 1.67188' stroke='white' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
				z-index: 3;
			}
		}
	}

	&__step-number-ball {
		display: block;
		position: relative;
		border-radius: 50%;
		background: #f0f6fa;
		z-index: 2;
		width: 100%;
		height: 100%;
		background: var(--primary-blue-400);
	}

	&__line {
		width: 100%;
		height: 2px;
		border-radius: 2px;
		background: var(--greyscale-grey-300);
		align-self: flex-end;
		margin-bottom: 22px;

		@media only screen and (max-width: 820px) {
			display: block;
		}

		svg {
			display: none;
		}
	}
}
