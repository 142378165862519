.input {
	margin-bottom: 16px;

	&__notification {
		margin-top: 5px;
		color: var(--additional-error);
		font-size: 12px;
		font-style: normal;
		font-weight: 400;
		line-height: normal;

		&--type5 {
			padding: 0 16px;
			margin-top: 0;

			p {
				color: var(--greyscale-grey-600);
				font-size: 14px;
				font-style: normal;
				font-weight: 400;
				line-height: normal;
				display: flex;
				align-items: center;
				justify-content: space-between;

				> span {
					display: flex;
					align-items: center;
					gap: 10px;
					color: var(--blue600);
					font-size: 14px;
					font-style: normal;
					font-weight: 500;
					line-height: normal;

					span {
						color: var(--greyscale-grey-500);
					}
				}
			}
		}
	}

	&__name {
		color: var(--gray600);
		//font-family: Euclid Circular A;
		font-size: 14px;
		font-weight: 400;
		margin-bottom: 8px;
	}

	&__notification--type2 {
		a {
			font-size: 12px;
			font-weight: 400;
			color: var(--blue400);
		}
	}
	&__links {
		display: flex;
		gap: 8px;
		color: var(--text-color-main);

		a {
			color: var(--blue400);

			&:hover {
				color: var(--text-color-main);
			}
		}
		.input-file-preview__item {
			cursor: pointer;
		}
	}
}

.totp .input__notification--type2 a {
	font-size: 12px;
	font-weight: 400;
	color: var(--blue400);
}

.input-wrapper {
	&--error {
		border: 1px solid var(--additional-error);
		border-radius: 10px;
	}
}

.input__label--wrapper {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	.switch {
		margin-bottom: 8px;
	}
}

