.table-walletBalance--liquidity-crypto .tr {
	grid-template-columns: .8fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
}
.table-walletBalance--liquidity-crypto span {
		font-family: Roboto;
		font-size: 14px;
		font-weight: 500;
		line-height: 25px;
		letter-spacing: 0px;
		text-align: left;

}

.table-walletBalance--liquidity-fiat .tr {
	grid-template-columns: .8fr 1fr 1fr 1fr 1fr;
}

.table-walletBalance--liquidity-fiat span {
	font-family: Roboto;
	font-size: 14px;
	font-weight: 500;
	line-height: 25px;
	letter-spacing: 0px;
	text-align: left;
}

.currencyButtonFile {

	width: 100%;
	display: flex;
	justify-content: space-between;
	.currencyButton {
		border: 1px solid var(--border-main-color);
		border-radius: 8px;
	}
	.fileButton {
		display: flex;
		button {
			margin-right: 10px;
		}
	}
}
//
.table-walletBalance--platformBalance-crypto .tr {
	grid-template-columns: 1fr 1fr;
}
.table-walletBalance--platformBalance-fiat .tr {
	grid-template-columns: 1fr 1fr;
}
//
.table-walletBalance--walletBalance-crypto .tr {
	grid-template-columns: 1fr 1fr 1fr;
}
.table-walletBalance--fiat .tr {
	grid-template-columns: 1fr 1fr;
}
//
.table-walletBalance--pendingFees-crypto .tr {
	grid-template-columns: 1fr 1fr 1fr;
}
.table-walletBalance--pendingFees-fiat .tr {
	grid-template-columns: 1fr 1fr 1fr;
}
.table-walletBalance--pendingFees-total .tr {

	grid-template-columns: 1fr 1fr 1fr;
}

.wallet-content-balance-item__value {
	display: flex;
	align-items: center;
}
.wallet-content-balance-item__user-currensy {
	display: flex;
	span {
		margin-left: 3px;
	}
}
//
.table-walletBalance--deposit-wallet-totals-crypto .tr {
	grid-template-columns: 1fr 1fr 1fr 1fr;
}

.table-walletBalance--deposit-wallet-totals-fiat .tr {
	display: flex;
	justify-content: space-between;
	grid-template-columns: 1fr 1fr;
}

.table-walletBalance--balances-crypto .tr {
	display: flex;
	justify-content: space-between;
	grid-template-columns: 1fr 1fr;
}
.table-walletBalance--balances-fiat .tr {
	display: flex;
	justify-content: space-between;
	grid-template-columns: 1fr 1fr;
}
.table-walletBalance--deposit-wallet-user .tr {
	grid-template-columns: .8fr 1fr 1fr 1fr 1fr 1fr 1.3fr;
}
.table-walletBalance--deposit-wallet-user-fait .tr {
	grid-template-columns: .8fr 1fr 1fr 1fr 1fr 1fr;
}
.coin__text---uppers {
	font-family: 'Roboto';
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 180%;
	color: #173B58;
	flex: none;
	order: 0;
	flex-grow: 0;
}

.coin__text---uppers1 {
	font-family: 'Roboto';
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 180%;
	color: #777E90;
	flex: none;
	order: 0;
	flex-grow: 0;
}
.td-sorting {
	.active {
		border-top-color: black !important;
		border-bottom-color: black !important;
	}
	div {
		width: 0;
		height: 0;
		border: 3.4px solid transparent;
		margin-left: 8px;
	}
	.arrow-top {
		border-top-color: #9d9dbc;
		border-bottom: 0;
	}
	.arrow-bottom {
		margin-bottom: 3px;
		border-bottom-color: #9d9dbc;
		border-top: 0;
	}
}

.totalBalances {
	width: 100%;
	margin-top: 30px;
	display: flex;
	p {
		font-family: 'Roboto';
		font-style: normal;
		font-weight: 400;
		font-size: 14px;
		line-height: 180%;
		color: #173B58;
		flex: none;
		order: 0;
		flex-grow: 0;
	}
	span {
		margin-left: 5px;
		font-family: 'Roboto';
		font-style: normal;
		font-weight: 500;
		font-size: 14px;
		line-height: 180%;
		/* or 25px */


		/* Text/Primary */

		color: #173B58;


		/* Inside auto layout */

		flex: none;
		order: 1;
		flex-grow: 0;
	}
}

.buttonsChains {
	display: flex;
	flex-direction: column;
	align-items: end;
	button {
		justify-content: center !important;
		align-items: center!important;
		width: 250px;
		margin-bottom: 5px;
	}
}

.buttonsChainsMini {
	button {
		justify-content: center !important;
		align-items: center!important;
		max-width: 300px;
		margin-bottom: 5px;
	}
}

.input__name_wallets_popup {
	display: block;
	margin-bottom: 8px;
	color: #636363;
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 160%; /* 22.4px */
}
