.editor {
	&__container {
		border-radius: 10px;
		border: 1px solid var(--greyscale-grey-100);
		background: var(--additional-white);
		height: 400px;
		padding: 12px 16px;
		font-size: 14px;
		font-style: normal;
		font-weight: 500;
		line-height: 160%;

		span {
			display: inline;
		}
		&--error {
			border-color: var(--additional-error);
		}
	}
	&__wrapper {
		cursor: text;
		overflow-y: auto;
		height: fit-content;
		max-height: 350px;
		scrollbar-color: #b6b6b6 transparent;
		scrollbar-width: thin;
		.public-DraftEditorPlaceholder-root {
			font-size: 14px;
			font-weight: 400;
			color: #c7c7c7;
			opacity: 1;
			font-family: var(--font-sans);
		}
	}
	&__toolbar {
		border: none;
		.rdw-option-wrapper {
			border: none;
		}
		&--disabled {
			pointer-events: none;
		}
		.rdw-link-modal {
			height: fit-content;
		}
	}
}

.rdw-editor-main {
	padding: 10px;
}

.rdw-editor-main .public-DraftEditor-content {
	outline: none;
	caret-color: black;
}

