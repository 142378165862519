.user-management-balance {
	.td-sorting-arrow__item,.select__current-arrow {
		color: red;
		svg,path {
			fill: rgba(0, 113, 171, 1);
		}
	}
	.td-sorting-arrow-button{
		background: #fafafa;
		background: var(--gray300);
		padding: 6px 8px;
		border-radius: 6px;
		width: 100%;
		color: var(--gray600);
		font-size: 14px;
		font-weight: 400;
	}

	.pagination-block {

	}
	.wallet-content-tables {
		padding: 0;
	}
	.wallet-content-balance {
		width: 100%;
	}
	.wallet-content-balance-item {
		padding: 26px 32px;
		border-radius: 14px;
		width: calc(33.3333333333% - 20px);
		margin: 0 10px;
	}
	.coin {
		display: flex;
	}
	.td--right {
		p {

		}
	}
	.table-title {
		p {
			margin-bottom: 20px;
		}
	}
	.table-title_crypto {
		display: flex;
		align-items: center;
		gap: 10px;

		@media only screen and (max-width: 767.98px) {
			flex-direction: column;
		}

		p {
			//margin-top: 24px !important;
			//margin-bottom: 4px !important;
			margin: 0 !important;
		}
	}

	.coin__icon___text {
		p {
			display: flex !important;
			flex-direction: column !important;
			justify-content: center !important;
			font-family: Euclid Circular A !important;
			font-size: 16px !important;
			font-weight: 500 !important;
			line-height: 20px !important;
			letter-spacing: 0px !important;
			text-align: left !important;
			color: rgba(20, 20, 20, 1) !important;
			margin-bottom: 5px;
		}

		span {
			font-family: Euclid Circular A;
			font-size: 14px;
			font-weight: 400;
			line-height: 18px;
			letter-spacing: 0;
			text-align: left;
			color: rgba(119, 126, 144, 1);
		}
	}

	.flex {
		display: flex;
		align-items: center;
	}

	.flex-end {
		margin:  14px 0;
		display: flex;
		align-items: center;
		justify-content: end;
	}

	.coin__icon {
		width: 42px;
		height: 42px;
	}

	//crypto
	.crypto-flex {
		.coin {
		}
	}

	.fiatRow {
	}

	.coin__text {
		font-family: Euclid Circular A;
		font-size: 16px !important;
		font-weight: 500 !important;
		line-height: 20px;
		letter-spacing: 0;
		text-align: left;

	}

	.cryptoRow {

		.flex-end {
			flex-direction: column;
			justify-content: center;
			align-items: flex-start;
			padding: 0 !important;
			margin: 0 !important;

			p {
				margin: 4px 0;
			}

			p:nth-child(2) {
				margin-top: 10px;
			}

			p:last-child {
				margin-bottom: 10px;
			}
		}

		.td--right {
			display: flex;
			align-items: flex-end;
			justify-content: center;
			flex-direction: column;
			gap: 8px;
		}
		.td {
			height: 100%;

			.crypto-flex {
				height: 100%;
				display: flex;
				flex-direction: column;
				justify-content: center;
				gap: 20px;

				.coin {
				}
			}
		}
	}

	.table-wrapper {
		overflow: visible;
	}

	.active-trade {
		display: flex;
		justify-content: center;
	}

	.active-trade_filters {
		background: #fafafa;
		border: none;
		width: 200px;
		.active-trade_filters--currency .select {
			width: 200px;
			padding: 0;
			background: none;
		}
		.active-trade_filters, .select, .select__current {
			background: none;
		}
	}

	.td--fiat--right {
		margin-right: 40px;
	}

	.user-management-balance, .active-trade_filters {
		background: none;
	}

	.select--table .select__drop {
		padding: 8px;
		width: 200px;
		right: auto;
		left: 0;
	}

	.select, .select--table {

		button {
			//styleName: Body/Body4 14 Regular;
			font-family: Euclid Circular A  !important;
			font-size: 14px !important;
			font-weight: 400 !important;
			line-height: 22px !important;
			letter-spacing: 0px !important;
			text-align: left !important;
			color: rgba(99, 99, 99, 1) !important;
		}
	}
}

.button--send-pool-wallet {
	display: flex;
	align-items: center;
	font-family: Euclid Circular A;
	font-size: 16px;
	font-weight: 500;
	line-height: 20px;
	letter-spacing: 0em;
	text-align: left;
	color: rgba(0, 113, 171, 1);

	svg path[stroke="#777E90"][d="M1.5 1L8.5 8L1.5 15"] {
		stroke: #0071ABFF;
	}
}

.button--send-pool-wallet_arrow {
	margin-left: 16px;
	min-width: 38px;
	width: 38px;
	height: 38px;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 6px;
	border: 1px solid var(--blue200);
	background: var(--blue100);
	box-shadow: 0 2px 6px 0 rgba(33, 44, 99, 0.09);
	background-size: 24px 24px;
	background-repeat: no-repeat;
	background-position: center;
}
@media (max-width: 1200px) {
		.menu-filter-balances {
			display: none !important;
		}
 .table--fiat-balance--custom-mob {
	 .td--right {
		 display: flex;
		 flex-direction: column;
		 align-items: flex-start;
		 justify-content: flex-end;
	 }
	 .flex-end {
		 display: flex;
		 flex-direction: column;
		 align-items: flex-start;
		 justify-content: flex-end;
		 .td-hidden-name {
			 margin-top: 15px !important;
		 }
	 }
	 .cryptoRow {
		 .td-hidden-name {
			 margin-bottom: 10px;
		 }
		 .td--right {
			 height: 100%;
			 display: flex;
			 justify-content: flex-start;
			 align-items: flex-start;
		 }
	 }
 }
	.wallet-content-balance-mob {
		display: flex;
		flex-direction: column;
		.wallet-content-balance-item {
			width: 100%;
			margin-bottom: 20px;
		}
	}
}
