.buttons-flexcontainer {
	font-family: 'Euclid Circular A', sans-serif !important;
	justify-content: flex-start;
}
.button--size41.active {
	font-family: 'Euclid Circular A', sans-serif !important;
	color: #0071ab !important;
	font-weight: 600;
	background: #d4edfc !important;
	box-shadow: 0 2px 8px 0 rgba(33, 44, 99, 0.1) !important;
}
.title-block-wrap {
	margin-bottom: 40px !important;
}
.tabs-buttons a,
.tabs-buttons button {
	border-radius: 8px;
	background: transparent;
	width: 180px;
	height: 42px;
	display: flex;
	align-items: center;
	justify-content: center;
	color: #777e90;
	font-size: 18px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
}

.tabs-buttons a.active,
.tabs-buttons button.active {
	color: #0071ab;
	font-weight: 600;
	background: #d4edfc;
	/* background: var(--primary-blue-100); */
	box-shadow: 0 2px 8px 0 rgba(33, 44, 99, 0.1);
}

.tabs-buttons-nav {
	display: grid;
	grid-template-columns: repeat(6, 1fr) 1.1fr;
	border: 1px solid var(--greyscale-grey-100);
	border-radius: 14px;
	width: 100%;
	margin-bottom: 24px;

	@media screen and (max-width: 1380px) {
		display: grid;
		grid-template-columns: repeat(5, 1fr);
		gap: 1px;
	}
	@media screen and (max-width: 1080px) {
		display: grid;
		grid-template-columns: repeat(3, 1fr);
	}
	@media screen and (max-width: 620px) {
		display: grid;
		grid-template-columns: repeat(2, 1fr);
	}
}

.account-tabs {
	border: 1px solid var(--greyscale-grey-100);
	border-radius: 14px;
	a {
		padding: 0 12px;
		height: 50px;
		display: flex;
		justify-content: center;
		align-items: center;
		font-weight: 400;
		font-size: 14px;
		border-right: none;
		color: var(--greyscale-grey-500);
		background: var(--additional-white);
		position: relative;

		&:first-child,
		&:last-child {
			border-radius: 14px;
		}
		&:not(:last-child) {
			&::before {
				content: '';
				height: 14px;
				display: block;
				width: 1px;
				position: absolute;
				right: 0px;
				top: 18px;
				background-color: var(--greyscale-grey-100);
			}
		}

		&.active {
			color: var(--greyscale-grey-800);
			font-weight: 600;
			background: #fff;
			position: relative;
			&::after {
				content: '';
				display: block;
				height: 2px;
				background-color: var(--primary-blue-400); /* Колір підкреслення */
				position: absolute;
				bottom: 8px;
				left: 0;
				right: 0;
				margin: auto;
				width: 80%;
			}
		}
	}
}

.tabs-buttons-flex {
	max-width: fit-content;
	width: 100%;
	display: flex;
	justify-content: flex-start;
	a {
		min-width: 160px;
	}
	margin-bottom: 24px;
}
.tabs-buttons--transfer-history {
	max-width: fit-content;
	width: 100%;
	display: flex;
	justify-content: flex-start;
	margin: 20px 0px;
}

.tabs-buttons-grid {
	display: grid;
	grid-template-columns: repeat(4, 1fr);
	a {
		width: 100%;
	}
	@media screen and (max-width: 992px) {
		grid-template-columns: repeat(2, 1fr);
	}
}

.tabs-buttons-wrapper {
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
}

