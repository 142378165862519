.totalBalances {

	p {
		color: var(--greyscale-grey-500);
		font-family: var(--font-sans);
		font-size: 14px;
		font-style: normal;
		font-weight: 400;
		line-height: 160%; /* 22.4px */
	}

	span {
		color: var(--greyscale-grey-800);
		font-family: var(--font-sans);
		font-size: 14px;
		font-style: normal;
		font-weight: 500;
		line-height: 160%; /* 22.4px */
	}
}
