.account-notes-editor {
	width: 100%;

	&__container {
		position: relative;
		display: flex;
		flex-direction: column;
		border-radius: 10px;
		border: 1px solid var(--greyscale-grey-100);
		background: var(--additional-white);
		padding: 0 16px 12px 16px;

		@media only screen and (max-width: 767.98px) {
			min-height: 200px !important;
		}

		.editor__container {
			height: auto;
			box-sizing: border-box;
			flex: 1 0 auto;
			padding: 0;
			border: 0;
			border-radius: 0;
		}

		.editor__wrapper {
			max-height: inherit;
		}

		.rdw-editor-main {
			padding: 0 10px;
		}

		.editor__toolbar {
			position: sticky;
			top: 0;
			background: #fff;
			z-index: 10;
			padding: 12px 0 5px 0;
			margin: 0;
		}
	}

	&__resize {
		cursor: s-resize;
		width: 100%;
		height: 10px;
		background-color: #000;
		position: relative;
		margin-top: -5px;
		opacity: 0;
	}
}
