.input-file {
	padding: 16px;
	border-radius: 10px;
	border: 1px solid var(--greyscale-grey-100);
	background: var(--additional-white);
	margin-bottom: 30px;

	&.active {
		border: 1px solid var(--greyscale-grey-200);
		background: #f5f5f5;
	}

	&__button {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 100%;
		cursor: pointer;
	}

	&__disabled {
		pointer-events: none;
	}

	&.drop-file-preview__item {

		img {
			width: 32px;
		}
	}

	&__button-content {
		height: auto;
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
		gap: 16px;
		color: var(--greyscale-grey-600);
		font-size: 14px;
		font-style: normal;
		font-weight: 400;
		line-height: 160%;
		background: transparent;
		border-radius: 0;

		b {
			color: var(--primary-blue-400);
			font-weight: 600;
		}
	}
}

.input-file-preview {
	margin-bottom: 16px;
	display: flex;
	flex-flow: row wrap;
	grid-gap: 8px;
	gap: 8px;

	&__item {
		position: relative;
		margin-right: 0;
		border-radius: 6px;
		border: 1px solid var(--primary-blue-200);
		background: var(--additional-white);
		width: 115px;
		min-width: 115px;
		min-height: 80px;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		padding: 10px;

		img {
			width: 52px;
		}

		&:hover .input-file-preview__delete {
			display: block;
		}
	}

	&__delete {
		position: absolute;
		top: 8px;
		right: 8px;
		width: 24px;
		min-width: 24px;
		height: 24px;
		background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect x='0.4' y='0.4' width='23.2' height='23.2' rx='11.6' fill='%23FEECEB'/%3E%3Crect x='0.4' y='0.4' width='23.2' height='23.2' rx='11.6' stroke='%23FE0000' stroke-width='0.8'/%3E%3Cpath d='M8.35033 18C8.07533 18 7.83991 17.9021 7.64408 17.7063C7.44824 17.5104 7.35033 17.275 7.35033 17V7.5H6.66699V6.5H9.80033V6H14.2003V6.5H17.3337V7.5H16.6503V17C16.6503 17.2667 16.5503 17.5 16.3503 17.7C16.1503 17.9 15.917 18 15.6503 18H8.35033ZM15.6503 7.5H8.35033V17H15.6503V7.5ZM10.117 15.5667H11.117V8.91667H10.117V15.5667ZM12.8837 15.5667H13.8837V8.91667H12.8837V15.5667Z' fill='%23FE0000'/%3E%3C/svg%3E%0A");
		display: none;
	}

	&__name {
		color: var(--greyscale-grey-800);
		font-size: 12px;
		font-style: normal;
		font-weight: 700;
		line-height: normal;
		width: 100%;
		overflow: hidden;
		text-overflow: ellipsis;
		margin-top: 8px;
		text-wrap: nowrap;
	}

	&__item__del {
		background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16' fill='none'%3E%3Cpath d='M5.4987 11.2026L7.9987 8.7026L10.4987 11.2026L11.1987 10.5026L8.6987 8.0026L11.1987 5.5026L10.4987 4.8026L7.9987 7.3026L5.4987 4.8026L4.7987 5.5026L7.2987 8.0026L4.7987 10.5026L5.4987 11.2026ZM7.9987 14.6693C7.08759 14.6693 6.22648 14.4943 5.41536 14.1443C4.60425 13.7943 3.89592 13.3165 3.29036 12.7109C2.68481 12.1054 2.20703 11.397 1.85703 10.5859C1.50703 9.77483 1.33203 8.91372 1.33203 8.0026C1.33203 7.08038 1.50703 6.21372 1.85703 5.4026C2.20703 4.59149 2.68481 3.88594 3.29036 3.28594C3.89592 2.68594 4.60425 2.21094 5.41536 1.86094C6.22648 1.51094 7.08759 1.33594 7.9987 1.33594C8.92092 1.33594 9.78759 1.51094 10.5987 1.86094C11.4098 2.21094 12.1154 2.68594 12.7154 3.28594C13.3154 3.88594 13.7904 4.59149 14.1404 5.4026C14.4904 6.21372 14.6654 7.08038 14.6654 8.0026C14.6654 8.91372 14.4904 9.77483 14.1404 10.5859C13.7904 11.397 13.3154 12.1054 12.7154 12.7109C12.1154 13.3165 11.4098 13.7943 10.5987 14.1443C9.78759 14.4943 8.92092 14.6693 7.9987 14.6693ZM7.9987 13.6693C9.57648 13.6693 10.9154 13.1165 12.0154 12.0109C13.1154 10.9054 13.6654 9.56927 13.6654 8.0026C13.6654 6.42483 13.1154 5.08594 12.0154 3.98594C10.9154 2.88594 9.57648 2.33594 7.9987 2.33594C6.43203 2.33594 5.09592 2.88594 3.99036 3.98594C2.88481 5.08594 2.33203 6.42483 2.33203 8.0026C2.33203 9.56927 2.88481 10.9054 3.99036 12.0109C5.09592 13.1165 6.43203 13.6693 7.9987 13.6693Z' fill='%230071AB'/%3E%3C/svg%3E");
		width: 16px;
		min-width: 16px;
		height: 16px;
		min-height: 16px;
		transform: translate(0, 0);
		top: 4px;
		right: 4px;
		position: absolute;
		cursor: pointer;

		svg {
			display: none;
		}
	}

	&__download {
		cursor: pointer;
	}
}

