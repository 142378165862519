.input-item {
	height: 44px;
	border: 1px solid var(--gray100);
	border-radius: 10px;
	padding: 0 16px;
	font-size: 14px;
	font-weight: 500;
	color: var(--blue600);
	font-family: var(--font-sans);

	&::placeholder {
		font-size: 14px;
		font-weight: 400;
		color: #c7c7c7;
		opacity: 1;
		font-family: var(--font-sans);
	}

	&:placeholder-shown {
		text-overflow: ellipsis;
	}

	&.input-item--left-icon {
		padding-left: 40px;
	}

	&.input-item--left-icon-bigger {
		padding-left: 50px;
	}

	&--pr-30 {
		padding-right: 30px;
	}
}
