.table--associated-accounts_main {
	margin-top: 10px;
}
.table.table--associated-accounts_main__newRequests {
	.active {
		border: none;
	}
	.select__current {
		position: absolute;
		min-width: 115px;
		border: 1.5px solid var(--primary-blue-200);
		border-radius: 7px;
		padding: 10px;
	}
	.select__drop {
		left: -30px;
	}
}
.associated-accounts_main_title_main {
	display: flex;
	margin-bottom: 33px;
	p {
		//styleName: Heading/H1;
		font-family: Euclid Circular A;
		font-size: 38px;
		font-weight: 600;
		line-height: 38px;
		text-align: left;
		color: #141414;
		margin-left: 14px;
	}
}
.associated-accounts_main__title {
	//styleName: Heading/H2;
	font-family: Euclid Circular A;
	font-size: 24px;
	font-weight: 600;
	line-height: 30.43px;
	text-align: left;
	color: #141414;
	margin-bottom: 26px;
}
.associated-accounts_main {
	.filters__search-block {
		justify-content: flex-start !important;
	}
	background: #fff;
	padding:  0 20px 20px 20px;
	border-radius: 14px;
	.table-block {

	}
}
.table--associated-accounts_main .table-header .tr .td > p {
	border-radius: 6px;
	background: var(--greyscale-grey-10);
	padding: 2px 10px;
	height: 34px;
	display: flex;
	align-items: center;
	color: var(--greyscale-grey-600);
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
}

.table--associated-accounts_main {
	.tr {
		border-bottom: 1px solid #F2F2F2;
	}
	.table-body {
		.td:last-child {
			display: flex;
			justify-content: flex-start;
			align-items: flex-start;
		}
	}
	.table-header .tr {
		border-bottom: none;
	}
	.td__text {
		//styleName: Body/Body4 14 Regular;
		font-family: Euclid Circular A;
		font-size: 14px;
		font-weight: 400;
		line-height: 22.4px;
		text-align: left;
		color: var(--gray800);
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		align-items: flex-start;
		span {
			//styleName: Body/Body6 12 Regular;
			font-family: Euclid Circular A;
			font-size: 12px;
			font-weight: 400;
			line-height: 15.22px;
			text-align: left;
			color: var(--text-color2);
		}
	}
	.td__more {
		//styleName: Body/Body6 12 Regular;
		font-family: Euclid Circular A;
		font-size: 12px;
		font-weight: 400;
		line-height: 15.22px;
		text-align: left;
		color: var(--text-color2);
	}
	.td {
		padding: 0px 6px;
	}
	.table, .table-body, .td {
		margin-top: 4px;
	}
	.table ,.td__wrap {
		gap:0px;
}
.td__wrap {
padding: 0px;
display: flex;
flex-direction: column;
justify-content: flex-start;
align-items: flex-start;
margin-left: 6px;
}
.btn--icon {
cursor: pointer;
display: flex;
justify-content: center;
align-items: center;
border-radius: 6px;
border: 1px solid #a8dbf9;
border: 1px solid var(--blue200);
background: #d4edfc;
background: var(--blue100);
box-shadow: 0 2px 6px 0 rgba(33, 44, 99, .09);
width: 38px;
min-width: 38px;
height: 38px;
min-height: 38px;
padding: 0;
img {

}
}
.td-more ,.table {
margin-top: 0px;
}
}
.table--associated-accounts_main {
.table-block .pagination-block {
border-top: none;
}
}
.associated-accounts_main {

}
.associated-accounts_main .filters__search-block div:nth-child(3) {
	margin-left: auto;
}
.associated-accounts_main {
	.filters__search-block {
		.filter {
			margin-right: 0 !important;
		}
	}
}
.newRequests {

	.filters__search-block {
		.filter {
			margin-right: 0 !important;
		}
		display: flex;
		width: 100%;
		justify-content: flex-start;
		align-items: center;
		margin-right: 0 !important;
	}

	.filters__search-block div:nth-child(3) {
		margin-left: auto;
	}
	.btn--edit {
		background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M4.5 19.4969H5.6L16.675 8.42188L15.575 7.32188L4.5 18.3969V19.4969ZM19.85 7.34688L16.65 4.14688L17.7 3.09688C17.9833 2.81354 18.3333 2.67188 18.75 2.67188C19.1667 2.67188 19.5167 2.81354 19.8 3.09688L20.9 4.19687C21.1833 4.48021 21.325 4.83021 21.325 5.24688C21.325 5.66354 21.1833 6.01354 20.9 6.29688L19.85 7.34688ZM18.8 8.39687L6.2 20.9969H3V17.7969L15.6 5.19688L18.8 8.39687ZM16.125 7.87188L15.575 7.32188L16.675 8.42188L16.125 7.87188Z' fill='%230071AB'/%3E%3C/svg%3E%0A");
		background-size: 24px 24px;
		background-position: center;
		background-repeat: no-repeat;
	}
}

.table.table--associated-accounts_main__newRequests {
	.table .td__wrap {
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		display: block; /* Это важно, чтобы text-overflow работал */
	}
	.td__text--truncate {
		overflow: hidden;
		text-overflow: ellipsis;
		display: -webkit-box;
		-webkit-line-clamp: 1;
		-webkit-box-orient: vertical;
		white-space: normal;
	}
	.td__text--truncate a {
		overflow: hidden;
		text-overflow: ellipsis;
		display: -webkit-box;
		-webkit-line-clamp: 1;
		-webkit-box-orient: vertical;
		white-space: normal;
	}
}


