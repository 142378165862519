.user-management-item {
	padding: 0;
	border: 0;

	&__title {

		&--type3 {
			color: var(--greyscale-grey-800);
			font-size: 24px;
			font-style: normal;
			font-weight: 600;
			line-height: normal;
			margin-bottom: 24px;
		}
	}

	.select-counter {

		.select {
			height: 44px;
			border: 1px solid var(--border-main-color);
			border-radius: 10px;
		}
	}

	.website {
		position: relative;

		svg {
			position: absolute;
			right: 18px;
			top: 50%;
			transform: translateY(-50%);
		}
	}
}
