.title-block-button {
	button {
		width: 188px;
		height: 44px;
		padding: 12px 16px;
		border-radius: 8px;
		gap: 6px;
		background: rgba(33, 44, 99, 1);
	}
}
